<template>
    <div v-show="autenticacao.autenticado">
        <Loader v-if="carregando" />
        <div v-show="!carregando">
            <section class="section">
                <div class="container">
                    <div class="indicadores indicador" :key="rerenderKeyGeral">  
                        <div class="barra-superior">
                            <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                                <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 150px; margin-left: 1.5rem" />
                                <span style="font-size: 24px; margin: auto">Indicadores</span>
                            </div>
                            <div style="margin-right: 2rem; display: flex; gap: 1rem">
                                <Button
                                    :classes="`is-blue-steel is-outlined is-active`" 
                                    :titulo="`Digitação`" 
                                    style="width: 7rem; border-color: #ccc;"
                                />
                                
                                <Button
                                    :classes="`is-blue-steel is-outlined`" 
                                    :titulo="`Pesquisa`" 
                                    :onclick="pesquisa"
                                    style="width: 7rem; border-color: #ccc;"
                                />
                                
                                <Button
                                    :classes="`is-blue-steel is-outlined`" 
                                    :titulo="`Exportação`" 
                                    :onclick="exportacao"
                                    v-if="autenticacao.admin"
                                    style="width: 7rem; border-color: #ccc;"
                                />
                                
                                <Button
                                    :classes="`is-blue-steel is-outlined`" 
                                    :titulo="`Documentos`" 
                                    :onclick="documentos"
                                    v-if="autenticacao.admin"
                                    style="width: 7rem; border-color: #ccc;"
                                />
                                
                                <Button
                                    :classes="`is-blue-steel is-outlined`" 
                                    :titulo="`Endereços`" 
                                    :onclick="enderecos"
                                    v-if="autenticacao.admin"
                                    style="width: 7rem; border-color: #ccc;"
                                />

                                <Button
                                    :classes="`is-blue-steel is-outlined`" 
                                    :titulo="`Estatísticas`" 
                                    :onclick="estatisticas"
                                    v-if="autenticacao.coordenadores || autenticacao.admin"
                                    style="width: 7rem; border-color: #ccc;"
                                />

                                <div class="dropdown is-right is-hoverable">
                                    <div class="dropdown-trigger">
                                        <button class="button is-secondary" aria-haspopup="true" aria-controls="dropdown-menu3">
                                            <i class="gg-more-vertical-alt"></i>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                        <div class="dropdown-content">
                                            <a class="dropdown-item" @click="usuarios()" v-if="autenticacao.admin">
                                                Usuários
                                            </a>
                                            <a class="dropdown-item" @click="ajuda()">
                                                Ajuda
                                            </a>
                                            <a class="dropdown-item" @click="sair()">
                                                Sair
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="formulario">                              
                            <div class="tabs is-boxed">
                                <ul>
                                    <li :class="{ 'is-active': formAtivo == 'real'}"><a @click="formAtivo = 'real'">Indicador Real</a></li>
                                    <li :class="{ 'is-active': formAtivo == 'imovel'}" v-show="form.tipoRegistro != 3"><a @click="formAtivo = 'imovel'">Imóvel</a></li>
                                    <li :class="{ 'is-active': formAtivo == 'registroAnterior'}" v-show="form.tipoRegistro != 3"><a @click="formAtivo = 'registroAnterior'">Registro Anterior</a></li>
                                    <!-- <li :class="{ 'is-active': formAtivo == 'pessoal'}"><a @click="formAtivo = 'pessoal'">Indicador Pessoal</a></li> -->
                                    <li :class="{ 'is-active': formAtivo == 'atos'}" v-show="form.tipoRegistro != 3"><a @click="formAtivo = 'atos'">Atos</a></li>
                                    <li :class="{ 'is-active': formAtivo == 'indisponibilidade'}" v-show="form.tipoRegistro != 3"><a @click="formAtivo = 'indisponibilidade'">Indisponibilidade</a></li>
                                    <li :class="{ 'is-active': formAtivo == 'proprietarios'}" v-show="form.tipoRegistro != 3 && edicao && !copia"><a @click="formAtivo = 'proprietarios'">Proprietários atuais do imóvel</a></li>
                                    <li :class="{ 'is-active': formAtivo == 'registroAuxiliar'}" v-show="form.tipoRegistro == 3 && form.tipoLivro3 != 6"><a @click="formAtivo = 'registroAuxiliar'">Descrição do Reg. Auxiliar</a></li>
                                </ul>
                                <div class="numero">{{ formAtivo != 'real' && form.tipoRegistro != '' ? tiposRegistro[form.tipoRegistro].split(" - ")[1] : '' }} <b>{{ formAtivo != 'real' && form.tipoRegistro != '' ? form.numeroRegistro.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '' }}</b></div>
                            </div>

                            <div class="columns" v-show="formAtivo == 'real'">
                                <div class="column is-one-fifth">
                                    <Select
                                        :label="`Tipo do registro`" 
                                        :preenchido="validacao.tipoRegistro"
                                        :render="forcarRerenderGeral"
                                        v-model="form.tipoRegistro"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposRegistro" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>

                                <div class="column is-3" v-if="form.tipoRegistro == 3">
                                    <Select 
                                        :label="`Tipo do livro 3`" 
                                        :preenchido="validacao.tipoLivro3"
                                        :render="forcarRerenderTipo3"
                                        ref="tipoLivro3"
                                        v-model="form.tipoLivro3"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposLivro3" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>

                                <div class="column is-2">
                                    <InputText
                                        :label="`Número (Matrícula, L3, etc.)`" 
                                        :preenchido="validacao.numeroRegistro"
                                        :loading="loading.numeroRegistro"
                                        :verificarRegistro="verificarRegistro"
                                        :mascara="'######################'"
                                        ref="numeroRegistro"
                                        v-model="form.numeroRegistro"
                                    />
                                </div>

                                <div class="column is-2">
                                    <InputText
                                        :label="`Letra complementar`" 
                                        :preenchido="validacao.letraRegistro"
                                        class="letraComplementar"
                                        v-model="form.letraRegistro"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-show="form.tipoRegistro == 3 && form.tipoLivro3 == 6">
                                    <InputText
                                        :label="`Nº do processo administrativo`" 
                                        :preenchido="true"
                                        v-model="form.processo.numeroProcesso"
                                    />
                                </div>

                                <div class="column is-one-fifth">
                                    <InputText
                                        :label="`Data de abertura`" 
                                        :tipo="`date`" 
                                        :preenchido="validacao.dataAbertura"
                                        v-model="form.dataAbertura"
                                        ref="dataAbertura"
                                    />
                                </div>

                                <div class="column is-3" v-show="form.tipoRegistro == 3 && form.tipoLivro3 == 1">
                                    <InputText
                                        :label="`Nº da cédula (contrato)`" 
                                        :preenchido="validacao.numeroCedula"
                                        class="letraComplementar"
                                        v-model="form.numeroCedula"
                                    />
                                </div>

                                <div class="column is-3">
                                    <InputText
                                        :label="`CNM`" 
                                        :preenchido="validacao.cnm"
                                        :disabled="true"
                                        v-model="form.cnm"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-show="form.tipoRegistro == 3 && form.tipoLivro3 == 6">
                                    <InputText
                                        :label="`Data do encerramento`" 
                                        :tipo="`date`" 
                                        :preenchido="true"
                                        v-model="form.processo.dataEncerramento"
                                    />
                                </div>

                                <div class="column is-2" v-show="form.tipoRegistro == 3 && form.tipoLivro3 == 6">
                                    <InputText
                                        :label="`Nº da inscrição`" 
                                        :preenchido="true"
                                        v-model="form.processo.numeroInscricao"
                                    />
                                </div>

                                <div class="column is-2" v-show="form.tipoRegistro == 3 && form.tipoLivro3 == 6">
                                    <InputText
                                        :label="`Livro`" 
                                        :preenchido="true"
                                        v-model="form.processo.livro"
                                    />
                                </div>

                                <div class="column is-2" v-if="form.tipoRegistro == 3 && form.tipoLivro3 != 4 && form.tipoLivro3 != 5 && form.tipoLivro3 != 6 && form.tipoLivro3 != 9 && form.tipoLivro3 != 10">
                                    <Select 
                                        :label="`Ônus ativo`" 
                                        :preenchido="validacao.onusAtivo"
                                        v-model="form.onusAtivo"
                                        :tabUltimoCampo="form.tipoRegistro == 3 && (form.tipoLivro3 == 7 || form.tipoLivro3 == 8 || form.tipoLivro3 == 11) ? tabUltimoCampo : undefined"
                                        :tab="`registroAuxiliar`"
                                    >
                                        <option value="0">0 - Não</option>
                                        <option value="1">1 - Sim</option>
                                    </Select>
                                </div>

                                <div class="column is-2" v-if="form.tipoRegistro == 3 && form.tipoLivro3 != 6 && form.tipoLivro3 != 7 && form.tipoLivro3 != 8 && form.tipoLivro3 != 10 && form.tipoLivro3 != 11">
                                    <Select 
                                        :label="`L2 vinculado`" 
                                        :preenchido="validacao.l2Vinculado"
                                        v-model="form.l2Vinculado"
                                    >
                                        <option value="0">0 - Não</option>
                                        <option value="1">1 - Sim</option>
                                    </Select>
                                </div>

                                <div v-show="form.tipoRegistro == 3 && form.tipoLivro3 != 6 && form.tipoLivro3 != 7 && form.tipoLivro3 != 8 && form.tipoLivro3 != 10 && form.tipoLivro3 != 11" ref="numerosMatriculas" style="display: contents;" class="column" v-for="(numeroMatricula, idx) in form.numerosMatriculas">
                                    <div class="column is-one-fifth">
                                        <InputText
                                            :label="`Nº da matrícula`" 
                                            :preenchido="true"
                                            v-model="form.numerosMatriculas[idx]"
                                        />
                                    </div>

                                    <div v-if="form.numerosMatriculas.length > 1">
                                        <Button
                                            :classes="`is-danger is-outlined btn-small`" 
                                            :icone="`close`" 
                                            :titulo="``" 
                                            :onclick="removerMatriculas"
                                            :parametro1="idx"
                                            style="margin-left: 0rem; margin-top: 1.8rem;"
                                        />
                                    </div>
                                </div>
                                
                                <div class="column is-2" style="width: 15%" v-show="form.tipoRegistro == 3 && form.tipoLivro3 != 6 && form.tipoLivro3 != 7 && form.tipoLivro3 != 8 && form.tipoLivro3 != 10 && form.tipoLivro3 != 11">
                                    <Button
                                        :classes="`is-info is-outlined btn-small`" 
                                        :icone="`math-plus`" 
                                        :titulo="`Novo nº de matrícula (ALT + M)`" 
                                        :onclick="adicionarMatriculas"
                                        style="margin-left: -2rem; margin-top: 1.8rem;"
                                        :tabUltimoCampo="tabUltimoCampo"
                                        :tab="`registroAuxiliar`"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-show="form.tipoRegistro == 3 && form.tipoLivro3 == 10">
                                    <InputText
                                        :label="`Número do ofício`" 
                                        :preenchido="true"
                                        class="letraComplementar"
                                        v-model="form.numeroOficio"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.tipoRegistro == 3 && form.tipoLivro3 == 10">
                                    <InputText
                                        :label="`Data do ofício`" 
                                        :tipo="`date`" 
                                        :preenchido="true"
                                        v-model="form.dataOficio"
                                        :tabUltimoCampo="tabUltimoCampo"
                                        :tab="`registroAuxiliar`"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-if="form.tipoRegistro != 3">
                                    <Select 
                                        :label="`Movimento do dia?`" 
                                        :preenchido="validacao.matriculaNova"
                                        v-model="form.matriculaNova"
                                    >
                                        <option value="1">1 - Sim</option>
                                        <option value="2">2 - Não</option>
                                    </Select>
                                </div>

                                <div class="column is-one-fifth">
                                    <Select 
                                        :label="`Vigência`" 
                                        :preenchido="validacao.vigenciaRegistro"
                                        v-model="form.vigenciaRegistro"
                                    >
                                        <option value="1">1 - Ativo (Matrícula aberta e vigente)</option>
                                        <option value="2">2 - Encerrado pelo cartório</option>
                                        <option value="3">3 - Cancelado Administrativo</option>
                                        <option value="4">4 - Cancelado Judicial</option>
                                        <option value="5">5 - Requalificado (Prov. 13 CGJ/PA)</option>
                                        <option value="6">6 - Inexistente</option>
                                    </Select>
                                </div>

                                <div class="column is-one-fifth" v-if="form.tipoRegistro != 3">
                                    <Select 
                                        :label="`Possui livro 3 vinculado?`" 
                                        :preenchido="validacao.livroVinculado"
                                        v-model="form.livroVinculado"
                                    >
                                        <option value="0">0 - Não</option>
                                        <option value="1">1 - Sim</option>
                                    </Select>
                                </div>

                                <div class="column is-one-fifth" v-if="form.tipoRegistro != 3">
                                    <Select 
                                        :label="`Possui anotações?`" 
                                        :preenchido="validacao.anotacoes"
                                        v-model="form.anotacoes"
                                        :tabUltimoCampo="form.tipoRegistro != 2 && form.anotacoes == 0 && form.livroVinculado == 0 ? tabUltimoCampo : undefined"
                                        :tab="`imovel`"
                                    >
                                        <option value="0">0 - Não</option>
                                        <option value="1">1 - Sim</option>
                                    </Select>
                                </div>

                                <div class="column is-one-fifth" v-show="form.tipoRegistro == 2">
                                    <InputText
                                        :label="`Livro da transcrição`" 
                                        :preenchido="validacao.livroTranscricao"
                                        v-model="form.livroTranscricao"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-show="form.tipoRegistro == 2">
                                    <InputText
                                        :label="`Folha da transcrição`" 
                                        :preenchido="validacao.folhaTranscricao"
                                        :tabUltimoCampo="form.livroVinculado != 1 && form.anotacoes != 1 ? tabUltimoCampo : undefined"
                                        :tab="`imovel`"
                                        v-model="form.folhaTranscricao"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="false">
                                    <InputText
                                        :label="`CNS`" 
                                        :preenchido="validacao.cns"
                                        v-model="form.cns"
                                    />
                                </div>

                                <div class="column is-5" v-show="form.tipoRegistro != 2 && form.beneficiariosLivroVinculado.length == 0"></div>

                                <div class="columns" v-show="form.livroVinculado == 1" style="width: 100%; padding: 0 0.8rem!important; margin-top: 0rem!important;">
                                    <div class="column is-full" ref="beneficiariosLivroVinculado" v-for="(beneficiario, indice) in form.beneficiariosLivroVinculado" style="display: contents;">
                                        <div class="column is-2">
                                            <Select 
                                                :label="`Natureza do título`" 
                                                :preenchido="validacao.beneficiariosLivroVinculado[indice].naturezaTitulo"
                                                v-model="form.beneficiariosLivroVinculado[indice].naturezaTitulo"
                                            >
                                                <option 
                                                    v-for="(tipo, idx) in tiposNatureza" 
                                                    :value="idx"
                                                >{{ tipo }}</option>
                                            </Select>
                                        </div>

                                        <div class="column is-2">
                                            <InputText
                                                :label="`Número`" 
                                                :preenchido="validacao.beneficiariosLivroVinculado[indice].numero"
                                                v-model="form.beneficiariosLivroVinculado[indice].numero"
                                            />
                                        </div>      

                                        <div class="column is-2">
                                            <InputText
                                                :label="`CPF/CNPJ`"  
                                                :preenchido="validacao.beneficiariosLivroVinculado[indice].documento"
                                                :buscarDocumentoBeneficiario="buscarDocumentoBeneficiarioL2"
                                                :indice="indice"
                                                :mascara="form.beneficiariosLivroVinculado[indice].documento.replace(/\D/g, '').length == 11 ? '###.###.###-##' : form.beneficiariosLivroVinculado[indice].documento.replace(/\D/g, '').length == 14 ? '##.###.###/####-##' : '#########################'"
                                                v-model="form.beneficiariosLivroVinculado[indice].documento"
                                            />
                                        </div>

                                        <div class="column is-5">
                                            <InputText
                                                :label="`Nome/Razão social`" 
                                                :preenchido="validacao.beneficiariosLivroVinculado[indice].nome"
                                                :loading="loading.beneficiariosLivroVinculado[indice].nome"
                                                v-model="form.beneficiariosLivroVinculado[indice].nome"
                                            />
                                        </div>

                                        <div class="column is-1">  
                                            <Button
                                                :classes="`is-danger is-outlined btn-small`" 
                                                :icone="`close`" 
                                                :titulo="``" 
                                                :onclick="removerBeneficiarioLivroVinculado"
                                                :parametro1="indice"
                                                :tabindex="'-1'"
                                                style="margin-left: 0px; margin-right: 1%;"
                                            />
                                        </div>
                                    </div>

                                    <div class="column is-full">
                                        <Button
                                            :classes="`is-primary is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`Adicionar beneficiário (CTRL + B)`" 
                                            :onclick="adicionarBeneficiarioLivroVinculado"
                                            :tabUltimoCampo="form.anotacoes != 1 ? tabUltimoCampo : undefined"
                                            :tab="`imovel`"
                                            style="margin-left: -2.5rem; margin-top: 0.5rem;"
                                        />
                                    </div>
                                </div>

                                <div class="column is-half" v-show="false">
                                    <Textarea
                                        :label="`Descrição do livro 3 vinculado`" 
                                        :preenchido="validacao.descricaoLivroVinculado ?? true"
                                        v-model="form.descricaoLivroVinculado"
                                        :tabUltimoCampo="form.tipoRegistro != 2 && form.anotacoes != 1 ? tabUltimoCampo : undefined"
                                        :tab="`imovel`"
                                    />
                                </div>

                                <div class="column is-half" v-show="form.anotacoes == 1">
                                    <Textarea
                                        :label="`Descrição das anotações`" 
                                        :preenchido="validacao.descricaoAnotacoes ?? true"
                                        v-model="form.descricaoAnotacoes"
                                        :tabUltimoCampo="form.anotacoes == 1 ? tabUltimoCampo : undefined"
                                        :tab="`imovel`"
                                    />
                                </div>
                            </div>

                            <div class="columns" v-show="formAtivo == 'registroAuxiliar' && form.tipoRegistro == 3" :key="rerenderKey">

                                <div class="column is-one-fifth">
                                    <InputText
                                        :label="`Número do ato`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].numeroAto"
                                        v-model="form.descricaoRegistroAuxiliar.numeroAto"
                                    />
                                </div>     
                                
                                <div class="column is-one-fifth">
                                    <InputText
                                        :label="`Data do registro`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].dataRegistro"
                                        :tipo="`date`"
                                        v-model="form.descricaoRegistroAuxiliar.dataRegistro"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 4 || form.tipoLivro3 == 5">
                                    <InputText
                                        :label="`Data do instrumento`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].dataInstrumento"
                                        :tipo="`date`"
                                        v-model="form.descricaoRegistroAuxiliar.dataInstrumento"
                                    />
                                </div> 
                                
                                <div class="column is-one-fifth">
                                    <Select 
                                        :label="`Tipo do ato`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].tipoAto"
                                        v-model="form.descricaoRegistroAuxiliar.tipoAto"
                                    >
                                        <option value="R">1 - R</option>
                                        <option value="AV">2 - AV</option>
                                    </Select>
                                </div> 
                                
                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 3">
                                    <Select 
                                        :label="`Descrição do ato`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].descricaoAto"
                                        v-model="form.descricaoRegistroAuxiliar.descricaoAto"
                                    >
                                        <option value="Bem de Família">1 - Bem de Família</option>
                                    </Select>
                                </div>  
                                
                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 4">
                                    <Select 
                                        :label="`Descrição do ato`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].descricaoAto"
                                        v-model="form.descricaoRegistroAuxiliar.descricaoAto"
                                    >
                                        <option value="Pacto antenupcial">1 - Pacto Antenupcial</option>
                                    </Select>
                                </div>  
                                
                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 5">
                                    <Select 
                                        :label="`Descrição do ato`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].descricaoAto"
                                        v-model="form.descricaoRegistroAuxiliar.descricaoAto"
                                    >
                                        <option value="União estável">1 - União Estável</option>
                                    </Select>
                                </div>  
                                
                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 1">
                                    <Select 
                                        :label="`Descrição do ato`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].descricaoAto"
                                        v-model="form.descricaoRegistroAuxiliar.descricaoAto"
                                    >
                                        <option value="Cédula de Crédito Comercial">1 - Cédula de Crédito Comercial</option>
                                        <option value="Cédula de Crédito Industrial">2 - Cédula de Crédito Industrial</option>
                                        <option value="Cédula de Crédito Bancário">3 - Cédula de Crédito Bancária</option>
                                        <option value="Cédula e Nota de Crédito à Exportação">4 - Cédula e Nota de Crédito à Exportação</option>
                                        <option value="Nota de Crédito">5 - Nota de Crédito</option>
                                        <option value="Cédula Rural Pignoratícia">6 - Cédula Rural Pignoratícia</option>
                                        <option value="Cédula Rural Hipotecária">7 - Cédula Rural Hipotecária</option>
                                        <option value="Cédula Rural Pignoratícia e Hipotecária">8 - Cédula Rural Pignoratícia e Hipotecária</option>
                                        <option value="Cédula de Produto Rural">9 - Cédula de Produto Rural</option>
                                        <option value="Cédula de Produto Rural Financeiro">10 - Cédula de Produto Rural Financeiro</option>
                                        <option value="Cédula de Crédito Rural">11 - Cédula de Crédito Rural</option>
                                        <option value="Cédula de Crédito com Alienação Fiduciária">12 - Cédula de Crédito com Alienação Fiduciária</option>
                                        <option value="Cédula de Crédito Industrial com Alienação Fiduciária">13 - Cédula de Crédito Industrial com Alienação Fiduciária</option>
                                    </Select>
                                </div>  
                                
                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 7 || form.tipoLivro3 == 11">
                                    <Select 
                                        :label="`Tipo de instrumento`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.tipoInstrumento"
                                    >
                                        <option value="Escritura pública">Escritura pública</option>
                                        <option value="Instrumento particular">Instrumento particular</option>
                                    </Select>
                                </div>
                                
                                <div class="column is-one-fifth" v-if="form.tipoLivro3 != 4 && form.tipoLivro3 != 5">
                                    <InputText
                                        :label="`Data do instrumento`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].dataInstrumento"
                                        :tipo="`date`"
                                        v-model="form.descricaoRegistroAuxiliar.dataInstrumento"
                                    />
                                </div>                                         

                                <div class="column is-full" v-if="form.tipoLivro3 == 2">
                                    <Textarea
                                        :label="`Descrição do ato`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.descricaoAto"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 11">
                                    <InputText
                                        :label="`Título`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.titulo"
                                    />
                                </div> 

                                <div v-if="form.tipoLivro3 == 7" ref="bensPenhorados" style="display: contents;" class="column" v-for="(bemPenhorado, idx) in form.descricaoRegistroAuxiliar.bensPenhorados">
                                    <div class="column is-one-fifth">
                                        <InputText
                                            :label="`Bens penhorados`" 
                                            :preenchido="true"
                                            v-model="form.descricaoRegistroAuxiliar.bensPenhorados[idx]"
                                        />
                                    </div>

                                    <div v-if="form.descricaoRegistroAuxiliar.bensPenhorados.length > 1">
                                        <Button
                                            :classes="`is-danger is-outlined btn-small`" 
                                            :icone="`close`" 
                                            :titulo="``" 
                                            :onclick="removerBemPenhorado"
                                            :parametro1="idx"
                                            style="margin-left: 0rem; margin-top: 1.8rem;"
                                        />
                                    </div>
                                </div>
                                
                                <div class="column is-2" style="width: 15%" v-show="form.tipoLivro3 == 7">
                                    <Button
                                        :classes="`is-info is-outlined btn-small`" 
                                        :icone="`math-plus`" 
                                        :titulo="`Novo bem (ALT + B)`" 
                                        :onclick="adicionarBemPenhorado"
                                        style="margin-left: -2rem; margin-top: 1.8rem;"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.tipoLivro3 == 1 || form.tipoLivro3 == 7 || form.tipoLivro3 == 8 || form.tipoLivro3 == 11">
                                    <InputText
                                        :label="`Data do vencimento`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].dataVencimento"
                                        :tipo="`date`"
                                        v-model="form.descricaoRegistroAuxiliar.dataVencimento"
                                    />
                                </div>  

                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 1 || form.tipoLivro3 == 7 || form.tipoLivro3 == 8 || form.tipoLivro3 == 11">
                                    <Select 
                                        :label="`Tipo da moeda`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].tipoMoeda"
                                        v-model="form.descricaoRegistroAuxiliar.tipoMoeda"
                                    >
                                        <option 
                                            v-for="(tipo, iMoeda) in tiposMoedas" 
                                            :value="iMoeda"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>                                      

                                <div class="column is-one-fifth" v-show="form.tipoLivro3 == 1 || form.tipoLivro3 == 7 || form.tipoLivro3 == 8 || form.tipoLivro3 == 11">
                                    <InputText
                                        :label="`Valor`"
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].valor"
                                        v-model="form.descricaoRegistroAuxiliar.valor"
                                        :mascaraMonetaria="mascaraMonetaria"
                                    />
                                </div>    
                                
                                <div class="column is-one-fifth" v-if="form.tipoLivro3 != 1" v-show="false">
                                    <Textarea
                                        :label="`Objeto de garantia`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.objetoGarantia[0]"
                                    />
                                </div>
                                <div v-if="form.tipoLivro3 == 1 || form.tipoLivro3 == 11" ref="objetosGarantia" style="display: contents;" class="column" v-for="(objetoGarantia, idxGarantia) in form.descricaoRegistroAuxiliar.objetoGarantia">
                                    <div class="column is-11">
                                        <div class="control">
                                            <label class="label">Objeto de garantia</label>
                                            <textarea 
                                                class="textarea" 
                                                rows="3"
                                                v-model="form.descricaoRegistroAuxiliar.objetoGarantia[idxGarantia]"
                                            ></textarea>
                                        </div>
                                    </div>

                                    <Button
                                        :classes="`is-danger is-outlined btn-small`" 
                                        :icone="`close`" 
                                        :titulo="`Remover`" 
                                        :onclick="removerObjetoGarantia"
                                        :parametro1="idxGarantia"
                                    />
                                </div>
                                
                                <div class="column is-full" v-show="form.tipoLivro3 == 1 || form.tipoLivro3 == 11">
                                    <Button
                                        :classes="`is-info is-outlined btn-small`" 
                                        :icone="`math-plus`" 
                                        :titulo="`Nova garantia (ALT + G)`" 
                                        :onclick="adicionarObjetoGarantia"
                                        style="margin-left: -1.8rem; margin-top: 1rem;"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-show="form.tipoLivro3 == 2">
                                    <InputText
                                        :label="`Denominação do empreendimento`"
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].denominacaoEmpreendimento"
                                        v-model="form.descricaoRegistroAuxiliar.denominacaoEmpreendimento"
                                    />
                                </div>                                 

                                <div class="column is-one-fifth" v-show="form.tipoLivro3 == 3">
                                    <InputText
                                        :label="`Valor atribuído`"
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].valorAtribuido"
                                        v-model="form.descricaoRegistroAuxiliar.valorAtribuido"
                                        :mascaraMonetaria="mascaraMonetaria"
                                    />
                                </div>

                                <div ref="contribuintes" style="display: contents;" class="column" v-for="(contribuinte, indiceContribuinte) in form.descricaoRegistroAuxiliar.contribuintes">
                                    <div class="column is-2" v-show="form.tipoLivro3 == 3 || form.tipoLivro3 == 10">
                                        <InputText
                                            :label="`Inscrição municipal - Nº do contribuinte`" 
                                            :preenchido="true"
                                            v-model="form.descricaoRegistroAuxiliar.contribuintes[indiceContribuinte]"
                                        />
                                    </div>

                                    <div v-if="form.descricaoRegistroAuxiliar.contribuintes.length > 1" v-show="form.tipoLivro3 == 3 || form.tipoLivro3 == 10">
                                        <Button
                                            :classes="`is-danger is-outlined btn-small`" 
                                            :icone="`close`" 
                                            :titulo="``" 
                                            :onclick="removerContribuinte"
                                            :parametro1="indiceContribuinte"
                                            :parametro2="`descricaoRegistroAuxiliar`"
                                            style="margin-left: 0rem; margin-top: 1.8rem;"
                                        />
                                    </div>                                            
                                </div>
                                
                                <div class="column is-one-tenth" v-show="form.tipoLivro3 == 3 || form.tipoLivro3 == 10">
                                    <Button
                                        :classes="`is-info is-outlined btn-small`" 
                                        :icone="`math-plus`" 
                                        :titulo="`Novo Nº (ALT + C)`" 
                                        :onclick="adicionarContribuinte"
                                        :parametro1="`descricaoRegistroAuxiliar`"
                                        style="margin-left: -2rem; margin-top: 1.8rem;"
                                    />
                                </div>  

                                <div class="column is-2" v-if="form.tipoLivro3 == 2 || form.tipoLivro3 == 3 || form.tipoLivro3 == 10">
                                    <Select 
                                        :label="`Subdistrito`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].subdistrito"
                                        v-model="form.descricaoRegistroAuxiliar.subdistrito"
                                    >
                                        <option value="21° Subdistrito - Saúde">21 - Saúde</option>
                                        <option value="24° Subdistrito – Indianópolis ">24 - Indianópolis</option>
                                    </Select>
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.tipoLivro3 == 2 || form.tipoLivro3 == 3 || form.tipoLivro3 == 10">
                                    <InputText
                                        :label="`Cidade`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].cidade"
                                        v-model="form.descricaoRegistroAuxiliar.cidade"
                                    />
                                </div>
                                
                                <div class="column is-1" v-if="form.tipoLivro3 == 2 || form.tipoLivro3 == 3 || form.tipoLivro3 == 10">
                                    <Select 
                                        :label="`UF`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].uf"
                                        v-model="form.descricaoRegistroAuxiliar.uf"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposEstados" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>                        

                                <div class="column is-one-fifth" v-show="form.tipoLivro3 == 4 || form.tipoLivro3 == 9">
                                    <InputText
                                        :label="`Data do casamento`"
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].dataCasamento"
                                        :tipo="`date`"
                                        v-model="form.descricaoRegistroAuxiliar.dataCasamento"
                                    />
                                </div>                               

                                <div class="column is-one-fifth" v-show="form.tipoLivro3 == 5">
                                    <InputText
                                        :label="`Data da união estável`"
                                        :preenchido="true"
                                        :tipo="`date`"
                                        v-model="form.descricaoRegistroAuxiliar.dataUniaoEstavel"
                                    />
                                </div>
                                
                                <div class="column is-4" v-show="form.tipoLivro3 == 9 || form.tipoLivro3 == 10">
                                    <InputText
                                        :label="`Número do processo`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.numeroProcesso"
                                    />
                                </div>   

                                <div class="column is-half" v-show="form.tipoLivro3 == 9">
                                    <InputText
                                        :label="`Juízo`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.juizo"
                                    />
                                </div>   

                                <div class="column is-two-fifths" v-show="form.tipoLivro3 == 4 || form.tipoLivro3 == 5">
                                    <div class="selectize-control single">
                                        <InputText
                                            :label="`Tabelionato`"
                                            :preenchido="validacao.descricaoRegistroAuxiliar[0].tabelionato"
                                            :buscarTabelionatos="buscarTabelionatos"
                                            v-model="form.descricaoRegistroAuxiliar.tabelionato"
                                        />
                                        <div class="selectize-dropdown single dropdownTabelionato" style="width: 100%;left: 0px;visibility: visible;">
                                            <div class="selectize-dropdown-content">
                                                <div class="optionEndereco" v-for="(tabelionato, indiceTabelionato) in resultadosTabelionato" :key="tabelionato.cns" @click="definirTabelionato(tabelionato)">{{ `${tabelionato.cns} | ${tabelionato.nome} - ${tabelionato.estado} - ${tabelionato.cidade}` }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                         

                                <div class="column is-one-tenth livro" v-show="form.tipoLivro3 == 4 || form.tipoLivro3 == 5">
                                    <InputText
                                        :label="`Livro`"
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].livro"
                                        v-model="form.descricaoRegistroAuxiliar.livro"
                                    />
                                </div>                       

                                <div class="column is-one-tenth" v-show="form.tipoLivro3 == 4 || form.tipoLivro3 == 5">
                                    <InputText
                                        :label="`Folha`"
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].folha"
                                        v-model="form.descricaoRegistroAuxiliar.folha"
                                    />
                                </div>

                                <div class="column is-one-fifth" v-if="form.tipoLivro3 == 4 || form.tipoLivro3 == 5">
                                    <Select 
                                        :label="`Regime de bens`" 
                                        :preenchido="validacao.descricaoRegistroAuxiliar[0].regimeBens"
                                        v-model="form.descricaoRegistroAuxiliar.regimeBens"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposRegimeBens" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>

                                <div class="column is-two-fifths" v-if="form.tipoLivro3 == 9">
                                    <Select 
                                        :label="`Regime de bens anterior`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.regimeBensAnterior"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposRegimeBens" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>

                                <div class="column is-two-fifths" v-if="form.tipoLivro3 == 9">
                                    <Select 
                                        :label="`Regime de bens atual`" 
                                        :preenchido="true"
                                        v-model="form.descricaoRegistroAuxiliar.regimeBensAtual"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposRegimeBens" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>

                                <div style="width: 100%" v-show="form.tipoLivro3 == 2 || form.tipoLivro3 == 3 || form.tipoLivro3 == 10">
                                    <div ref="enderecosRegistroAuxiliar" style="width: 96%; margin: 2rem auto 0 auto; display: flex; flex-wrap: wrap;" v-for="(endereco, indice) in form.descricaoRegistroAuxiliar.enderecos">          
                                        <div class="accordion">
                                            <div class="accordion-item">
                                                <div class="accordion-header" @click="toggleAccordion(indice)">
                                                    <span>
                                                        Endereço do imóvel {{ endereco.cep != '' ? '-' : '' }} {{ endereco.cep }} {{ endereco.tipoLogradouro && tiposLogradouro[endereco.tipoLogradouro] ? `- ${tiposLogradouro[endereco.tipoLogradouro].split(' - ')[1]}` : '' }} {{ !endereco.tipoLogradouro && endereco.nomeLogradouro  ? '-' : '' }} {{ endereco.nomeLogradouro }}{{ endereco.numero ? `, ${endereco.numero}` : '' }}
                                                    </span>
                                                    <div style="width: 15rem; display: flex; align-items: center;">
                                                        <Button
                                                            :classes="`is-danger is-outlined btn-small`" 
                                                            :icone="``" 
                                                            :titulo="`Remover endereço`" 
                                                            :onclick="removerEndereco"
                                                            :parametro1="indice"
                                                            :parametro2="`descricaoRegistroAuxiliar`"
                                                            :tabindex="'-1'"
                                                            style="margin-left: 50px;"
                                                        />

                                                        <span :class="`accordion-icon icon-class`" :id="`icon-${indice}`">{{edicao ? '+' : '-'}}</span>
                                                    </div>
                                                </div>
                                                <div :class="`accordion-content ${edicao ? 'hide' : 'show'} content-class`" style="padding: 1rem 1rem 2rem 1rem" :id="`content-${indice}`">
                                                    <div class="column is-one-fifth">
                                                        <Select 
                                                            :label="`Tipo do imóvel`" 
                                                            :preenchido="true"
                                                            v-model="endereco.tipoImovel"
                                                        >
                                                            <option 
                                                                v-for="(tipo, idx) in tiposImovel" 
                                                                :value="idx"
                                                            >{{ tipo }}</option>
                                                        </Select>
                                                    </div>
                                                    
                                                    <div class="column is-2" v-show="endereco.tipoImovel == 1 || endereco.tipoImovel == 2 || endereco.tipoImovel == 10 || endereco.tipoImovel == 11">
                                                        <InputText
                                                            :label="`Número`" 
                                                            :preenchido="true"
                                                            v-model="endereco.unidade"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-2">
                                                        <InputText
                                                            :label="`Setor`" 
                                                            :preenchido="true"
                                                            v-model="endereco.setor"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-2">
                                                        <InputText
                                                            :label="`Bloco`" 
                                                            :preenchido="true"
                                                            v-model="endereco.bloco"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-2">
                                                        <InputText
                                                            :label="`Andar`" 
                                                            :preenchido="true"
                                                            v-model="endereco.andar"
                                                        />
                                                    </div>

                                                    <div class="column is-2">
                                                        <Select 
                                                            :label="`Loteamento/Condomínio`" 
                                                            :preenchido="true"
                                                            v-model="endereco.loteamentoCondominio"
                                                        >
                                                            <option value="1">1 - Loteamento</option>
                                                            <option value="2">2 - Condomínio</option>
                                                        </Select>
                                                    </div>
                                                    
                                                    <div class="column is-one-fifth" v-if="false">
                                                        <InputText
                                                            :label="`Nome do empreendimento`" 
                                                            :preenchido="true"
                                                            v-model="endereco.nomeCondominio"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-one-fifth" v-else-if="endereco.loteamentoCondominio == 3">
                                                        <InputText
                                                            :label="`Nome do condomínio`" 
                                                            :preenchido="true"
                                                            v-model="endereco.nomeCondominio"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-one-fifth" v-else-if="endereco.loteamentoCondominio == 4">
                                                        <InputText
                                                            :label="`Nome do edifício`" 
                                                            :preenchido="true"
                                                            v-model="endereco.nomeCondominio"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-one-fifth" v-show="endereco.loteamentoCondominio == 2">
                                                        <InputText
                                                            :label="`Conjunto do condomínio`" 
                                                            :preenchido="true"
                                                            v-model="endereco.conjuntoCondominio"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-2" v-show="endereco.loteamentoCondominio == 1">
                                                        <InputText
                                                            :label="`Loteamento`" 
                                                            :preenchido="true"
                                                            v-model="endereco.loteamento"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-2">
                                                        <InputText
                                                            :label="`Torre/Edíficio`" 
                                                            :preenchido="true"
                                                            v-model="endereco.torreEdificio"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-one-fifth">
                                                        <InputText
                                                            :label="`Conjunto/Empreeendimento`" 
                                                            :preenchido="true"
                                                            v-model="endereco.conjuntoEmpreeendimento"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-2">
                                                        <InputText
                                                            :label="`Vagas`" 
                                                            :preenchido="true"
                                                            v-model="endereco.vagas"
                                                        />
                                                    </div>

                                                    <div class="column is-3" v-show="endereco.tipoImovel != 1 && endereco.tipoImovel != 2 && endereco.tipoImovel != 10 && endereco.tipoImovel != 11 && endereco.loteamentoCondominio != 2"></div>
                                                    <div class="column is-1" v-show="endereco.tipoImovel != 1 && endereco.tipoImovel != 2 && endereco.tipoImovel != 10 && endereco.tipoImovel != 11 && endereco.loteamentoCondominio == 2"></div>
                                                    <div class="column is-1" v-show="(endereco.tipoImovel == 1 || endereco.tipoImovel == 2 || endereco.tipoImovel == 10 || endereco.tipoImovel == 11) && endereco.loteamentoCondominio != 2"></div>
                                                    <div class="column is-1" v-show="endereco.loteamentoCondominio == ''"></div>
                                                    
                                                    <div class="column is-one-fifth">
                                                        <InputText
                                                            :label="`CEP`" 
                                                            :preenchido="validacao.descricaoRegistroAuxiliar[0].enderecos[indice].cep"
                                                            :loading="loading.enderecos[indice].cep"
                                                            :mascara="`#####-###`"
                                                            :buscarCep="buscarCepRegistroAuxiliar"
                                                            :indice="indice"
                                                            v-model="endereco.cep"
                                                        />
                                                    </div>
                                                    
                                                    <div class="column is-2">
                                                        <Select 
                                                            :label="`Tipo do logradouro`" 
                                                            :preenchido="validacao.descricaoRegistroAuxiliar[0].enderecos[indice].tipoLogradouro"
                                                            v-model="endereco.tipoLogradouro"
                                                        >
                                                            <option 
                                                                v-for="(tipo, idx) in tiposLogradouro" 
                                                                :value="idx"
                                                            >{{ tipo }}</option>
                                                        </Select>
                                                    </div>
                                                    
                                                    <div class="column is-6">
                                                        <div class="selectize-control single">
                                                            <InputText
                                                                :label="`Logradouro`" 
                                                                :preenchido="validacao.descricaoRegistroAuxiliar[0].enderecos[indice].nomeLogradouro"
                                                                :buscarCepPorEndereco="buscarCepPorEndereco"
                                                                :indice="indice"
                                                                :aba="`RegistroAuxiliar`"
                                                                v-model="endereco.nomeLogradouro"
                                                            />
                                                            <div class="selectize-dropdown single dropdownEnderecoRegistroAuxiliar" style="width: 100%;left: 0px;visibility: visible;">
                                                                <div class="selectize-dropdown-content">
                                                                    <div class="optionEndereco" v-for="(endereco, indiceEndereco) in resultadosEndereco[indice]" :key="endereco._id" @click="definirCep(endereco, indice, 'descricaoRegistroAuxiliar')">{{ endereco.cep != '' ? endereco.cep.replace(/(\d{5})(\d{3})/, '$1-$2')+' - ' : '' }}{{ endereco.logradouro }} {{ endereco.complemento }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="column is-two-tenth campoNumeroRegistroAuxiliar">
                                                        <InputText
                                                            :label="`Número`" 
                                                            :preenchido="validacao.descricaoRegistroAuxiliar[0].enderecos[indice].numero"
                                                            v-model="endereco.numero"
                                                        />
                                                    </div>

                                                    <div class="column is-2">
                                                        <InputText
                                                            :label="`Bairro`" 
                                                            :preenchido="validacao.descricaoRegistroAuxiliar[0].enderecos[indice].bairro"
                                                            v-model="endereco.bairro"
                                                        />
                                                    </div>

                                                    <div class="column is-2">
                                                        <InputText
                                                            :label="`Complemento`" 
                                                            :preenchido="validacao.descricaoRegistroAuxiliar[0].enderecos[indice].complemento"
                                                            v-model="endereco.complemento"
                                                        />
                                                    </div>

                                                    <div ref="loteQuadrasRegistroAuxiliar" v-for="(loteQuadra, indiceLoteQuadra) in endereco.loteQuadras" style="width: 31%; display: flex">                                        
                                                        <div class="column is-4">
                                                            <InputText
                                                                :label="`Lote`" 
                                                                :preenchido="true"
                                                                v-model="loteQuadra.lote"
                                                            />
                                                        </div>
                                                        
                                                        <div class="column is-4">
                                                            <InputText
                                                                :label="`Quadra`" 
                                                                :preenchido="true"
                                                                v-model="loteQuadra.quadra"
                                                            />
                                                        </div>

                                                        <div class="column is-2" v-if="indiceLoteQuadra != 0">
                                                            <Button
                                                                :classes="`is-danger is-outlined btn-small`" 
                                                                :icone="`math-plus`" 
                                                                :titulo="`Remover lote/quadra`" 
                                                                :onclick="removerLoteQuadra"
                                                                :parametro1="indice"
                                                                :parametro2="indiceLoteQuadra"
                                                                :parametro3="`descricaoRegistroAuxiliar`"
                                                                style="margin-left: -2rem; margin-top: 1.8rem;"
                                                            />
                                                        </div>
                                                        <div class="column is-2" v-else>
                                                            <Button
                                                                :classes="`is-primary is-outlined btn-small`" 
                                                                :icone="`math-plus`" 
                                                                :titulo="`Novo lote/quadra`" 
                                                                :onclick="adicionarLoteQuadra"
                                                                :parametro1="indice"
                                                                :parametro2="`descricaoRegistroAuxiliar`"
                                                                style="margin-left: -2rem; margin-top: 1.8rem;"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="column is-full">
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`Adicionar endereço (ALT + N)`" 
                                            :onclick="adicionarEndereco"
                                            :parametro1="`descricaoRegistroAuxiliar`"
                                            :style="`margin-left: -2.2rem; margin-top: ${form.descricaoRegistroAuxiliar.enderecos.length == 0 ? '1' : '0'}rem;`"
                                        />
                                    </div>
                                </div>

                                <hr style="width: 100%" v-show="form.descricaoRegistroAuxiliar.pessoal && form.descricaoRegistroAuxiliar.pessoal.length > 0">

                                <div class="column is-half listaPartes" v-show="form.descricaoRegistroAuxiliar.pessoal && form.descricaoRegistroAuxiliar.pessoal.length > 0">
                                    <Select 
                                        :label="`Lista de partes`" 
                                        :labelTotal="`Total de partes: ${form.descricaoRegistroAuxiliar.pessoal.length ?? 0}`" 
                                        :textoOption="`Selecione uma parte para editar`" 
                                        :preenchido="true"
                                        ref="selectAvParteRA"
                                        :tabindex="'-1'"
                                        :selecionarOpcao="selecionarParteRA"
                                    >
                                        <option 
                                            v-for="(pessoal, idx) in form.descricaoRegistroAuxiliar.pessoal" 
                                            :value="idx"
                                        >{{ idx+1 }} - {{ pessoal.classificacao }} {{ pessoal.documento != '' ? ` - ${pessoal.documento}` : '' }} {{ pessoal.nome != '' ? ` - ${pessoal.nome}` : '' }}</option>
                                    </Select>
                                </div>

                                <hr style="width: 100%">

                                <div class="columns pessoal" :style="`width: 100%; margin: 0 auto; margin-top: 0rem!important;`" :key="`parte_RA`">
                                    <div class="accordion">
                                        <div class="accordion-item">
                                            <div class="accordion-header">
                                                <span style="margin-left: 0.7rem;">
                                                    Parte {{ descricaoRegistroAuxiliar.pessoal.documento != '' ? ` - ${descricaoRegistroAuxiliar.pessoal.documento}` : ''  }} {{ descricaoRegistroAuxiliar.pessoal.nome != '' ? ` - ${descricaoRegistroAuxiliar.pessoal.nome}` : ''  }}
                                                </span>
                                                <div style="width: 10rem; display: flex; align-items: center; margin-right: 1rem; justify-content: flex-end">
                                                    <Button
                                                        :classes="`is-gray-alt is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Cancelar ${descricaoRegistroAuxiliar.pessoal.edicao ? 'edição' : 'cadastro'} da parte`" 
                                                        :onclick="cancelarEdicaoParteRA"
                                                        :parametro1="false"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-danger btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Remover parte`" 
                                                        :onclick="removerParteRA"
                                                        :tabindex="'-1'"
                                                        v-show="descricaoRegistroAuxiliar.pessoal.edicao ?? false"
                                                    />
                                                </div>
                                            </div>
                                            <div :class="`accordion-content show`">
                                                <div class="column is-2" ref="parte_RA">
                                                    <Select 
                                                        :label="`Classificação`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.classificacao"
                                                        ref="selectClassificacaoRA"
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposClassificacao" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-2">
                                                    <Select 
                                                        :label="`Tipo do documento`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.tipoDocumento"
                                                        ref="selectTipoDocumentoRA"
                                                    >
                                                        <option value="CPF">1 - CPF</option>
                                                        <option value="CIC">2 - CIC</option>
                                                        <option value="CNPJ">3 - CNPJ</option>
                                                        <option value="RG">4 - RG</option>
                                                        <option value="CGC">5 - CGC</option>
                                                        <option value="OUTROS">6 - OUTROS</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-one-fifth">
                                                    <InputText
                                                        :label="`Nº do documento`" 
                                                        :preenchido="true"
                                                        :mascara="descricaoRegistroAuxiliar.pessoal.mascara"
                                                        :inconsistencia="definirInconsistencia"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.documento"
                                                        :buscarDocumento="buscarDocumento"
                                                        :aba="`registroAuxiliar`"
                                                    />
                                                </div>

                                                <div class="column is-5">
                                                    <InputText
                                                        :label="verificaCpfCnpj(descricaoRegistroAuxiliar.pessoal?.documento) != undefined ? verificaCpfCnpj(descricaoRegistroAuxiliar.pessoal?.documento) ? `Nome` : `Razão social` : `Nome/Razão social`" 
                                                        :preenchido="true"
                                                        :loading="descricaoRegistroAuxiliar.pessoal.loading"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.nome"
                                                    />
                                                </div>

                                                <div style="display: flex">             
                                                    <Button
                                                        :classes="`is-warning-alt is-outlined btn-small`" 
                                                        :icone="`danger`" 
                                                        :titulo="``" 
                                                        :onclick="definirInconsistencia"
                                                        :parametro1="`registroAuxiliar`"
                                                        :tabindex="'-1'"
                                                        style="margin-left: 0;"
                                                    />
                                                </div>

                                                <div class="column is-2" v-show="descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ'">
                                                    <Select 
                                                        :label="`Nacionalidade`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.nacionalidade"
                                                        ref="selectNacionalidadeRA"
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposNacionalidade" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-one-fifth" v-show="descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ'">
                                                    <Select 
                                                        :label="`Estado civil`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.estadoCivil"    
                                                        ref="selectEstadoCivilRA"                                                                      
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposEstadosCivil" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-two-fifths" v-show="(descricaoRegistroAuxiliar.pessoal.estadoCivil == 1 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 2) && descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ'">
                                                    <Select 
                                                        :label="`Regime de bens`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.regimeBens"  
                                                        ref="selectRegimeBensRA"                               
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposRegimeBens" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-one-fifth" v-show="(descricaoRegistroAuxiliar.pessoal.estadoCivil == '10' || descricaoRegistroAuxiliar.pessoal.estadoCivil == '14')">
                                                    <Select 
                                                        :label="`União estável`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.uniaoEstavel"
                                                        ref="selectUniaoEstavelRA"        
                                                    >
                                                        <option value="0">0 - Não</option>
                                                        <option value="1">1 - Sim</option>
                                                    </Select>
                                                </div>

                                                <div :class="{
                                                    column: true,
                                                    'is-2': descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil == 1 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 2),
                                                    'is-5': descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil == 10 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 11 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 14 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 15),
                                                    'is-7': descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil != 1 && descricaoRegistroAuxiliar.pessoal.estadoCivil != 2 && descricaoRegistroAuxiliar.pessoal.estadoCivil != 10 && descricaoRegistroAuxiliar.pessoal.estadoCivil != 14)
                                                }"></div>

                                                <div class="column is-2" v-show="descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil == 1 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 2 || ((descricaoRegistroAuxiliar.pessoal.estadoCivil == 10 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 11 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 14 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 15) && descricaoRegistroAuxiliar.pessoal.uniaoEstavel == 1))">
                                                    <Select 
                                                        :label="`Tipo do documento do cônjuge`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.tipoDocumentoConjuge"
                                                        ref="selectTipoDocumentoConjugeRA"        
                                                    >
                                                        <option value="CPF">1 - CPF</option>
                                                        <option value="CIC">2 - CIC</option>
                                                        <option value="CNPJ">3 - CNPJ</option>
                                                        <option value="RG">4 - RG</option>
                                                        <option value="CGC">5 - CGC</option>
                                                        <option value="OUTROS">6 - OUTROS</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-2" v-show="descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil == 1 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 2 || ((descricaoRegistroAuxiliar.pessoal.estadoCivil == 10 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 11 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 14 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 15) && descricaoRegistroAuxiliar.pessoal.uniaoEstavel == 1))">
                                                    <InputText
                                                        :label="`Nº do documento do cônjuge`" 
                                                        :preenchido="true"
                                                        :mascara="descricaoRegistroAuxiliar.pessoal.mascaraConjuge"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.documentoConjuge"
                                                        :buscarDocumentoConjuge="buscarDocumentoConjuge"
                                                        :aba="`registroAuxiliar`"
                                                    />
                                                </div>

                                                <div class="column is-two-fifths" v-show="descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil == 1 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 2 || ((descricaoRegistroAuxiliar.pessoal.estadoCivil == 10 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 11 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 14 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 15) && descricaoRegistroAuxiliar.pessoal.uniaoEstavel == 1))">
                                                    <InputText
                                                        :label="`Nome do cônjuge`" 
                                                        :preenchido="true"
                                                        :loading="descricaoRegistroAuxiliar.pessoal.loadingConjuge"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.nomeConjuge"
                                                    />
                                                </div>

                                                <div class="column is-1" v-show="descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil == 1 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 2 || ((descricaoRegistroAuxiliar.pessoal.estadoCivil == 10 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 11 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 14 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 15) && descricaoRegistroAuxiliar.pessoal.uniaoEstavel == 1))">
                                                    <Button
                                                        :classes="`is-info btn-small`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`Salvar cônjuge como parte`" 
                                                        :onclick="adicionarConjugeRA"
                                                        style="margin-left: -2rem;"
                                                    />
                                                </div>

                                                <div class="column is-2" v-show="descricaoRegistroAuxiliar.pessoal.tipoDocumento != 'CNPJ' && (descricaoRegistroAuxiliar.pessoal.estadoCivil == 1 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 2 || ((descricaoRegistroAuxiliar.pessoal.estadoCivil == 10 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 11 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 14 || descricaoRegistroAuxiliar.pessoal.estadoCivil == 15) && descricaoRegistroAuxiliar.pessoal.uniaoEstavel == 1))"></div>
                                                
                                                <div class="column is-one-quarter" v-show="descricaoRegistroAuxiliar.pessoal.opcaoInconsistencia">
                                                    <Select 
                                                        :label="`Inconsistência`" 
                                                        :preenchido="true"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.inconsistencia"
                                                        ref="selectInconsistenciaRA"   
                                                    >
                                                        <option value="1">1 - CPF com inconsistência</option>
                                                        <option value="2">2 - CIC em conjunto</option>
                                                        <option value="3">3 - Nome diferente</option>
                                                        <option value="4">4 - CIC dependente</option>
                                                        <option value="5">5 - CPF dependente</option>
                                                        <option value="6">6 - CNPJ com inconsistência</option>
                                                    </Select>
                                                </div>

                                                <div class="linha-divisoria" v-show="descricaoRegistroAuxiliar.pessoal.nomes && descricaoRegistroAuxiliar.pessoal.nomes.length > 0"><span>Nomes semelhantes</span></div>

                                                <div class="column is-one-quarter" ref="nomeSemelhante" v-for="(nomes, indiceNome) in descricaoRegistroAuxiliar.pessoal.nomes" style="display: contents;">
                                                    <InputText
                                                        :label="`Nome semelhante`"
                                                        :preenchido="true"
                                                        v-bind:ref="`nomes_ra_${indiceNome}`"
                                                        v-model="descricaoRegistroAuxiliar.pessoal.nomes[indiceNome]"
                                                        style="width: 26%; margin-left: 9px"
                                                    />
                                                    
                                                    <Button
                                                        :classes="`is-danger is-outlined btn-small`" 
                                                        :icone="`close`" 
                                                        :titulo="``" 
                                                        :onclick="removerNome"
                                                        :parametro1="`registroAuxiliar`"
                                                        :parametro2="indiceNome"
                                                        :tabindex="'-1'"
                                                        style="margin-left: 0px; margin-right: 1%;"
                                                    />
                                                </div>

                                                <div class="column is-full">
                                                    <Button
                                                        :classes="`is-primary btn-small`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`Adicionar nome semelhante`" 
                                                        :onclick="adicionarNome"
                                                        :parametro1="`registroAuxiliar`"
                                                        :parametro2="`nomes_ra`"
                                                        style="margin-left: -35px;"
                                                    />
                                                </div>

                                                <div class="column is-full" style="display: flex; justify-content: flex-end; margin-bottom: 1rem;"> 
                                                    <Button
                                                        :classes="`is-link`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`Salvar parte (ALT + P)`" 
                                                        :onclick="adicionarParteRA"
                                                        style="width: 30rem; margin-right: 0rem;"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <hr style="width: 100%" v-show="listaAverbacoes.length > 0">

                                <div class="column is-half listaAtos" v-show="listaAverbacoes.length > 0">
                                    <Select 
                                        :label="`Lista de atos`" 
                                        :labelTotal="`Total de atos: ${listaAverbacoes.length}`" 
                                        :textoOption="`Selecione um ato para editar`" 
                                        :preenchido="true"
                                        ref="selectAv"
                                        :selecionarOpcao="selecionarAverbacao"
                                    >
                                        <option 
                                            v-for="(averbacao, idx) in listaAverbacoes" 
                                            :value="idx"
                                        >{{ averbacao.descricaoAto != '' ? `${averbacao.descricaoAto} -` : '' }} {{ averbacao.numeroAto != '' ? ` ${averbacao.numeroAto}` : '' }}</option>
                                    </Select>
                                </div>

                                <hr style="width: 100%">

                                <div class="columns averbacao" :style="`width: 100%; margin: 0 auto;`" ref="averbacao" :key="`averbacao`">
                                    <div class="accordion">
                                        <div class="accordion-item">
                                            <div class="accordion-header" style="background-color: #f1f1f1;">
                                                <span style="margin-left: 0.7rem;">
                                                    Ato {{ averbacao.descricaoAto != '' ? ` - ${averbacao.descricaoAto}` : ''  }} {{ averbacao.numeroAto != '' ? ` - ${averbacao.numeroAto}` : ''  }}
                                                </span>
                                                <div :style="`width: 5rem; display: flex; align-items: center; margin-right: 1rem; justify-content: flex-end`">
                                                    <Button
                                                        :classes="`is-gray-alt is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Cancelar ${averbacao.edicao ? 'edição' : 'cadastro'} do ato`" 
                                                        :onclick="cancelarEdicaoAverbacao"
                                                        :parametro1="false"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-danger btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Remover ato`" 
                                                        :onclick="removerAverbacao"
                                                        :tabindex="'-1'"
                                                        v-show="averbacao.edicao ?? false"
                                                    />
                                                </div>
                                            </div>
                                            <div :class="`accordion-content show`" style="padding: 1rem 1rem 2rem;">
                                                <div class="column is-3">
                                                    <InputText
                                                        :label="`Nº do ato`" 
                                                        :preenchido="true"
                                                        v-model="averbacao.numeroAto"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Data do ato`" 
                                                        :preenchido="true"
                                                        :tipo="`date`"
                                                        v-model="averbacao.dataRegistro"
                                                    />
                                                </div>

                                                <div class="column is-5">
                                                    <InputText
                                                        :label="`Descrição do ato`" 
                                                        :preenchido="true"
                                                        v-model="averbacao.descricaoAto"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Data do instrumento`" 
                                                        :preenchido="true"
                                                        :tipo="`date`"
                                                        v-model="averbacao.dataInstrumento"
                                                    />
                                                </div>

                                                <hr style="width: 100%; margin-bottom: 0.5rem;">

                                                <div class="column is-half listaPartes" style="margin-left: 2rem; margin-bottom: 1rem;" v-show="form.descricaoRegistroAuxiliar.averbacoes[averbacao.indice]?.pessoal && form.descricaoRegistroAuxiliar.averbacoes[averbacao.indice].pessoal.length > 0">
                                                    <Select 
                                                        :label="`Lista de partes`" 
                                                        :labelTotal="`Total de partes: ${form.descricaoRegistroAuxiliar.averbacoes[averbacao.indice]?.pessoal.length ?? 0}`" 
                                                        :textoOption="`Selecione uma parte para editar`" 
                                                        :preenchido="true"
                                                        ref="selectAvParte"
                                                        :tabindex="'-1'"
                                                        :selecionarOpcao="selecionarParteAverbacao"
                                                    >
                                                        <option 
                                                            v-for="(pessoal, idx) in form.descricaoRegistroAuxiliar.averbacoes[averbacao.indice]?.pessoal" 
                                                            :value="idx"
                                                        >{{ idx+1 }} - {{ pessoal.classificacao }} {{ pessoal.documento != '' ? ` - ${pessoal.documento}` : '' }} {{ pessoal.nome != '' ? ` - ${pessoal.nome}` : '' }}</option>
                                                    </Select>
                                                </div>

                                                <div class="columns pessoal" :style="`width: 100%; margin: 0rem 2rem;`" ref="pessoal" :key="`pessoal`">
                                                    <div class="accordion">
                                                        <div class="accordion-item">
                                                            <div class="accordion-header">
                                                                <span style="margin-left: 0.7rem;">
                                                                    Parte {{ averbacao.pessoal.documento != '' ? ` - ${averbacao.pessoal.documento}` : ''  }} {{ averbacao.pessoal.nome != '' ? ` - ${averbacao.pessoal.nome}` : ''  }}
                                                                </span>
                                                                <div :style="`width: 10rem; display: flex; align-items: center; margin-right: 1rem; justify-content: flex-end`">
                                                                    <Button
                                                                        :classes="`is-gray-alt is-outlined btn-small-alt`" 
                                                                        :icone="``" 
                                                                        :titulo="`Cancelar ${averbacao.pessoal.edicao ? 'edição' : 'cadastro'} da parte`" 
                                                                        :onclick="cancelarEdicaoParteAverbacao"
                                                                        :parametro1="false"
                                                                        :tabindex="'-1'"
                                                                    />
                                                                    
                                                                    <Button
                                                                        :classes="`is-danger btn-small-alt`" 
                                                                        :icone="``" 
                                                                        :titulo="`Remover parte`" 
                                                                        :onclick="removerParteAverbacao"
                                                                        :tabindex="'-1'"
                                                                        v-show="averbacao.pessoal.edicao ?? false"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div :class="`accordion-content show`">
                                                                <div class="column is-2" :ref="`parte_averbacao`">
                                                                    <Select 
                                                                        :label="`Classificação`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.classificacao"
                                                                        ref="selectClassificacao"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposClassificacao" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2">
                                                                    <Select 
                                                                        :label="`Tipo do documento`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.tipoDocumento"
                                                                        ref="selectTipoDocumento"
                                                                    >
                                                                        <option value="CPF">1 - CPF</option>
                                                                        <option value="CIC">2 - CIC</option>
                                                                        <option value="CNPJ">3 - CNPJ</option>
                                                                        <option value="RG">4 - RG</option>
                                                                        <option value="CGC">5 - CGC</option>
                                                                        <option value="OUTROS">6 - OUTROS</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-fifth">
                                                                    <InputText
                                                                        :label="`Nº do documento`" 
                                                                        :preenchido="true"
                                                                        :mascara="averbacao.pessoal.mascara"
                                                                        :inconsistencia="definirInconsistencia"
                                                                        v-model="averbacao.pessoal.documento"
                                                                        :buscarDocumento="buscarDocumento"
                                                                        :aba="`descricaoRegistroAuxiliar`"
                                                                    />
                                                                </div>

                                                                <div class="column is-5">
                                                                    <InputText
                                                                        :label="verificaCpfCnpj(averbacao.pessoal?.documento) != undefined ? verificaCpfCnpj(averbacao.pessoal?.documento) ? `Nome` : `Razão social` : `Nome/Razão social`" 
                                                                        :preenchido="true"
                                                                        :loading="averbacao.pessoal.loading ?? false"
                                                                        v-model="averbacao.pessoal.nome"
                                                                    />
                                                                </div>

                                                                <div style="display: flex">             
                                                                    <Button
                                                                        :classes="`is-warning-alt is-outlined btn-small`" 
                                                                        :icone="`danger`" 
                                                                        :titulo="``" 
                                                                        :onclick="definirInconsistencia"
                                                                        :parametro1="`descricaoRegistroAuxiliar`"
                                                                        :tabindex="'-1'"
                                                                        style="margin-left: 0;"
                                                                    />
                                                                </div>

                                                                <div class="column is-2" v-show="averbacao.pessoal.tipoDocumento != 'CNPJ'">
                                                                    <Select 
                                                                        :label="`Nacionalidade`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.nacionalidade"
                                                                        ref="selectNacionalidade"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposNacionalidade" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-fifth" v-show="averbacao.pessoal.tipoDocumento != 'CNPJ'">
                                                                    <Select 
                                                                        :label="`Estado civil`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.estadoCivil" 
                                                                        ref="selectEstadoCivil"                                                                      
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposEstadosCivil" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-two-fifths" v-show="(averbacao.pessoal.estadoCivil == 1 || averbacao.pessoal.estadoCivil == 2) && averbacao.pessoal.tipoDocumento != 'CNPJ'">
                                                                    <Select 
                                                                        :label="`Regime de bens`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.regimeBens"
                                                                        ref="selectRegime"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposRegimeBens" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-fifth" v-show="(averbacao.pessoal.estadoCivil == '10' || averbacao.pessoal.estadoCivil == '14')">
                                                                    <Select 
                                                                        :label="`União estável`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.uniaoEstavel"
                                                                        ref="selectUniaoEstavel"
                                                                    >
                                                                        <option value="0">0 - Não</option>
                                                                        <option value="1">1 - Sim</option>
                                                                    </Select>
                                                                </div>

                                                                <div :class="{
                                                                    column: true,
                                                                    'is-2': averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil == 1 || averbacao.pessoal.estadoCivil == 2),
                                                                    'is-5': averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil == 10 || averbacao.pessoal.estadoCivil == 11 || averbacao.pessoal.estadoCivil == 14 || averbacao.pessoal.estadoCivil == 15),
                                                                    'is-7': averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil != 1 && averbacao.pessoal.estadoCivil != 2 && averbacao.pessoal.estadoCivil != 10 && averbacao.pessoal.estadoCivil != 14)
                                                                }"></div>

                                                                <div class="column is-2" v-show="averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil == 1 || averbacao.pessoal.estadoCivil == 2 || ((averbacao.pessoal.estadoCivil == 10 || averbacao.pessoal.estadoCivil == 11 || averbacao.pessoal.estadoCivil == 14 || averbacao.pessoal.estadoCivil == 15) && averbacao.pessoal.uniaoEstavel == 1))">
                                                                    <Select 
                                                                        :label="`Tipo do documento do cônjuge`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.tipoDocumentoConjuge"
                                                                        ref="selectTipoDocumentoConjuge"
                                                                    >
                                                                        <option value="CPF">1 - CPF</option>
                                                                        <option value="CIC">2 - CIC</option>
                                                                        <option value="CNPJ">3 - CNPJ</option>
                                                                        <option value="RG">4 - RG</option>
                                                                        <option value="CGC">5 - CGC</option>
                                                                        <option value="OUTROS">6 - OUTROS</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2" v-show="averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil == 1 || averbacao.pessoal.estadoCivil == 2 || ((averbacao.pessoal.estadoCivil == 10 || averbacao.pessoal.estadoCivil == 11 || averbacao.pessoal.estadoCivil == 14 || averbacao.pessoal.estadoCivil == 15) && averbacao.pessoal.uniaoEstavel == 1))">
                                                                    <InputText
                                                                        :label="`Nº do documento do cônjuge`" 
                                                                        :preenchido="true"
                                                                        :mascara="averbacao.pessoal.mascaraConjuge"
                                                                        v-model="averbacao.pessoal.documentoConjuge"
                                                                        :buscarDocumentoConjuge="buscarDocumentoConjuge"
                                                                        :aba="`descricaoRegistroAuxiliar`"
                                                                    />
                                                                </div>

                                                                <div class="column is-two-fifths" v-show="averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil == 1 || averbacao.pessoal.estadoCivil == 2 || ((averbacao.pessoal.estadoCivil == 10 || averbacao.pessoal.estadoCivil == 11 || averbacao.pessoal.estadoCivil == 14 || averbacao.pessoal.estadoCivil == 15) && averbacao.pessoal.uniaoEstavel == 1))">
                                                                    <InputText
                                                                        :label="`Nome do cônjuge`" 
                                                                        :preenchido="true"
                                                                        :loading="averbacao.pessoal.loadingConjuge"
                                                                        v-model="averbacao.pessoal.nomeConjuge"
                                                                    />
                                                                </div>

                                                                <div class="column is-1" v-show="averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil == 1 || averbacao.pessoal.estadoCivil == 2 || ((averbacao.pessoal.estadoCivil == 10 || averbacao.pessoal.estadoCivil == 11 || averbacao.pessoal.estadoCivil == 14 || averbacao.pessoal.estadoCivil == 15) && averbacao.pessoal.uniaoEstavel == 1))">
                                                                    <Button
                                                                        :classes="`is-info btn-small`" 
                                                                        :icone="`math-plus`" 
                                                                        :titulo="`Salvar cônjuge como parte`" 
                                                                        :onclick="adicionarConjugeAverbacao"
                                                                        style="margin-left: -2rem;"
                                                                    />
                                                                </div>

                                                                <div class="column is-2" v-show="averbacao.pessoal.tipoDocumento != 'CNPJ' && (averbacao.pessoal.estadoCivil == 1 || averbacao.pessoal.estadoCivil == 2 || ((averbacao.pessoal.estadoCivil == 10 || averbacao.pessoal.estadoCivil == 11 || averbacao.pessoal.estadoCivil == 14 || averbacao.pessoal.estadoCivil == 15) && averbacao.pessoal.uniaoEstavel == 1))"></div>
                                                                
                                                                <div class="column is-one-quarter" v-show="averbacao.pessoal.opcaoInconsistencia ?? false">
                                                                    <Select 
                                                                        :label="`Inconsistência`" 
                                                                        :preenchido="true"
                                                                        v-model="averbacao.pessoal.inconsistencia"
                                                                        ref="selectInconsistencia"
                                                                    >
                                                                        <option value="1">1 - CPF com inconsistência</option>
                                                                        <option value="2">2 - CIC em conjunto</option>
                                                                        <option value="3">3 - Nome diferente</option>
                                                                        <option value="4">4 - CIC dependente</option>
                                                                        <option value="5">5 - CPF dependente</option>
                                                                        <option value="6">6 - CNPJ com inconsistência</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="linha-divisoria" v-show="averbacao.pessoal.nomes && averbacao.pessoal.nomes.length > 0"><span>Nomes semelhantes</span></div>

                                                                <div class="column is-one-quarter" ref="nomeSemelhante" v-for="(nomes, indiceNome) in averbacao.pessoal.nomes" style="display: contents;">
                                                                    <InputText
                                                                        :label="`Nome semelhante`"
                                                                        :preenchido="true"
                                                                        v-bind:ref="`nomes_averbacao_${indiceNome}`"
                                                                        v-model="averbacao.pessoal.nomes[indiceNome]"
                                                                        style="width: 26%; margin-left: 9px"
                                                                    />
                                                                    
                                                                    <Button
                                                                        :classes="`is-danger is-outlined btn-small`" 
                                                                        :icone="`close`" 
                                                                        :titulo="``" 
                                                                        :onclick="removerNome"
                                                                        :parametro1="`descricaoRegistroAuxiliar`"
                                                                        :parametro2="indiceNome"
                                                                        :tabindex="'-1'"
                                                                        style="margin-left: 0px; margin-right: 1%;"
                                                                    />
                                                                </div>

                                                                <div class="column is-full">
                                                                    <Button
                                                                        :classes="`is-primary btn-small`" 
                                                                        :icone="`math-plus`" 
                                                                        :titulo="`Adicionar nome semelhante`" 
                                                                        :onclick="adicionarNome"
                                                                        :parametro1="`descricaoRegistroAuxiliar`"
                                                                        :parametro2="`nomes_averbacao`"
                                                                        style="margin-left: -35px;"
                                                                    />
                                                                </div>
                                                                <div class="column is-full" style="display: flex; justify-content: flex-end; margin-bottom: 1rem;"> 
                                                                    <Button
                                                                        :classes="`is-link`" 
                                                                        :icone="`math-plus`" 
                                                                        :titulo="`Salvar parte (ALT + Q)`" 
                                                                        :onclick="adicionarParteAverbacao"
                                                                        :parametro1="averbacao.indice"
                                                                        :parametro2="{}"
                                                                        :parametro3="`descricaoRegistroAuxiliar`"
                                                                        :style="`margin-right: 0.8rem; width: 30rem`"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="column is-full" style="display: flex; justify-content: flex-start;"> 
                                                    <Button
                                                        :classes="`is-blue-steel`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`Salvar ato (ALT + A)`" 
                                                        :onclick="adicionarAverbacao"
                                                        style="margin-right: 1.7rem; margin-top: -1rem; margin-bottom: 1rem; width: 30rem"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns" v-show="formAtivo == 'imovel'">                                        
                                <div class="column is-one-fifth">
                                    <Select 
                                        :label="`Localização`" 
                                        :preenchido="validacao.localizacao"
                                        v-model="form.localizacao"
                                    >
                                        <option value="0">0 - Urbano</option>
                                        <option value="1">1 - Rural</option>
                                    </Select>
                                </div>

                                <div class="column is-one-fifth">
                                    <Select 
                                        :label="`Tipo do imóvel`" 
                                        :preenchido="validacao.tipoImovel"
                                        v-model="form.tipoImovel"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposImovel" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>
                                
                                <div ref="unidade" style="display: contents;" class="column">
                                    <div class="column is-4" style="display: flex; width: 30%; padding-right: 0!important;">
                                        <div class="selectize-control single unidade" style="width: 100%;">
                                            <InputText
                                                :label="`<div>Unidade</div><div>${form.unidade.length == 1 ? form.unidade.length + ` adicionado` : form.unidade.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="unidade"
                                                ref="input-unidade"
                                                :enter="adicionarCampo"
                                                :focus="abrirDropdown"
                                                :blur="fecharDropdown"
                                                :indice="`unidade`"
                                            />
                                            <div class="selectize-dropdown single dropdown-unidade" style="width: 100%;left: 0px;visibility: visible; display: none">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(valor, indice) in form.unidade" :key="indice">
                                                        <div class="option-unidade" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="selecionarOpcao(valor, indice, 'unidade')">{{ valor }}</div>
                                                        <div class="option-unidade remover" @click="removerOpcao(indice, 'unidade')">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`${indiceunidade == null ? 'Adicionar' : 'Salvar'} unidade`" 
                                            :onclick="adicionarCampo"
                                            :parametro1="`unidade`"
                                            style="margin-top: 1.8rem;margin-right: -1rem; margin-left: -0.5rem;"
                                        />
                                    </div>
                                </div>

                                <div ref="andar" style="display: contents;" class="column">
                                    <div class="column is-4" style="display: flex; width: 30%; padding-right: 0!important;">
                                        <div class="selectize-control single andar" style="width: 100%;">
                                            <InputText
                                                :label="`<div>Andar</div><div>${form.andar.length == 1 ? form.andar.length + ` adicionado` : form.andar.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="andar"
                                                ref="input-andar"
                                                :enter="adicionarCampo"
                                                :focus="abrirDropdown"
                                                :blur="fecharDropdown"
                                                :indice="`andar`"
                                            />
                                            <div class="selectize-dropdown single dropdown-andar" style="width: 100%;left: 0px;visibility: visible; display: none">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(valor, indice) in form.andar" :key="indice">
                                                        <div class="option-andar" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="selecionarOpcao(valor, indice, 'andar')">{{ valor }}</div>
                                                        <div class="option-andar remover" @click="removerOpcao(indice, 'andar')">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`${indiceandar == null ? 'Adicionar' : 'Salvar'} andar`" 
                                            :onclick="adicionarCampo"
                                            :parametro1="`andar`"
                                            style="margin-top: 1.8rem;margin-right: -1rem; margin-left: -0.5rem;"
                                        />
                                    </div>
                                </div>

                                <div class="column is-2">
                                    <Select 
                                        :label="`Tipo da vaga`" 
                                        :preenchido="true"
                                        v-model="form.tipoVaga"
                                        ref="tipoVaga"
                                    >
                                        <option value="0">0 - Sem vaga</option>
                                        <option value="1">1 - Determinada</option>
                                        <option value="2">2 - Inderteminada</option>
                                    </Select>
                                </div>

                                <div class="column is-2" v-show="form.tipoVaga == 1 || form.tipoVaga == 2">
                                    <Select 
                                        :label="`Natureza jurídica`" 
                                        :preenchido="validacao.naturezaJuridica"
                                        v-model="form.naturezaJuridica"
                                        ref="naturezaJuridica"
                                    >
                                        <option value="1">1 - Comum</option>
                                        <option value="2">2 - Acessória</option>
                                        <option value="3">3 - Autônoma</option>
                                    </Select>
                                </div>

                                <div class="column is-1" style="width: 7%" v-show="form.tipoVaga == 1 || form.tipoVaga == 2">
                                    <InputText
                                        :label="'Qtde. vagas'" 
                                        :preenchido="validacao.vagas"
                                        v-model="form.vagas"
                                    />
                                </div>

                                <div ref="numeroVagas" style="display: contents;" class="column" v-show="form.tipoVaga == 1 || form.tipoVaga == 2">
                                    <div class="column is-3" style="display: flex; width: 27%; padding-right: 0!important;">
                                        <div class="selectize-control single numeroVagas" style="width: 100%;">
                                            <InputText
                                                :label="`<div>Número da vaga</div><div>${form.numeroVagas && form.numeroVagas.length == 1 ? form.numeroVagas.length + ` adicionado` : form.numeroVagas.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="numeroVagas"
                                                ref="input-numeroVagas"
                                                :enter="adicionarCampo"
                                                :focus="abrirDropdown"
                                                :blur="fecharDropdown"
                                                :indice="`numeroVagas`"
                                            />
                                            <div class="selectize-dropdown single dropdown-numeroVagas" style="width: 100%;left: 0px;visibility: visible; display: none">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(valor, indice) in form.numeroVagas" :key="indice">
                                                        <div class="option-numeroVagas" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="selecionarOpcao(valor, indice, 'numeroVagas')">{{ valor }}</div>
                                                        <div class="option-numeroVagas remover" @click="removerOpcao(indice, 'numeroVagas')">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`${indicenumeroVagas == null ? 'Adicionar' : 'Salvar'} vaga`" 
                                            :onclick="adicionarCampo"
                                            :parametro1="`numeroVagas`"
                                            style="margin-top: 1.8rem;margin-right: -1rem; margin-left: -0.5rem;"
                                        />
                                    </div>
                                </div>

                                <div ref="andarVaga" style="display: contents;" class="column" v-show="form.tipoVaga == 1 || form.tipoVaga == 2">
                                    <div class="column is-4" style="display: flex; width: 32%; padding-right: 0!important;">
                                        <div class="selectize-control single andarVaga" style="width: 100%;">
                                            <InputText
                                                :label="`<div>Andar da vaga</div><div>${form.andarVaga.length == 1 ? form.andarVaga.length + ` adicionado` : form.andarVaga.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="andarVaga"
                                                ref="input-andarVaga"
                                                :enter="adicionarCampo"
                                                :focus="abrirDropdown"
                                                :blur="fecharDropdown"
                                                :indice="`andarVaga`"
                                            />
                                            <div class="selectize-dropdown single dropdown-andarVaga" style="width: 100%;left: 0px;visibility: visible; display: none">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(valor, indice) in form.andarVaga" :key="indice">
                                                        <div class="option-andarVaga" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="selecionarOpcao(valor, indice, 'andarVaga')">{{ valor }}</div>
                                                        <div class="option-andarVaga remover" @click="removerOpcao(indice, 'andarVaga')">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`${indiceandarVaga == null ? 'Adicionar' : 'Salvar'} andar da vaga`" 
                                            :onclick="adicionarCampo"
                                            :parametro1="`andarVaga`"
                                            style="margin-top: 1.8rem;margin-right: -1rem; margin-left: -0.5rem;"
                                        />
                                    </div>
                                </div>
                                
                                <div ref="bloco" style="display: contents;" class="column">
                                    <div class="column is-4" :style="`display: flex; width: ${form.tipoVaga == 1 || form.tipoVaga == 2 ? '' : '26%'}`">
                                        <div class="selectize-control single bloco" style="width: 100%;">
                                            <InputText
                                                :label="`<div>Bloco</div><div>${form.bloco.length == 1 ? form.bloco.length + ` adicionado` : form.bloco.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="bloco"
                                                ref="input-bloco"
                                                :enter="adicionarCampo"
                                                :focus="abrirDropdown"
                                                :blur="fecharDropdown"
                                                :indice="`bloco`"
                                            />
                                            <div class="selectize-dropdown single dropdown-bloco" style="width: 100%;left: 0px;visibility: visible; display: none">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(valor, indice) in form.bloco" :key="indice">
                                                        <div class="option-bloco" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="selecionarOpcao(valor, indice, 'bloco')">{{ valor }}</div>
                                                        <div class="option-bloco remover" @click="removerOpcao(indice, 'bloco')">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`${indicebloco == null ? 'Adicionar' : 'Salvar'} bloco`" 
                                            :onclick="adicionarCampo"
                                            :parametro1="`bloco`"
                                            style="margin-top: 1.8rem;margin-right: -1rem; margin-left: -0.5rem;"
                                        />
                                    </div>
                                </div>

                                <div ref="torre" style="display: contents;" class="column">
                                    <div class="column is-4" :style="`display: flex; width: ${form.tipoVaga == 1 || form.tipoVaga == 2 ? '' : '30%'}; padding-right: 0!important;`">
                                        <div class="selectize-control single torre" style="width: 100%;">
                                            <InputText
                                                :label="`<div>Torre/Edifício</div><div>${form.torre.length == 1 ? form.torre.length + ` adicionado` : form.torre.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="torre"
                                                ref="input-torre"
                                                :enter="adicionarCampo"
                                                :focus="abrirDropdown"
                                                :blur="fecharDropdown"
                                                :indice="`torre`"
                                            />
                                            <div class="selectize-dropdown single dropdown-torre" style="width: 100%;left: 0px;visibility: visible; display: none">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(valor, indice) in form.torre" :key="indice">
                                                        <div class="option-torre" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="selecionarOpcao(valor, indice, 'torre')">{{ valor }}</div>
                                                        <div class="option-torre remover" @click="removerOpcao(indice, 'torre')">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`${indicetorre == null ? 'Adicionar' : 'Salvar'} torre/edifício`" 
                                            :onclick="adicionarCampo"
                                            :parametro1="`torre`"
                                            style="margin-top: 1.8rem;margin-right: -1rem; margin-left: -0.5rem;"
                                        />
                                    </div>
                                </div>

                                <div ref="setor" style="display: contents;" class="column">
                                    <div class="column is-4" :style="`display: flex; width: ${form.tipoVaga == 1 || form.tipoVaga == 2 ? '' : '27%'}; padding-right: 0!important;`">
                                        <div class="selectize-control single setor" style="width: 100%;">
                                            <InputText
                                                :label="`<div>Setor</div><div>${form.setor.length == 1 ? form.setor.length + ` adicionado` : form.setor.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="setor"
                                                ref="input-setor"
                                                :enter="adicionarCampo"
                                                :focus="abrirDropdown"
                                                :blur="fecharDropdown"
                                                :indice="`setor`"
                                            />
                                            <div class="selectize-dropdown single dropdown-setor" style="width: 100%;left: 0px;visibility: visible; display: none">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(valor, indice) in form.setor" :key="indice">
                                                        <div class="option-setor" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="selecionarOpcao(valor, indice, 'setor')">{{ valor }}</div>
                                                        <div class="option-setor remover" @click="removerOpcao(indice, 'setor')">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            :classes="`is-info is-outlined btn-small`" 
                                            :icone="`math-plus`" 
                                            :titulo="`${indicesetor == null ? 'Adicionar' : 'Salvar'} setor`" 
                                            :onclick="adicionarCampo"
                                            :parametro1="`setor`"
                                            style="margin-top: 1.8rem;margin-right: -1rem; margin-left: -0.5rem;"
                                        />
                                    </div>
                                </div>
                                
                                <div class="column is-2">
                                    <Select 
                                        :label="`Loteamento/Condomínio`" 
                                        :preenchido="true"
                                        v-model="form.empreendimentoLoteamento"
                                    >
                                        <option value="1">1 - Não consta</option>
                                        <option value="2">2 - Loteamento</option>
                                        <option value="3">3 - Condomínio</option>
                                    </Select>
                                </div>
                                
                                <div class="column is-6" v-if="form.empreendimentoLoteamento != 2 && form.empreendimentoLoteamento != 3">
                                    <InputText
                                        :label="`Nome do condomínio/loteamento`" 
                                        :preenchido="validacao.nomeCondominio"
                                        v-model="form.nomeCondominio"
                                    />
                                </div>
                                
                                <div class="column is-4" v-show="form.empreendimentoLoteamento == 3">
                                    <InputText
                                        :label="`Nome do condomínio`" 
                                        :preenchido="validacao.nomeCondominio"
                                        v-model="form.nomeCondominio"
                                    />
                                </div>
                                
                                <div class="column" v-show="form.empreendimentoLoteamento == 2">
                                    <InputText
                                        :label="`Loteamento`" 
                                        :preenchido="validacao.loteamento"
                                        v-model="form.loteamento"
                                    />
                                </div>
                                
                                <div class="column is-3" v-show="form.empreendimentoLoteamento == 3">
                                    <InputText
                                        :label="'Conjunto do condomínio'" 
                                        :preenchido="validacao.condominioConjunto"
                                        v-model="form.condominioConjunto"
                                    />
                                </div>

                                <div class="column is-3">
                                    <InputText
                                        :label="`Conjunto/Empreendimento`" 
                                        :preenchido="validacao.conjunto"
                                        v-model="form.conjunto"
                                    />
                                </div>                                        

                                <div class="column is-full"></div>
                                
                                <div class="column is-half listaEnderecos" v-show="listaEnderecos.length > 0" style="padding: 0px!important; margin-top: 1rem!important; margin-left: 0.5rem; margin-bottom: -1rem!important;">
                                    <Select 
                                        :label="`Lista de endereços do imóvel`" 
                                        :labelTotal="`Total de endereços do imóvel: ${listaEnderecos.length}`" 
                                        :textoOption="`Selecione um endereço para editar`" 
                                        :preenchido="true"
                                        ref="selectEndereco"
                                        :selecionarOpcao="selecionarEndereco"
                                    >
                                        <option 
                                            v-for="(enderecoUnico, idx) in listaEnderecos" 
                                            :value="idx"
                                            v-if="enderecoUnico.nomeLogradouro"
                                        >{{ enderecoUnico.cep }} {{ enderecoUnico.cep != '' ? '-' : '' }} {{ enderecoUnico.tipoLogradouro ? tiposLogradouro[enderecoUnico.tipoLogradouro].split(' - ')[1] : '' }} {{ !enderecoUnico.tipoLogradouro && enderecoUnico.nomeLogradouro  ? '-' : '' }} {{ enderecoUnico.nomeLogradouro }}{{ enderecoUnico.numero ? `, ${enderecoUnico.numero}` : '' }}</option>
                                    </Select>
                                </div>

                                <div ref="enderecos" style="width: 99%; margin: 2rem auto 0 auto; display: flex; flex-wrap: wrap;">          
                                    <div class="accordion">
                                        <div class="accordion-item">
                                            <div class="accordion-header" style="background-color: #f1f1f1;">
                                                <span style="margin-left: 0.7rem;">
                                                    Endereço do imóvel {{ endereco.cep != '' ? '-' : '' }} {{ endereco.cep }} {{ endereco.tipoLogradouro && tiposLogradouro[endereco.tipoLogradouro] ? `- ${tiposLogradouro[endereco.tipoLogradouro].split(' - ')[1]}` : '' }} {{ !endereco.tipoLogradouro && endereco.nomeLogradouro  ? '-' : '' }} {{ endereco.nomeLogradouro }}{{ endereco.numero ? `, ${endereco.numero}` : '' }}
                                                </span>
                                                <div :style="`display: flex; align-items: center;`">
                                                    <Button
                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Copiar endereço`" 
                                                        :onclick="copiarEndereco"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Cancelar ${endereco.edicao ? 'edição' : 'cadastro'} do endereço`" 
                                                        :onclick="cancelarEdicaoEndereco"
                                                        :parametro1="true"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-danger btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Remover endereço`" 
                                                        :onclick="removerEndereco"
                                                        :tabindex="'-1'"
                                                        v-show="endereco.edicao"
                                                    />
                                                </div>
                                            </div>
                                            <div :class="`accordion-content show content-class`" style="padding: 1rem 1rem 2rem 1rem">
                                                <div class="column is-6">
                                                    <div class="selectize-control single">
                                                        <InputText
                                                            :label="`Logradouro`" 
                                                            :preenchido="true"
                                                            :buscarCepPorEndereco="buscarCepPorEndereco"
                                                            :indice="0"
                                                            :aba="``"
                                                            v-model="endereco.nomeLogradouro"
                                                        />
                                                        <div class="selectize-dropdown single dropdownEndereco" style="width: 100%;left: 0px;visibility: visible;">
                                                            <div class="selectize-dropdown-content">
                                                                <div class="optionEndereco" v-for="(endereco, indiceEndereco) in resultadosEndereco[0]" :key="endereco._id" @click="definirCep(endereco, 0)">{{ endereco.cep != '' ? endereco.cep.replace(/(\d{5})(\d{3})/, '$1-$2')+' - ' : '' }}{{ endereco.logradouro }} {{ endereco.complemento }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="column is-two-tenth campoNumero">
                                                    <InputText
                                                        :label="`Número`" 
                                                        :preenchido="true"
                                                        v-model="endereco.numero"
                                                    />
                                                </div>
                                                
                                                <div class="column is-2">
                                                    <Select 
                                                        :label="`Tipo do logradouro`" 
                                                        :preenchido="true"
                                                        v-model="endereco.tipoLogradouro"
                                                        ref="selectTipoLogradouro"
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposLogradouro" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>
                                                
                                                <div class="column is-one-fifth">
                                                    <InputText
                                                        :label="`CEP`" 
                                                        :preenchido="true"
                                                        :mascara="`#####-###`"
                                                        :loading="endereco.loading"
                                                        :buscarCep="buscarCep"
                                                        v-model="endereco.cep"
                                                    />
                                                </div>

                                                <div class="column is-4">
                                                    <InputText
                                                        :label="`Bairro`" 
                                                        :preenchido="true"
                                                        v-model="endereco.bairro"
                                                    />
                                                </div>
                                                
                                                <div class="column is-8">
                                                    <InputText
                                                        :label="`Complemento`" 
                                                        :preenchido="true"
                                                        v-model="endereco.complemento"
                                                    />
                                                </div>
                                                                                        
                                                <div class="column is-3">
                                                    <InputText
                                                        :label="`Lote`" 
                                                        :preenchido="true"
                                                        v-model="endereco.lote"
                                                    />
                                                </div>
                                                
                                                <div class="column is-3">
                                                    <InputText
                                                        :label="`Quadra`" 
                                                        :preenchido="true"
                                                        v-model="endereco.quadra"
                                                    />
                                                </div>

                                                <div class="column is-2" style="display: flex;">
                                                    <Button
                                                        :classes="`is-primary is-outlined btn-small`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`${endereco.loteQuadraIndice == null ? 'Adicionar' : 'Salvar' } lote/quadra`" 
                                                        :onclick="adicionarLoteQuadraL2"
                                                        style="margin-left: -2rem; margin-top: 1.8rem;"
                                                    />
                                                    <Button
                                                        :classes="`is-danger is-outlined btn-small`" 
                                                        :icone="``" 
                                                        :titulo="`Remover`" 
                                                        :onclick="removerLoteQuadraL2"
                                                        v-if="endereco.loteQuadraIndice"
                                                        style="margin-left: -2rem; margin-top: 1.8rem;"
                                                    />
                                                </div>

                                                <div class="column is-4" v-show="listaLotesQuadras.length > 0">
                                                    <Select 
                                                        :label="`Lista de lotes/quadras`" 
                                                        :labelTotal="`Total de lotes/quadras: ${listaLotesQuadras.length}`" 
                                                        :textoOption="`Selecione um lote/quadra para editar`" 
                                                        :preenchido="true"
                                                        ref="selectLoteQuadra"
                                                        :selecionarOpcao="selecionarLoteQuadra"
                                                    >
                                                        <option 
                                                            v-for="(loteQuadraUnico, idx) in listaLotesQuadras" 
                                                            :value="idx"
                                                        >{{ loteQuadraUnico.lote != '' ? `Lote: ${ loteQuadraUnico.lote }` : '' }}{{ loteQuadraUnico.lote != '' && loteQuadraUnico.quadra != '' ? ` - ` : '' }}{{ loteQuadraUnico.quadra != '' ? `Quadra: ${ loteQuadraUnico.quadra }` : '' }}</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="column is-12">
                                    <Button
                                        :classes="`is-purple`" 
                                        :icone="`math-plus`" 
                                        :titulo="`${endereco.edicao ? 'Salvar' : 'Adicionar'}  endereço (CTRL + Q)`" 
                                        :onclick="adicionarEnderecoL2"
                                        style="margin-bottom: 1rem; width: 40rem; float: right;"
                                    />
                                </div>

                                <div class="column is-2">
                                    <Select 
                                        :label="`Subdistrito`" 
                                        :preenchido="validacao.subdistrito"
                                        v-model="form.subdistrito"
                                    >
                                        <option value="21° Subdistrito - Saúde">21 - Saúde</option>
                                        <option value="24° Subdistrito – Indianópolis ">24 - Indianópolis</option>
                                    </Select>
                                </div>
                                
                                <div class="column is-2">
                                    <InputText
                                        :label="`Cidade`" 
                                        :preenchido="validacao.cidade"
                                        v-model="form.cidade"
                                    />
                                </div>
                                
                                <div class="column is-one-tenth">
                                    <Select 
                                        :label="`UF`" 
                                        :preenchido="validacao.uf"
                                        v-model="form.uf"
                                    >
                                        <option 
                                            v-for="(tipo, idx) in tiposEstados" 
                                            :value="idx"
                                        >{{ tipo }}</option>
                                    </Select>
                                </div>
                                
                                <div class="column is-one-tenth">
                                    <InputText
                                        :label="`Área privativa`" 
                                        :preenchido="validacao.fracaoAreaComum"
                                        v-model="form.fracaoAreaComum"
                                    />
                                </div>
                                
                                <div class="column is-one-tenth">
                                    <InputText
                                        :label="`Área total`" 
                                        :preenchido="validacao.fracaoTempo"
                                        v-model="form.fracaoTempo"
                                    />
                                </div>
                                
                                <div ref="contribuintes" style="display: contents;" class="column">
                                    <div class="column is-one-fifth">
                                        <div class="selectize-control single contribuintes">
                                            <InputText
                                                :label="`<div>Contribuintes</div><div>${form.contribuintes.length == 1 ? form.contribuintes.length + ` adicionado` : form.contribuintes.length + ` adicionados`}</div>`" 
                                                :preenchido="true"
                                                :placeholder="`  `"
                                                v-model="contribuinte"
                                                ref="inputContribuintes"
                                            />
                                            <div v-if="form.contribuintes.length > 0" class="selectize-dropdown single dropdownContribuintes" style="width: 100%;left: 0px;visibility: visible;">
                                                <div class="selectize-dropdown-content">
                                                    <div class="optionEndereco" style="display: flex; padding: 0!important" v-for="(nomeContribuinte, indiceContribuinte) in form.contribuintes" :key="indiceContribuinte">
                                                        <div class="optionContribuinte" style="word-break: break-all; width: 100%; padding: 5px 8px;" @click="definirContribuinte(nomeContribuinte, indiceContribuinte)">{{ nomeContribuinte }}</div>
                                                        <div class="optionContribuinte remover" @click="removerContribuinte(indiceContribuinte)">&times;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="column is-two-tenths">
                                    <Button
                                        :classes="`is-info is-outlined btn-small`" 
                                        :icone="`math-plus`" 
                                        :titulo="`Adicionar contribunte`" 
                                        :onclick="adicionarContribuinte"
                                        style="margin-left: -2rem; margin-top: 1.8rem;"
                                    />
                                </div>

                                <div class="column is-one-fifth">
                                    <Select 
                                        :label="`Situação da construção`" 
                                        :preenchido="validacao.situacaoConstrucao"
                                        v-model="form.situacaoConstrucao"
                                        :tabUltimoCampo="form.localizacao != 1 ? tabUltimoCampo : undefined"
                                        :tab="`registroAnterior`"
                                    >
                                        <option value="0">0 - Construção Averbada</option>
                                        <option value="1">1 - Em construção</option>
                                        <option value="2">2 - Inscrição</option>
                                        <option value="3">3 - Sem construção</option>
                                    </Select>
                                </div>

                                <div class="column is-2" v-show="false">
                                    <Select 
                                        :label="`Casa/Apartamento`" 
                                        :preenchido="true"
                                        v-model="form.casaApto"
                                    >
                                        <option value="1">1 - Casa</option>
                                        <option value="2">2 - Apartamento</option>
                                    </Select>
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.localizacao == 1">
                                    <InputText
                                        :label="`SNCR/CCIR`" 
                                        :preenchido="validacao.sncrCcir"
                                        v-model="form.sncrCcir"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.localizacao == 1">
                                    <InputText
                                        :label="`CAR`" 
                                        :preenchido="validacao.car"
                                        v-model="form.car"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.localizacao == 1">
                                    <InputText
                                        :label="`Denominação rural`" 
                                        :preenchido="validacao.denominacaoRural"
                                        v-model="form.denominacaoRural"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.localizacao == 1">
                                    <InputText
                                        :label="`Número INCRA`" 
                                        :preenchido="true"
                                        v-model="form.numeroIncra"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.localizacao == 1">
                                    <InputText
                                        :label="`Acidente geográfico`" 
                                        :preenchido="validacao.acidenteGeografico"
                                        v-model="form.acidenteGeografico"
                                    />
                                </div>
                                
                                <div class="column is-one-tenth" v-show="form.localizacao == 3">
                                    <InputText
                                        :label="`CIB`" 
                                        :preenchido="validacao.cib"
                                        v-model="form.cib"
                                    />
                                </div>
                                
                                <div class="column is-one-tenth" v-show="form.localizacao == 3">
                                    <InputText
                                        :label="`Área`" 
                                        :preenchido="validacao.area"
                                        v-model="form.area"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.localizacao == 1">
                                    <InputText
                                        :label="`SIGEF`" 
                                        :preenchido="validacao.sigef"
                                        v-model="form.sigef"
                                    />
                                </div>
                                
                                <div class="column is-one-fifth" v-show="form.localizacao == 1">
                                    <InputText
                                        :label="`NIRF`" 
                                        :preenchido="validacao.nirf"
                                        v-model="form.nirf"
                                        :tabUltimoCampo="form.localizacao == 1 ? tabUltimoCampo : undefined"
                                        :tab="`registroAnterior`"
                                    />
                                </div>
                            </div>

                            <div v-show="formAtivo == 'registroAnterior'">
                                <div class="column is-half listaRegistros" v-show="listaRegistros.length > 0" style="padding: 0px!important; margin-bottom: 1rem;">
                                    <Select 
                                        :label="`Lista de registros anteriores`" 
                                        :labelTotal="`Total de registros anteriores: ${listaRegistros.length}`" 
                                        :textoOption="`Selecione um registro para editar`" 
                                        :preenchido="true"
                                        ref="selectRegistroAnterior"
                                        :selecionarOpcao="selecionarRegistroAnterior"
                                    >
                                        <option 
                                            v-for="(registroUnico, idx) in listaRegistros" 
                                            :value="idx"
                                            v-if="registroUnico.numeroRegistroAnterior"
                                        >{{ registroUnico.tipoRegistroAnterior != '' && tiposRegistro[registroUnico.tipoRegistroAnterior] ? `- ${tiposRegistro[registroUnico.tipoRegistroAnterior].split(' - ')[1]}` : '' }} {{ registroUnico.ato != '' ? `- ${registroUnico.ato}` : '' }} {{ registroUnico.numeroRegistroAnterior != '' ? `- ${registroUnico.numeroRegistroAnterior}` : '' }}</option>
                                    </Select>
                                </div>

                                <div class="columns ato" ref="registroAnterior">
                                    <div class="accordion">
                                        <div class="accordion-item">
                                            <div class="accordion-header" style="background-color: #f1f1f1;">
                                                <span style="margin-left: 0.7rem;">
                                                    Registro Anterior {{ registroAnterior.tipoRegistroAnterior != '' && tiposRegistro[registroAnterior.tipoRegistroAnterior] ? `- ${tiposRegistro[registroAnterior.tipoRegistroAnterior].split(' - ')[1]}` : '' }} {{ registroAnterior.ato != '' ? `- ${registroAnterior.ato}` : '' }} {{ registroAnterior.numeroRegistroAnterior != '' ? `- ${registroAnterior.numeroRegistroAnterior}` : '' }}
                                                </span>
                                                <div :style="`width: 10.5rem; display: flex; align-items: center; margin-right: ${registroAnterior.edicao ? 6 : 1}rem;`">
                                                    <Button
                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Cancelar ${registroAnterior.edicao ? 'edição' : 'cadastro'} do registro`" 
                                                        :onclick="cancelarEdicaoRegistroAnterior"
                                                        :parametro1="true"
                                                        :tabindex="'-1'"
                                                        :style="`${registroAnterior.edicao ? 'margin-left: -4rem;' : ''}`"
                                                    />

                                                    <Button
                                                        :classes="`is-danger btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Remover registro anterior`" 
                                                        :onclick="removerRegistroAnterior"
                                                        :parametro1="registroAnterior.indice"
                                                        :parametro2="1"
                                                        :tabindex="'-1'"
                                                        v-show="registroAnterior.edicao"
                                                    />
                                                </div>
                                            </div>
                                            <div :class="`accordion-content show`">
                                                <div class="column is-2">
                                                    <Select 
                                                        :label="`Tipo do registro anterior`" 
                                                        :preenchido="true"
                                                        v-model="registroAnterior.tipoRegistroAnterior"
                                                        ref="selectTipoRegistro"
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposRegistro" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Ato do registro anterior`" 
                                                        :preenchido="true"
                                                        v-model="registroAnterior.ato"
                                                    />
                                                </div>

                                                <div class="column is-3">
                                                    <InputText
                                                        :label="`Número (matrícula, etc.)`" 
                                                        :preenchido="true"
                                                        v-model="registroAnterior.numeroRegistroAnterior"
                                                    />
                                                </div>

                                                <div class="column is-2" v-show="false">
                                                    <InputText
                                                        :label="`Data do registro anterior`" 
                                                        :preenchido="true"
                                                        :tipo="`date`"
                                                        v-model="registroAnterior.dataRegistroAnterior"
                                                    />
                                                </div> 

                                                <div class="column is-5">
                                                    <Select 
                                                        :label="`CNS`" 
                                                        :preenchido="true"
                                                        v-model="registroAnterior.cnsRegistroAnterior"
                                                        ref="selectCns"
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposCns" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>                                                     

                                                <div class="column is-12" v-show="registroAnterior.tipoRegistroAnterior == 4">
                                                    <Select 
                                                        :label="`Órgão emissor`" 
                                                        :preenchido="true"
                                                        v-model="registroAnterior.orgaoEmissor"
                                                        ref="selectOrgaoEmissor"
                                                    >
                                                        <option value="1">1 - INCRA</option>
                                                        <option value="2">2 - ITERPA - Instituto de terras do Pará</option>
                                                        <option value="3">3 - ITERTINS - Instituto de terras do Tocantins</option>
                                                        <option value="4">4 - ITERGO - Instituto de terras do Goiás</option>
                                                        <option value="5">5 - Carta de Sesmarias</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-full" ref="beneficiario" v-for="(beneficiario, indice) in registroAnterior?.beneficiario" style="display: contents;">
                                                    <div class="column is-3">
                                                        <InputText
                                                            :label="`CPF/CNPJ`" 
                                                            :preenchido="true"
                                                            :buscarDocumentoBeneficiario="buscarDocumentoBeneficiario"
                                                            :indice="indice"
                                                            :mascara="registroAnterior.beneficiario[indice].documento.replace(/\D/g, '').length == 11 ? '###.###.###-##' : registroAnterior.beneficiario[indice].documento.replace(/\D/g, '').length == 14 ? '##.###.###/####-##' : '#########################'"
                                                            v-model="registroAnterior.beneficiario[indice].documento"
                                                        />
                                                    </div>

                                                    <div class="column is-6">
                                                        <InputText
                                                            :label="`Nome/Razão social`" 
                                                            :preenchido="true"
                                                            :loading="registroAnterior.loading[indice]"
                                                            v-model="registroAnterior.beneficiario[indice].nome"
                                                        />
                                                    </div>
                                                    
                                                    <Button
                                                        :classes="`is-danger is-outlined btn-small`" 
                                                        :icone="`close`" 
                                                        :titulo="``" 
                                                        :onclick="removerBeneficiario"
                                                        :parametro1="indice"
                                                        :tabindex="'-1'"
                                                        style="margin-left: 0px; margin-right: 1%;"
                                                    />
                                                </div>

                                                <div class="column is-full">
                                                    <Button
                                                        :classes="`is-primary is-outlined btn-small`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`Adicionar beneficiário`" 
                                                        :onclick="adicionarBeneficiario"
                                                        style="margin-left: -1.8rem; margin-top: 0.5rem;"
                                                    />
                                                </div>  
                                                
                                                <div class="column is-12"> 
                                                    <Button
                                                        :classes="`is-purple`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`${registroAnterior.edicao ? 'Salvar' : 'Adicionar'} registro anterior (CTRL + Q)`" 
                                                        :tabUltimoCampo="tabUltimoCampo"
                                                        :tab="`atos`"
                                                        :onclick="adicionarRegistroAnterior"
                                                        style="margin-bottom: 1rem; margin-topo: 1rem; width: 40rem; float: right;"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="formAtivo == 'atos'">
                                <div class="column is-half listaAtos" v-show="listaAtos.length > 0" style="padding: 0px!important; margin-bottom: 1rem;">
                                    <Select 
                                        :label="`Lista de atos`" 
                                        :labelTotal="`Total de atos: ${listaAtos.length}`" 
                                        :textoOption="`Selecione um ato para editar`" 
                                        :preenchido="true"
                                        ref="selectAto"
                                        :selecionarOpcao="selecionarAto"
                                    >
                                        <option 
                                            v-for="(atoUnico, idx) in listaAtos" 
                                            :value="idx"
                                            v-if="atoUnico.numeroAto"
                                        >{{ atoUnico.numeroAto }} {{ atoUnico.descricao != '' ? '-' : '' }} {{ atoUnico.descricao }} {{ tiposAto[atoUnico.tipo] ? ` - ${tiposAto[atoUnico.tipo].split(' - ')[1]}` : '' }} {{ atoUnico.descricaoAdicional ? ` - ${atoUnico.descricaoAdicional}` : '' }} {{ atoUnico.onus == 1 ? ` - Ônus ativo` : '' }}</option>
                                    </Select>
                                </div>

                                <div class="columns ato" ref="ato">
                                    <div class="accordion">
                                        <div class="accordion-item">
                                            <div class="accordion-header" style="background-color: #f1f1f1;">
                                                <span :class="{ onusAtivo: ato.onus == 1 }" style="margin-left: 0.7rem;">
                                                    Ato {{ ato.numeroAto }} {{ ato.descricao != '' ? '-' : '' }} {{ ato.descricao }} {{ tiposAto[ato.tipo] ? ` - ${tiposAto[ato.tipo].split(' - ')[1]}` : '' }} {{ ato.descricaoAdicional ? ` - ${ato.descricaoAdicional}` : '' }} {{ ato.onus == 1 ? ` - Ônus ativo` : '' }}
                                                </span>
                                                <div :style="`display: flex; align-items: center;`">
                                                    <Button
                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Cancelar ${ato.edicao ? 'edição' : 'cadastro'} do ato`" 
                                                        :onclick="cancelarEdicaoAto"
                                                        :parametro1="true"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Transferir ato`" 
                                                        :onclick="transferirAto"
                                                        :parametro1="ato.indice"
                                                        :parametro2="ato.numeroAto"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Copiar ato`" 
                                                        :onclick="copiarAto"
                                                        :parametro1="ato.indice"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-danger btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Remover ato`" 
                                                        :onclick="removerAto"
                                                        :parametro1="ato.indice"
                                                        :parametro2="1"
                                                        :tabindex="'-1'"
                                                        v-show="ato.edicao"
                                                    />
                                                </div>
                                            </div>
                                            <div :class="`accordion-content show`">
                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Número do ato`"
                                                        :preenchido="true"
                                                        v-model="ato.numeroAto"
                                                    />
                                                </div>
                                                
                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Data do registro`"
                                                        :preenchido="true"
                                                        :tipo="`date`"
                                                        v-model="ato.dataRegistro"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <Select 
                                                        :label="`Tipo do ato`" 
                                                        :preenchido="true"
                                                        v-model="ato.descricao"
                                                        ref="selectTipoAto"
                                                    >
                                                        <option value="AB">0 - AB</option>
                                                        <option value="AV">1 - AV</option>
                                                        <option value="R">2 - R</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-3">
                                                    <Select 
                                                        :label="`Título do ato`" 
                                                        :preenchido="true"
                                                        v-model="ato.tipo"
                                                        ref="selectTituloAto"
                                                    >
                                                        <option 
                                                            v-for="(tipo, idx) in tiposAto" 
                                                            :value="idx"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-3">
                                                    <InputText
                                                        :label="`Descrição`" 
                                                        :preenchido="true"
                                                        v-model="ato.descricaoAdicional"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Data do instrumento`" 
                                                        :preenchido="true"
                                                        :tipo="`date`"
                                                        v-model="ato.dataInstrumento"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <Select 
                                                        :label="`Tipo da moeda`" 
                                                        :preenchido="true"
                                                        v-model="ato.tipoMoeda"
                                                        ref="selectTipoMoeda"
                                                    >
                                                        <option 
                                                            v-for="(tipo, iMoeda) in tiposMoedas" 
                                                            :value="iMoeda"
                                                        >{{ tipo }}</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Valor declarado`"
                                                        :preenchido="true"
                                                        v-model="ato.valorDeclarado"
                                                        :mascaraMonetaria="mascaraMonetaria"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Valor fiscal`"
                                                        :preenchido="true"
                                                        v-model="ato.valorFiscal"
                                                        :mascaraMonetaria="mascaraMonetaria"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <Select 
                                                        :label="`Ônus`" 
                                                        :preenchido="true"
                                                        v-model="ato.onus"
                                                        ref="selectOnus"
                                                    >
                                                        <option value="0">0 - Não</option>
                                                        <option value="1">1 - Sim</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-2">
                                                    <Select 
                                                        :label="`Tipo de transação`" 
                                                        :preenchido="true"
                                                        v-model="ato.tipoTransacao"
                                                        ref="selectTipoTransacao"
                                                    >
                                                        <option value="Total">0 - Total</option>
                                                        <option value="Parcial">1 - Parcial</option>
                                                    </Select>
                                                </div>

                                                <div class="column is-4" v-show="ato.tipo == 207">
                                                    <Select 
                                                        :label="`Cancelamento`" 
                                                        :preenchido="true"
                                                        :multiple="true"
                                                        v-model="ato.cancelamento"
                                                        ref="selectCancelamento"
                                                    >
                                                    </Select>
                                                </div>

                                                <div class="column is-2" v-show="autenticacao.escreventes">
                                                    <InputText
                                                        :label="`Selo de fiscalização`"
                                                        :preenchido="true"
                                                        v-model="ato.seloFiscalizacao"
                                                    />
                                                </div>

                                                <hr style="width: 100%; margin-bottom: 0.5rem;">

                                                <div class="column is-half listaPartes" style="margin-left: 2rem; margin-bottom: 1rem;" v-show="form.ato[ato.indice]?.pessoal && form.ato[ato.indice].pessoal.length > 0">
                                                    <Select 
                                                        :label="`Lista de partes`" 
                                                        :labelTotal="`Total de partes: ${form.ato[ato.indice]?.pessoal.length ?? 0}`" 
                                                        :textoOption="`Selecione uma parte para editar`" 
                                                        :preenchido="true"
                                                        ref="selectParteAto"
                                                        key="selectParteAto"
                                                        :tabindex="'-1'"
                                                        :selecionarOpcao="selecionarParteAto"
                                                    >
                                                        <option 
                                                            v-for="(pessoal, idx) in form.ato[ato.indice]?.pessoal" 
                                                            :value="idx"
                                                        >{{ idx+1 }} - {{ pessoal.classificacao }} {{ pessoal.documento != '' ? ` - ${pessoal.documento}` : '' }} {{ pessoal.nome != '' ? ` - ${pessoal.nome}` : '' }}</option>
                                                    </Select>
                                                </div>

                                                <div class="columns pessoal" :style="`width: 100%; margin: 0rem 2rem;`" ref="pessoal" :key="`pessoal`">
                                                    <div class="accordion">
                                                        <div class="accordion-item">
                                                            <div class="accordion-header">
                                                                <span style="margin-left: 0.7rem;">
                                                                    Parte {{ ato.pessoal.documento != '' ? ` - ${ato.pessoal.documento}` : ''  }} {{ ato.pessoal.nome != '' ? ` - ${ato.pessoal.nome}` : ''  }}
                                                                </span>
                                                                <div :style="`display: flex; align-items: center; justify-content: flex-end`">
                                                                    <Button
                                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                                        :icone="``" 
                                                                        :titulo="`Cancelar ${ato.pessoal.edicao ? 'edição' : 'cadastro'} da parte`" 
                                                                        :onclick="cancelarEdicaoParteAto"
                                                                        :parametro1="false"
                                                                        :tabindex="'-1'"
                                                                    />
                                                                    
                                                                    <Button
                                                                        :classes="`is-danger btn-small-alt`" 
                                                                        :icone="``" 
                                                                        :titulo="`Remover parte`" 
                                                                        :onclick="removerParteAto"
                                                                        :tabindex="'-1'"
                                                                        v-show="ato.pessoal.edicao"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div :class="`accordion-content show`">
                                                                <div class="column is-2" :ref="`parte_ato`">
                                                                    <Select 
                                                                        :label="`Classificação`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.classificacao"
                                                                        ref="selectClassificacaoAto"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposClassificacao" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2">
                                                                    <Select 
                                                                        :label="`Tipo do documento`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.tipoDocumento"
                                                                        ref="selectTipoDocumentoAto"
                                                                    >
                                                                        <option value="CPF">1 - CPF</option>
                                                                        <option value="CIC">2 - CIC</option>
                                                                        <option value="CNPJ">3 - CNPJ</option>
                                                                        <option value="RG">4 - RG</option>
                                                                        <option value="CGC">5 - CGC</option>
                                                                        <option value="OUTROS">6 - OUTROS</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-fifth">
                                                                    <InputText
                                                                        :label="`Nº do documento`" 
                                                                        :preenchido="true"
                                                                        :mascara="ato.pessoal.mascara"
                                                                        :inconsistencia="definirInconsistencia"
                                                                        v-model="ato.pessoal.documento"
                                                                        :buscarDocumento="buscarDocumento"
                                                                        :aba="`ato`"
                                                                    />
                                                                </div>

                                                                <div class="column is-5">
                                                                    <InputText
                                                                        :label="verificaCpfCnpj(ato.pessoal?.documento) != undefined ? verificaCpfCnpj(ato.pessoal?.documento) ? `Nome` : `Razão social` : `Nome/Razão social`" 
                                                                        :preenchido="true"
                                                                        :loading="ato.pessoal.loading ?? false"
                                                                        v-model="ato.pessoal.nome"
                                                                    />
                                                                </div>

                                                                <div style="display: flex">             
                                                                    <Button
                                                                        :classes="`is-warning-alt is-outlined btn-small`" 
                                                                        :icone="`danger`" 
                                                                        :titulo="``" 
                                                                        :onclick="definirInconsistencia"
                                                                        :parametro1="`ato`"
                                                                        :tabindex="'-1'"
                                                                        style="margin-left: 0;"
                                                                    />
                                                                </div>

                                                                <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ'">
                                                                    <Select 
                                                                        :label="`Nacionalidade`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.nacionalidade"
                                                                        ref="selectNacionalidadeAto"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposNacionalidade" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ'">
                                                                    <Select 
                                                                        :label="`Estado civil`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.estadoCivil" 
                                                                        ref="selectEstadoCivilAto"                                                                      
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposEstadosCivil" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-third" v-show="(ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2) && ato.pessoal.tipoDocumento != 'CNPJ'">
                                                                    <Select 
                                                                        :label="`Regime de bens`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.regimeBens"
                                                                        ref="selectRegimeAto"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposRegimeBens" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-fifth" v-show="(ato.pessoal.estadoCivil == '10' || ato.pessoal.estadoCivil == '14')">
                                                                    <Select 
                                                                        :label="`União estável`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.uniaoEstavel"
                                                                        ref="selectUniaoEstavelAto"
                                                                    >
                                                                        <option value="0">0 - Não</option>
                                                                        <option value="1">1 - Sim</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2">
                                                                    <InputText
                                                                        :label="`Fração`"
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.fracao"
                                                                    />
                                                                </div>

                                                                <div class="column is-2">
                                                                    <InputText
                                                                        :label="`Valor fracional`"
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.valorFracional"
                                                                        :mascaraMonetaria="mascaraMonetaria"
                                                                    />
                                                                </div>

                                                                <div 
                                                                    v-if="!(ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2))"
                                                                    :class="{
                                                                        column: true,
                                                                        'is-1': ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15),
                                                                        'is-3': ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil != 1 && ato.pessoal.estadoCivil != 2 && ato.pessoal.estadoCivil != 10 && ato.pessoal.estadoCivil != 14)
                                                                    }"
                                                                ></div>

                                                                <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                    <Select 
                                                                        :label="`Tipo do documento do cônjuge`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.tipoDocumentoConjuge"
                                                                        ref="selectTipoDocumentoConjugeAto"
                                                                    >
                                                                        <option value="CPF">1 - CPF</option>
                                                                        <option value="CIC">2 - CIC</option>
                                                                        <option value="CNPJ">3 - CNPJ</option>
                                                                        <option value="RG">4 - RG</option>
                                                                        <option value="CGC">5 - CGC</option>
                                                                        <option value="OUTROS">6 - OUTROS</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                    <InputText
                                                                        :label="`Nº do documento do cônjuge`" 
                                                                        :preenchido="true"
                                                                        :mascara="ato.pessoal.mascaraConjuge"
                                                                        v-model="ato.pessoal.documentoConjuge"
                                                                        :buscarDocumentoConjuge="buscarDocumentoConjuge"
                                                                        :aba="`ato`"
                                                                    />
                                                                </div>

                                                                <div class="column is-two-fifths" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                    <InputText
                                                                        :label="`Nome do cônjuge`" 
                                                                        :preenchido="true"
                                                                        :loading="ato.pessoal.loadingConjuge"
                                                                        :adicionarConjuge="adicionarConjugeAto"
                                                                        v-model="ato.pessoal.nomeConjuge"
                                                                    />
                                                                </div>

                                                                <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))"></div>
                                                                
                                                                <div class="column is-one-quarter" v-show="ato.pessoal.opcaoInconsistencia ?? false">
                                                                    <Select 
                                                                        :label="`Inconsistência`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.inconsistencia"
                                                                        ref="selectInconsistenciaAto"
                                                                    >
                                                                        <option value="1">1 - CPF com inconsistência</option>
                                                                        <option value="2">2 - CIC em conjunto</option>
                                                                        <option value="3">3 - Nome diferente</option>
                                                                        <option value="4">4 - CIC dependente</option>
                                                                        <option value="5">5 - CPF dependente</option>
                                                                        <option value="6">6 - CNPJ com inconsistência</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="linha-divisoria" v-show="ato.pessoal.nomes && ato.pessoal.nomes.length > 0"><span>Nomes semelhantes</span></div>

                                                                <div class="column is-one-quarter" ref="nomeSemelhante" v-for="(nomes, indiceNome) in ato.pessoal.nomes" style="display: contents;">
                                                                    <InputText
                                                                        :label="`Nome semelhante`"
                                                                        :preenchido="true"
                                                                        v-bind:ref="`nomes_ato_${indiceNome}`"
                                                                        v-model="ato.pessoal.nomes[indiceNome]"
                                                                        style="width: 26%; margin-left: 9px"
                                                                    />
                                                                    
                                                                    <Button
                                                                        :classes="`is-danger is-outlined btn-small`" 
                                                                        :icone="`close`" 
                                                                        :titulo="``" 
                                                                        :onclick="removerNome"
                                                                        :parametro1="`ato`"
                                                                        :parametro2="indiceNome"
                                                                        :tabindex="'-1'"
                                                                        style="margin-left: 0px; margin-right: 1%;"
                                                                    />
                                                                </div>

                                                                <div class="column is-full">
                                                                    <Button
                                                                        :classes="`is-primary btn-small`" 
                                                                        :icone="`math-plus`" 
                                                                        :titulo="`Adicionar nome semelhante`" 
                                                                        :onclick="adicionarNome"
                                                                        :parametro1="`ato`"
                                                                        :parametro2="`nomes_ato`"
                                                                        style="margin-left: -3.3rem; margin-top: 0.3rem;; width: 22rem"
                                                                    />
                                                                </div>

                                                                <div class="linha-divisoria" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1)) && ato.pessoal.conjuge.nome != ''"><span>Dados do cônjuge</span></div>

                                                                <div class="columns" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1)) && ato.pessoal.conjuge.nome != ''">
                                                                    <div class="column is-2" :ref="`parte_ato_conjuge`">
                                                                        <Select 
                                                                            :label="`Classificação`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.classificacao"
                                                                            ref="selectClassificacaoAtoConjuge"
                                                                        >
                                                                            <option 
                                                                                v-for="(tipo, idx) in tiposClassificacao" 
                                                                                :value="idx"
                                                                            >{{ tipo }}</option>
                                                                        </Select>
                                                                    </div>

                                                                    <div class="column is-2">
                                                                        <Select 
                                                                            :label="`Tipo do documento`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.tipoDocumento"
                                                                            ref="selectTipoDocumentoAtoConjuge"
                                                                        >
                                                                            <option value="CPF">1 - CPF</option>
                                                                            <option value="CIC">2 - CIC</option>
                                                                            <option value="CNPJ">3 - CNPJ</option>
                                                                            <option value="RG">4 - RG</option>
                                                                            <option value="CGC">5 - CGC</option>
                                                                            <option value="OUTROS">6 - OUTROS</option>
                                                                        </Select>
                                                                    </div>

                                                                    <div class="column is-one-fifth">
                                                                        <InputText
                                                                            :label="`Nº do documento`" 
                                                                            :preenchido="true"
                                                                            :mascara="ato.pessoal.conjuge.mascara"
                                                                            :inconsistencia="definirInconsistencia"
                                                                            v-model="ato.pessoal.conjuge.documento"
                                                                            :buscarDocumento="buscarDocumento"
                                                                            :aba="`ato`"
                                                                            :conjuge="true"
                                                                        />
                                                                    </div>

                                                                    <div class="column is-5">
                                                                        <InputText
                                                                            :label="verificaCpfCnpj(ato.pessoal?.documento) != undefined ? verificaCpfCnpj(ato.pessoal?.documento) ? `Nome` : `Razão social` : `Nome/Razão social`" 
                                                                            :preenchido="true"
                                                                            :loading="ato.pessoal.conjuge.loading ?? false"
                                                                            v-model="ato.pessoal.conjuge.nome"
                                                                        />
                                                                    </div>

                                                                    <div style="display: flex">             
                                                                        <Button
                                                                            :classes="`is-warning-alt is-outlined btn-small`" 
                                                                            :icone="`danger`" 
                                                                            :titulo="``" 
                                                                            :onclick="definirInconsistenciaConjuge"
                                                                            :parametro1="`ato`"
                                                                            :tabindex="'-1'"
                                                                            style="margin-left: 0;"
                                                                        />
                                                                    </div>

                                                                    <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ'">
                                                                        <Select 
                                                                            :label="`Nacionalidade`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.nacionalidade"
                                                                            ref="selectNacionalidadeAtoConjuge"
                                                                        >
                                                                            <option 
                                                                                v-for="(tipo, idx) in tiposNacionalidade" 
                                                                                :value="idx"
                                                                            >{{ tipo }}</option>
                                                                        </Select>
                                                                    </div>

                                                                    <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ'">
                                                                        <Select 
                                                                            :label="`Estado civil`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.estadoCivil" 
                                                                            ref="selectEstadoCivilAtoConjuge"                                                                      
                                                                        >
                                                                            <option 
                                                                                v-for="(tipo, idx) in tiposEstadosCivil" 
                                                                                :value="idx"
                                                                            >{{ tipo }}</option>
                                                                        </Select>
                                                                    </div>

                                                                    <div class="column is-one-third" v-show="(ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2) && ato.pessoal.conjuge.tipoDocumento != 'CNPJ'">
                                                                        <Select 
                                                                            :label="`Regime de bens`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.regimeBens"
                                                                            ref="selectRegimeAtoConjuge"
                                                                        >
                                                                            <option 
                                                                                v-for="(tipo, idx) in tiposRegimeBens" 
                                                                                :value="idx"
                                                                            >{{ tipo }}</option>
                                                                        </Select>
                                                                    </div>

                                                                    <div class="column is-one-fifth" v-show="(ato.pessoal.conjuge.estadoCivil == '10' || ato.pessoal.conjuge.estadoCivil == '14')">
                                                                        <Select 
                                                                            :label="`União estável`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.uniaoEstavel"
                                                                            ref="selectUniaoEstavelAtoConjuge"
                                                                        >
                                                                            <option value="0">0 - Não</option>
                                                                            <option value="1">1 - Sim</option>
                                                                        </Select>
                                                                    </div>          

                                                                    <div class="column is-2">
                                                                        <InputText
                                                                            :label="`Fração`"
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.fracao"
                                                                        />
                                                                    </div>

                                                                    <div class="column is-2">
                                                                        <InputText
                                                                            :label="`Valor fracional`"
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.valorFracional"
                                                                            :mascaraMonetaria="mascaraMonetaria"
                                                                        />
                                                                    </div>

                                                                    <div 
                                                                        v-if="!(ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2))"
                                                                        :class="{
                                                                            column: true,
                                                                            'is-1': ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15),
                                                                            'is-3': ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil != 1 && ato.pessoal.conjuge.estadoCivil != 2 && ato.pessoal.conjuge.estadoCivil != 10 && ato.pessoal.conjuge.estadoCivil != 14)
                                                                        }"
                                                                    ></div>

                                                                    <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2 || ((ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15) && ato.pessoal.conjuge.uniaoEstavel == 1))">
                                                                        <Select 
                                                                            :label="`Tipo do documento do cônjuge`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.tipoDocumentoConjuge"
                                                                            ref="selectTipoDocumentoConjugeAtoConjuge"
                                                                        >
                                                                            <option value="CPF">1 - CPF</option>
                                                                            <option value="CIC">2 - CIC</option>
                                                                            <option value="CNPJ">3 - CNPJ</option>
                                                                            <option value="RG">4 - RG</option>
                                                                            <option value="CGC">5 - CGC</option>
                                                                            <option value="OUTROS">6 - OUTROS</option>
                                                                        </Select>
                                                                    </div>

                                                                    <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2 || ((ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15) && ato.pessoal.conjuge.uniaoEstavel == 1))">
                                                                        <InputText
                                                                            :label="`Nº do documento do cônjuge`" 
                                                                            :preenchido="true"
                                                                            :mascara="ato.pessoal.conjuge.mascaraConjuge"
                                                                            v-model="ato.pessoal.conjuge.documentoConjuge"
                                                                            :buscarDocumentoConjuge="buscarDocumentoConjuge"
                                                                            :aba="`ato`"
                                                                            :conjuge="true"
                                                                        />
                                                                    </div>

                                                                    <div class="column is-two-fifths" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2 || ((ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15) && ato.pessoal.conjuge.uniaoEstavel == 1))">
                                                                        <InputText
                                                                            :label="`Nome do cônjuge`" 
                                                                            :preenchido="true"
                                                                            :loading="ato.pessoal.conjuge.loadingConjuge"
                                                                            v-model="ato.pessoal.conjuge.nomeConjuge"
                                                                        />
                                                                    </div>

                                                                    <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2 || ((ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15) && ato.pessoal.conjuge.uniaoEstavel == 1))"></div>
                                                                    
                                                                    <div class="column is-one-quarter" v-show="ato.pessoal.conjuge.opcaoInconsistencia ?? false">
                                                                        <Select 
                                                                            :label="`Inconsistência`" 
                                                                            :preenchido="true"
                                                                            v-model="ato.pessoal.conjuge.inconsistencia"
                                                                            ref="selectInconsistenciaAtoConjuge"
                                                                        >
                                                                            <option value="1">1 - CPF com inconsistência</option>
                                                                            <option value="2">2 - CIC em conjunto</option>
                                                                            <option value="3">3 - Nome diferente</option>
                                                                            <option value="4">4 - CIC dependente</option>
                                                                            <option value="5">5 - CPF dependente</option>
                                                                            <option value="6">6 - CNPJ com inconsistência</option>
                                                                        </Select>
                                                                    </div>

                                                                    <div class="linha-divisoria" v-show="ato.pessoal.conjuge.nomes && ato.pessoal.conjuge.nomes.length > 0"><span>Nomes semelhantes</span></div>

                                                                    <div class="column is-one-quarter" ref="nomeSemelhante" v-for="(nomes, indiceNome) in ato.pessoal.conjuge.nomes" style="display: contents;">
                                                                        <InputText
                                                                            :label="`Nome semelhante`"
                                                                            :preenchido="true"
                                                                            :ref="`nomes_ato_conjuge_${indiceNome}`"
                                                                            v-model="ato.pessoal.conjuge.nomes[indiceNome]"
                                                                            style="width: 26%; margin-left: 9px"
                                                                        />
                                                                        
                                                                        <Button
                                                                            :classes="`is-danger is-outlined btn-small`" 
                                                                            :icone="`close`" 
                                                                            :titulo="``" 
                                                                            :onclick="removerNomeConjuge"
                                                                            :parametro1="`ato`"
                                                                            :parametro2="indiceNome"
                                                                            :tabindex="'-1'"
                                                                            style="margin-left: 0px; margin-right: 1%;"
                                                                        />
                                                                    </div>

                                                                    <div class="column is-full">
                                                                        <Button
                                                                            :classes="`is-primary btn-small`" 
                                                                            :icone="`math-plus`" 
                                                                            :titulo="`Adicionar nome semelhante`" 
                                                                            :onclick="adicionarNomeConjuge"
                                                                            :parametro1="`ato`"
                                                                            :parametro2="`nomes_ato_conjuge`"
                                                                            style="margin-left: -3.3rem; margin-top: 0.3rem; width: 22rem"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div class="column is-full" :style="`display: flex; justify-content: ${partesAdicionadas.length > 0 ? 'space-between' : 'flex-end'}`"> 
                                                                    <div v-show="partesAdicionadas.length > 0" style="margin-left: -3.3rem;">
                                                                        <Button
                                                                            :classes="`is-info is-outlined btn-small`" 
                                                                            :icone="`math-plus`" 
                                                                            :titulo="`Selecionar partes informadas anteriormente`" 
                                                                            :onclick="selecionarPartes"
                                                                            :parametro1="ato.indice"
                                                                            style="margin-left: 0px; width: 22rem"
                                                                        />
                                                                    </div>
                                                                    <Button
                                                                        :classes="`is-link`" 
                                                                        :icone="`math-plus`" 
                                                                        :titulo="`${ato.pessoal.edicao ? 'Salvar' : 'Adicionar'} parte (ALT + Q)`" 
                                                                        :onclick="adicionarParteAto"
                                                                        :style="`margin-right: 1rem; margin-top: -0.5rem; margin-bottom: 1rem; width: 30rem`"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="column is-12"> 
                                                    <Button
                                                        :classes="`is-purple`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`${ato.edicao ? 'Salvar' : 'Adicionar'} ato (CTRL + Q)`" 
                                                        :tabUltimoCampo="tabUltimoCampo"
                                                        :tab="`indisponibilidade`"
                                                        :onclick="adicionarAto"
                                                        style="margin-bottom: 1rem; margin-top: -1rem; width: 40rem; float: right;"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="formAtivo == 'indisponibilidade'">
                                <div class="column is-half listaIndisponibilidade" v-show="listaIndisponibilidade.length > 0" style="padding: 0px!important; margin-bottom: 1rem;">
                                    <Select 
                                        :label="`Lista de indisponibilidade`" 
                                        :labelTotal="`Total de indisponibilidade: ${listaIndisponibilidade.length}`" 
                                        :textoOption="`Selecione um indisponibilidade para editar`" 
                                        :preenchido="true"
                                        ref="selectIndisponibilidade"
                                        :selecionarOpcao="selecionarIndisponibilidade"
                                    >
                                        <option 
                                            v-for="(indisponibilidadeUnica, idx) in listaIndisponibilidade" 
                                            :value="idx"
                                            v-if="indisponibilidadeUnica.dataIndisponibilidade"
                                        >{{ indisponibilidadeUnica.ato != '' ? `- ${indisponibilidadeUnica.ato}` : '' }} {{ indisponibilidadeUnica.documento != '' ? `- ${indisponibilidadeUnica.documento}` : '' }} {{ indisponibilidadeUnica.nome != '' ? `- ${indisponibilidadeUnica.nome}` : '' }}</option>
                                    </Select>
                                </div>

                                <div class="columns indisponibilidade" ref="indisponibilidade">
                                    <div class="accordion">
                                        <div class="accordion-item">
                                            <div class="accordion-header" style="background-color: #f1f1f1;">
                                                <span style="margin-left: 0.7rem;">
                                                    Indisponibilidade {{ indisponibilidade.ato != '' ? `- ${indisponibilidade.ato}` : '' }} {{ indisponibilidade.documento != '' ? `- ${indisponibilidade.documento}` : '' }} {{ indisponibilidade.nome != '' ? `- ${indisponibilidade.nome}` : '' }}
                                                </span>
                                                
                                                <div :style="`display: flex; align-items: center;`">
                                                    <Button
                                                        :classes="`is-secondary is-outlined btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Cancelar ${indisponibilidade.edicao ? 'edição' : 'cadastro'} da indisponibilidade`" 
                                                        :onclick="cancelarEdicaoIndisponibilidade"
                                                        :parametro1="true"
                                                        :tabindex="'-1'"
                                                    />

                                                    <Button
                                                        :classes="`is-danger btn-small-alt`" 
                                                        :icone="``" 
                                                        :titulo="`Remover indisponibilidade`" 
                                                        :onclick="removerIndisponibilidade"
                                                        :parametro1="indisponibilidade.indice"
                                                        :parametro2="1"
                                                        :tabindex="'-1'"
                                                        v-show="indisponibilidade.edicao"
                                                    />
                                                </div>
                                            </div>
                                            <div :class="`accordion-content show`">
                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Ato da indisponibilidade`" 
                                                        :preenchido="true"
                                                        v-model="indisponibilidade.ato"
                                                    />
                                                </div>
                                                
                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Data da indisponibilidade`" 
                                                        :preenchido="true"
                                                        :tipo="`date`"
                                                        v-model="indisponibilidade.dataIndisponibilidade"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Data da revogação`" 
                                                        :preenchido="true"
                                                        :tipo="`date`"
                                                        v-model="indisponibilidade.dataRevogacao"
                                                    />
                                                </div>

                                                <div class="column is-2">
                                                    <InputText
                                                        :label="`Documento`" 
                                                        :preenchido="true"
                                                        :mascara="indisponibilidade.mascara"
                                                        :buscarDocumento="buscarDocumento"
                                                        :aba="`indisponibilidade`"
                                                        v-model="indisponibilidade.documento"
                                                    />
                                                </div>

                                                <div class="column is-4">
                                                    <InputText
                                                        :label="`Nome/Razão social`" 
                                                        :preenchido="true"
                                                        :loading="indisponibilidade.loading"
                                                        v-model="indisponibilidade.nome"
                                                    />
                                                </div>

                                                <div class="column is-full">
                                                    <Textarea
                                                        :label="`Motivo`" 
                                                        :preenchido="true"
                                                        v-model="indisponibilidade.motivo"
                                                        :maxlength="`1000`"
                                                    />
                                                </div>
                                                
                                                <div class="column is-12"> 
                                                    <Button
                                                        :classes="`is-purple`" 
                                                        :icone="`math-plus`" 
                                                        :titulo="`${indisponibilidade.edicao ? 'Salvar' : 'Adicionar'} indisponibilidade (CTRL + Q)`" 
                                                        :onclick="adicionarIndisponibilidade"
                                                        style="margin-bottom: 1rem; margin-top: 1.5rem; width: 40rem; float: right;"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="formAtivo == 'proprietarios'">
                                <div class="columns column-table">                                        
                                    <table class="table is-fullwidth is-hoverable">
                                        <thead>
                                            <tr>
                                                <th style="min-width: 105px; width: 10%;">Ato</th>
                                                <th style="min-width: 135px; width: 10%;">Data</th>
                                                <th style="min-width: 100px; width: 8%;">N° do documento</th>
                                                <th style="min-width: 125px; width: 12%;">Nome/Razão social</th>
                                                <th style="min-width: 175px; width: 11%;">Qualificação</th>
                                                <th style="min-width: 175px; width: 11%;">Tipo da transação</th>
                                            </tr>
                                        </thead>
                                        <tbody v-show="(form.proprietarios ?? []).length > 0">
                                            <tr v-for="(proprietario, idx) in form.proprietarios ?? []">
                                                <td>{{ proprietario.ato }}</td>
                                                <td>{{ proprietario.data }}</td>
                                                <td>{{ proprietario.documento }}</td>
                                                <td>{{ proprietario.nome }}</td>
                                                <td>{{ proprietario.classificacao }}</td>
                                                <td>{{ proprietario.tipoTransacao }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="columns column-table column-nao-encontrado" style="width: 100%; margin-top: -1.5rem!important;" v-if="(form.proprietarios ?? []).length == 0">
                                        <div>Nenhum proprietário encontrado</div>
                                    </div>
                                </div>
                            </div>

                            <div class="btn-control" style="justify-content: space-between;">
                                <Button
                                    :classes="`is-danger is-outlined`" 
                                    :titulo="`Voltar`" 
                                    :tabindex="'-1'"
                                    :onclick="voltar" 
                                    v-if="edicao"
                                    :parametro1="1"
                                    style="width: 20rem"
                                />

                                <Button
                                    :classes="`is-danger is-outlined`" 
                                    :titulo="`Cancelar`" 
                                    :tabindex="'-1'"
                                    :onclick="cancelar" 
                                    v-else
                                    :parametro1="1"
                                    style="width: 20rem"
                                />

                                <div>
                                    <Button
                                        :classes="`is-link is-outlined`" 
                                        style="margin-right: 1rem; width: 20rem"
                                        :titulo="`Salvar rascunho`" 
                                        :onclick="enviar" 
                                        :parametro1="2" 
                                        v-if="form.numeroRegistro != '' && form.rascunho"
                                    />

                                    <Button
                                        :classes="`is-success`" 
                                        :titulo="`Salvar indicador (CTRL + Enter)`" 
                                        :onclick="enviar" 
                                        style="width: 20rem"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="formulario" style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 3rem;
                        ">
                            <Versao :horaAtual="horaAtual" />
                                                      
                            <Button
                                :classes="`is-${contagemRegressivaClass} btn-small`" 
                                style="margin-right: -45px; width: 16rem;"
                                :onclick="refreshToken"
                                :titulo="`${contagemRegressiva}`" 
                            />
                        </div>
                    <Button
                        :classes="`btn-focus`" 
                        style="background-color: #eee!important; pointer-events: none; border: none"
                    />
                    </div>
                </div>
            </section>
        </div>

        <div class="modal" :class="{ 'is-active': modalAjuda }">
            <div class="modal-background" @click="modalAjuda = false"></div>
            <div class="modal-card" style="width: 50vw;">
                <header class="modal-card-head">
                <p class="modal-card-title">Ajudas</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalAjuda = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content" style="margin-top: -20px;">
                        <ul>
                            <li style="margin-top: 1rem;">Utilize a tecla "TAB" para mover o foco para o próximo campo.</li>
                            <li style="margin-top: 1rem;">Nas abas Atos e Registro Anterior, pressione "CTRL + Q" para adicionar um novo item.</li>
                            <li style="margin-top: 1rem;">Pressione "CTRL + Enter" para salvar o cadastro.</li>
                            <li style="margin-top: 1rem;">Nos campos de seleção, digite a palavra ou parte dela para fazer uma pesquisa prévia e tecle "ENTER" para selecionar.</li>
                            <li style="margin-top: 1rem;">
                                O botão 
                                <Button
                                    :classes="`is-primary is-outlined`" 
                                    :icone="`danger`" 
                                    :titulo="``" 
                                    :tabindex="'-1'"
                                    style="margin-left: 0; transform: scale(0.7); margin-top: -8px;"
                                /> 
                                serve para adicionar uma anotação de "inconsistência".
                            </li>
                            <li style="margin-top: 1rem;">
                                O botão 
                                <Button
                                    :classes="`is-primary is-outlined`" 
                                    :icone="`math-plus`" 
                                    :titulo="`Adicionar nome semelhante`" 
                                    :tabindex="'-1'"
                                    style="margin-left: 0; transform: scale(0.7); margin-top: -8px; margin-left: -34px; margin-right: -36px;"
                                /> 
                                serve para adicionar um nome semelhante. <br> Ex.: Felipe / Filipe / Felipi / Phelipe.
                            </li>
                            <li style="margin-top: 1rem;">
                                O ícone 
                                <Button
                                    :classes="`is-danger is-outlined`" 
                                    :icone="``" 
                                    :titulo="``" 
                                    :tabindex="'-1'"
                                    style="margin-left: 0; transform: scale(0.7); margin-top: -8px;"
                                />
                                serve para remover um Ato ou Registro Anterior.
                            </li>
                            <li style="margin-top: 1rem;">
                                É possível navegar entre as abas utilizando as seguintes teclas:
                                <ul>
                                    <li>"ALT + 1" para Indicador Real</li>
                                    <li>"ALT + 2" para Imóvel</li>
                                    <li>"ALT + 3" para Atos</li>
                                    <li>"ALT + 4" para Registro Anterior</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: end;">
                    <button class="button is-info is-outlined" @click="modalAjuda = false">Fechar</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-confimacao" :class="{ 'is-active': modalCarregarCopia }">
            <div class="modal-background" @click="naoCarregarDados()"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Copiar dados</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="naoCarregarDados()"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja carregar os dados da última matrícula?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="naoCarregarDados()">Não</button>
                    <button class="button is-success is-outlined" @click="carregarDados(true)">Sim</button>
                </footer>
            </div>
        </div>


        <div class="modal" :class="{ 'is-active': modalCamposVazios }">
            <div class="modal-background" @click="modalCamposVazios = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalCamposVazios = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        <b v-html="mensagemCamposVazios"></b>
                        <br>
                        Deseja salvar a matrícula mesmo assim?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalCamposVazios = false">Não</button>
                    <button class="button is-success is-outlined" @click="enviar(4)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" :class="{ 'is-active': modalCarregarForm }">
            <div class="modal-background" @click="naoCarregarDados()"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Última matrícula não finalizada</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="naoCarregarDados()"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja carregar a última matrícula não finalizada?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="naoCarregarDados()">Não</button>
                    <button class="button is-success is-outlined" @click="carregarDados()">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-rascunho" :class="{ 'is-active': modalRascunho }">
            <div class="modal-background" @click="modalRascunho = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalRascunho = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja salvar os dados como rascunho?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalRascunho = false">Não</button>
                    <button class="button is-success is-outlined" @click="enviar(3)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" :class="{ 'is-active': modalConfimacao }">
            <div class="modal-background" @click="modalConfimacao = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Matrícula já cadastrada</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalConfimacao = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja atualizar a matrícula?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalConfimacao = false">Não</button>
                    <button class="button is-success is-outlined" @click="enviar(1)">Sim</button>
                </footer>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': modalCancelamento }">
            <div class="modal-background" @click="modalCancelamento = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cancelar operação</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalCancelamento = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente cancelar?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="modalCancelamento = false">Não</button>
                    <button class="button is-danger is-outlined" @click="cancelar(0)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-remover-ato" :class="{ 'is-active': modalRemoverAto }">
            <div class="modal-background" @click="modalRemoverAto = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalRemoverAto = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente remover o ato?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalRemoverAto = false">Não</button>
                    <button class="button is-success is-outlined" @click="removerAto(indiceRemoverAto, false)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-remover-averbacao" :class="{ 'is-active': modalRemoverAverbacao }">
            <div class="modal-background" @click="modalRemoverAverbacao = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalRemoverAverbacao = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente remover o ato?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalRemoverAverbacao = false">Não</button>
                    <button class="button is-success is-outlined" @click="removerAverbacao(false)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-remover-parte" :class="{ 'is-active': modalRemoverAverbacaoParte }">
            <div class="modal-background" @click="modalRemoverAverbacaoParte = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalRemoverAverbacaoParte = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente remover a parte?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalRemoverAverbacaoParte = false">Não</button>
                    <button class="button is-success is-outlined" @click="removerParteAverbacao(false)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-remover-parte" :class="{ 'is-active': modalRemoverRAParte }">
            <div class="modal-background" @click="modalRemoverRAParte = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalRemoverRAParte = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente remover a parte?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalRemoverRAParte = false">Não</button>
                    <button class="button is-success is-outlined" @click="removerParteRA(false)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-remover-parte" :class="{ 'is-active': modalRemoverParte }">
            <div class="modal-background" @click="modalRemoverParte = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalRemoverParte = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente remover a parte?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalRemoverParte = false">Não</button>
                    <button class="button is-success is-outlined" @click="removerParteAto(false)">Sim</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-selecionar-partes" :class="{ 'is-active': modalSelecionarPartes }">
            <div class="modal-background" @click="modalSelecionarPartes = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title"></p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalSelecionarPartes = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        <h5 style="text-align: center">Selecione as partes que você deseja copiar dos atos anteriores</h5>
                        <div style="margin: 0 2rem;">
                            <label class="checkbox" v-for="(parte, idx) in partesAdicionadas">
                                {{  parte.classificacao }} - {{  parte.documento == '' ? 'Documento não informado' : parte.documento }} - {{ parte.nome }}
                                <input type="checkbox" :id="`checkbox-${idx}`" v-model="parte.selecionado">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalSelecionarPartes = false">Cancelar</button>
                    <button class="button is-success is-outlined" @click="partesSelecionadas()">Adicionar</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-copiar-ato" :class="{ 'is-active': modalCopiarAto }">
            <div class="modal-background" @click="modalCopiarAto = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Copiar ato</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalCopiarAto = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-3">
                                <InputText
                                    :label="`Nº do ato inicial`"
                                    :preenchido="true"
                                    v-model="numeroAtoInicial"
                                />
                            </div>
                            <div class="column is-1" style="width: 4%; display: flex; transform: translate(-5px, -10px); align-items: flex-end;">
                                a
                            </div>
                            <div class="column is-3">
                                <InputText
                                    :label="`Nº do ato final`"
                                    :preenchido="true"
                                    v-model="numeroAtoFinal"
                                />
                            </div>
                            <div class="column is-1" style="width: 5%; display: flex; transform: translate(-6px, -10px); align-items: flex-end;">
                                de
                            </div>
                            <div class="column is-5">
                                <InputText
                                    :label="`Data do registro`" 
                                    :preenchido="true"
                                    :tipo="`date`"
                                    v-model="dataRegistro"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-12">
                                <label class="checkbox">
                                    Transferir partes envolvidas
                                    <input type="checkbox" v-model="transferirPartes">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalCopiarAto = false">Cancelar</button>
                    <button class="button is-success is-outlined" @click="copiarAto(indiceAto, false)">Salvar</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-copiar-ato" :class="{ 'is-active': modalCopiarEndereco }">
            <div class="modal-background" @click="modalCopiarEndereco = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Copiar endereço</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalCopiarEndereco = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-5">
                                <InputText
                                    :label="`Quantidade de cópias`"
                                    :preenchido="true"
                                    :mascara="`###########`"
                                    v-model="qtdeEndereco"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-3">
                                <label class="checkbox">
                                    Númerico
                                    <input type="checkbox" v-model="numerico" @click="alfanumerico = false; alfabetica = false">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            
                            <div class="column is-3">
                                <label class="checkbox">
                                    Alfabética
                                    <input type="checkbox" v-model="alfabetica" @click="numerico = false; alfanumerico = false">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            
                            <div class="column is-3">
                                <label class="checkbox">
                                    Alfanúmerico
                                    <input type="checkbox" v-model="alfanumerico" @click="numerico = false; alfabetica = false">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-3">
                                <InputText
                                    :label="`Lote inicial`"
                                    :preenchido="true"
                                    v-model="loteInicial"
                                />
                            </div>
                            <div class="column is-1" style="width: 4%; display: flex; transform: translate(-5px, -10px); align-items: flex-end;">
                                a
                            </div>
                            <div class="column is-3">
                                <InputText
                                    :label="`Lote final`"
                                    :preenchido="true"
                                    v-model="loteFinal"
                                />
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-3">
                                <InputText
                                    :label="`Quadra inicial`"
                                    :preenchido="true"
                                    v-model="quadraInicial"
                                />
                            </div>
                            <div class="column is-1" style="width: 4%; display: flex; transform: translate(-5px, -10px); align-items: flex-end;">
                                a
                            </div>
                            <div class="column is-3">
                                <InputText
                                    :label="`Quadra final`"
                                    :preenchido="true"
                                    v-model="quadraFinal"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalCopiarEndereco = false">Cancelar</button>
                    <button class="button is-success is-outlined" @click="copiarEndereco(false)">Salvar</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-copiar-ato" :class="{ 'is-active': modalTransferirAto }">
            <div class="modal-background" @click="modalTransferirAto = false"></div>
            <div class="modal-card" style="width: 840px">
                <header class="modal-card-head">
                    <p class="modal-card-title">Transferir ato</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalTransferirAto = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-one-fifth">
                                <InputText
                                    :label="`Nº do ato`"
                                    :preenchido="true"
                                    v-model="numeroAto"
                                />
                            </div>
                            <div class="column is-1" style="width: 6%; display: flex; transform: translate(-5px, -10px); align-items: flex-end;">
                                para:
                            </div>
                            <div class="column is-one-fifth">
                                <InputText
                                    :label="`Matrícula inicial`"
                                    :preenchido="true"
                                    v-model="numeroMatriculaInicial"
                                />
                            </div>
                            <div class="column is-1" style="width: 3%; display: flex; transform: translate(-4px, -10px); align-items: flex-end;">
                                a
                            </div>
                            <div class="column is-one-fifth">
                                <InputText
                                    :label="`Matrícula final`"
                                    :preenchido="true"
                                    v-model="numeroMatriculaFinal"
                                />
                            </div>
                            <div class="column is-1" style="width: 4%; display: flex; transform: translate(-5px, -10px); align-items: flex-end;">
                                de
                            </div>
                            <div class="column is-3">
                                <InputText
                                    :label="`Data do registro`" 
                                    :preenchido="true"
                                    :tipo="`date`"
                                    v-model="dataRegistro"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-12">
                                <label class="checkbox">
                                    Transferir partes envolvidas
                                    <input type="checkbox" v-model="transferirPartes">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalTransferirAto = false">Cancelar</button>
                    <button class="button is-success is-outlined" @click="transferirAto(indiceAto, numeroAto, false)">Salvar</button>
                </footer>
            </div>
        </div>
        <div class="loading-container hidden" id="loading">
            <div class="loading-spinner"></div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import bigInt from "big-integer"
    import qs from "qs"
    import moment from "moment"
    import { Buffer } from 'buffer'
    import { cpf, cnpj } from 'cpf-cnpj-validator'
    import dbPromise from '../../indexedDB.js';

    export default {
        name: "Indicadores",
        components: {},
        data(){
            return {
                mascaraMonetaria: {
                    prefix: '',
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false
                },
                averbacao: {
                    indice: 0,
                    edicao: false,
                    numeroAto: '',
                    dataRegistro: '',
                    descricaoAto: '',
                    dataInstrumento: '',
                    pessoal: {
                        indice: 0,
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false,
                    }
                },
                descricaoRegistroAuxiliar: {
                    pessoal: {
                        indice: 0,
                        edicao: false,
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false
                    }
                },
                ato: {
                    indice: 0,
                    edicao: false,
                    numeroAto: '',
                    dataRegistro: '',
                    descricao: '',
                    tipo: '',
                    descricaoAdicional: '',
                    dataInstrumento: '',
                    tipoMoeda: '',
                    valorDeclarado: '',
                    valorFiscal: '',
                    onus: '',
                    tipoTransacao: '',
                    cancelamento: '',
                    seloFiscalizacao: '',
                    pessoal: {
                        indice: 0,
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        fracao: '',
                        valorFracional: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false,
                        conjuge: {
                            documento: '',
                            nome: '',
                            nacionalidade: '',
                            fracao: '',
                            valorFracional: '',
                            estadoCivil: '',
                            uniaoEstavel: '',
                            regimeBens: '',
                            classificacao: '',
                            tipoDocumento: '',
                            tipoDocumentoConjuge: '',
                            documentoConjuge: '',
                            nomeConjuge: '',
                            inconsistencia: '',
                            conjuge: '',
                            nomes: [],
                            loading: false,
                            loadingConjuge: false,
                            opcaoInconsistencia: false,
                            mascara: false,
                            mascaraConjuge: false
                        }
                    }
                },
                registroAnterior: {
                    indice: 0,
                    edicao: false,
                    loading: [false],
                    ato: '',
                    numeroRegistroAnterior: '',
                    tipoRegistroAnterior: '',
                    cnsRegistroAnterior: '111211',
                    dataRegistroAnterior: '',
                    orgaoEmissor: '',
                    beneficiario: [
                        {
                            documento: '',
                            nome: ''
                        }
                    ]
                },
                indisponibilidade: {
                    indice: 0,
                    edicao: false,
                    loading: false,
                    ato: '',
                    dataIndisponibilidade: '',
                    dataRevogacao: '',
                    documento: '',
                    nome: '',
                    motivo: '',
                    mascara: '###################'
                },
                endereco: {
                    indice: 0,
                    edicao: false,
                    loading: false,
                    cep: '',
                    tipoLogradouro: '',
                    nomeLogradouro: '',
                    bairro: '',
                    numero: '',
                    complemento: '',
                    complementoQuadra: '',
                    quadra: '',
                    lote: '',
                    loteQuadras: []
                },
                documentosBuscados: [],
                listaAtos: [],
                listaEnderecos: [],
                listaLotesQuadras: [],
                listaAverbacoes: [],
                listaRegistros: [],
                listaIndisponibilidade: [],
                rerenderKey: 0,
                rerenderKeyGeral: 0,
                validacao: {
                    cns: true,
                    cnm: true,
                    numeroRegistro: true,
                    letraRegistro: true,
                    numeroCedula: true,
                    onusAtivo: true,
                    l2Vinculado: true,
                    numeroMatricula: true,
                    dataAbertura: true,
                    livroVinculado: true,
                    anotacoes: true,
                    descricaoLivroVinculado: true,
                    descricaoAnotacoes: true,
                    tipoRegistro: true,
                    tipoLivro3: true,
                    beneficiariosLivroVinculado: [
                        {
                            naturezaTitulo: true,
                            numero: true,
                            documento: true,
                            nome: true
                        }
                    ],
                    inscricao: true,
                    registroAuxiliar: true,
                    livroTranscricao: true,
                    folhaTranscricao: true,
                    enderecos: [
                        {
                            cep: true,
                            tipoLogradouro: true,
                            nomeLogradouro: true,
                            bairro: true,
                            numero: true,
                            complemento: true,
                            complementoQuadra: true,
                            loteQuadras: [
                                {
                                    quadra: true,
                                    lote: true
                                }
                            ]
                        }
                    ],
                    tipoImovel: true,
                    unidade: true,
                    bloco: true,
                    contribuinte: true,
                    fracaoAreaComum: true,
                    fracaoTempo: true,
                    vagas: true,
                    nomeCondominio: true,
                    complemento: true,
                    setor: true,
                    loteamento: true,
                    cidade: true,
                    uf: true,
                    inscricaoMunicipal: true,
                    nirf: true,
                    sigef: true,
                    conjunto: true,
                    condominioConjunto: true,
                    numeroIncra: true,
                    acidenteGeografico: true,
                    torre: true,
                    andar: true,
                    subdistrito: true,
                    sncrCcir: true,
                    car: true,
                    denominacaoRural: true,
                    cib: true,
                    area: true,
                    situacaoConstrucao: true,
                    localizacao: true,
                    descricaoRegistroAuxiliar: [{
                        numeroAto: true,
                        dataRegistro: true,
                        tipoAto: true,
                        tipoRegistro: true,
                        dataInstrumento: true,
                        objetoGarantia: true,
                        dataVencimento: true,
                        tipoMoeda: true,
                        valor: true,
                        cidade: true,
                        uf: true,
                        subdistrito: true,
                        denominacaoEmpreendimento: true,
                        valorAtribuido: true,
                        dataCasamento: true,
                        tabelionato: true,
                        livro: true,
                        folha: true,
                        regimeBens: true,
                        pessoal: [],
                        averbacoes: [],
                        enderecos: [
                            {
                                cep: true,
                                tipoLogradouro: true,
                                nomeLogradouro: true,
                                bairro: true,
                                numero: true,
                                complemento: true,
                                complementoQuadra: true,
                                loteQuadras: [
                                    {
                                        quadra: true,
                                        lote: true
                                    }
                                ]
                            }
                        ]
                    }],
                    matriculaNova: true,
                    vigenciaRegistro: true,
                    pessoal: [
                        {
                            documento: true,
                            nome: true,
                            nacionalidade: true,
                            estadoCivil: true,
                            uniaoEstavel: true,
                            regimeBens: true,
                            classificacao: true,
                            tipoDocumento: true,
                            tipoDocumentoConjuge: true,
                            documentoConjuge: true,
                            nomeConjuge: true,
                            inconsistencia: true,
                            conjuge: true,
                            nomes: []
                        }
                    ],
                    ato: [
                        {
                            tipo: true,
                            descricao: true,
                            descricaoAdicional: true,
                            dataInstrumento: true,
                            tipoMoeda: true,
                            valorDeclarado: true,
                            valorFiscal: true,
                            seloFiscalizacao: true,
                            onus: true,
                            tipoTransacao: true,
                            cancelamento: true,
                            dataRegistro: true,
                            numeroAto: true,
                            pessoal: []
                        }
                    ],
                    registroAnterior: [
                        {
                            ato: true,
                            numeroRegistroAnterior: true,
                            tipoRegistroAnterior: true,
                            cnsRegistroAnterior: true,
                            dataRegistroAnterior: true,
                            orgaoEmissor: true,
                            beneficiario: [
                                {
                                    documento: true,
                                    nome: true
                                }
                            ]
                        }
                    ],
                    indisponibilidade: [
                        {
                            dataIndisponibilidade: true,
                            dataRevogacao: true,
                            documento: true,
                            nome: true,
                            motivo: true,
                            ato: true
                        }
                    ]
                },
                camposObrigatorios: {
                    numeroRegistro: 'Indicador Real',
                    tipoRegistro: 'Indicador Real',
                    cidade: 'Imóvel',
                    uf: 'Imóvel',
                    situacaoConstrucao: 'Imóvel',
                    localizacao: 'Imóvel',
                    ato: [
                        {
                            tipo: 'Atos',
                            dataInstrumento: 'Atos',
                            dataRegistro: 'Atos',
                            numeroAto: 'Atos',
                            pessoal: []
                        }
                    ],
                    descricaoRegistroAuxiliar: {
                        pessoal: []
                    },
                    registroAnterior: [
                        {
                            numeroRegistroAnterior: 'Registro Anterior',
                            tipoRegistroAnterior: 'Registro Anterior',
                            cnsRegistroAnterior: 'Registro Anterior',
                            // beneficiario: [
                            //     {
                            //         documento: 'Registro Anterior',
                            //         nome: 'Registro Anterior'
                            //     }
                            // ]
                        }
                    ]
                },
                camposObrigatoriosInicial: null,
                form: {
                    cns: '',
                    cnm: '',
                    numeroRegistro: '',
                    empreendimentoLoteamento: '',
                    letraRegistro: '',
                    numeroCedula: '',
                    onusAtivo: '',
                    l2Vinculado: '',
                    numeroMatricula: '',
                    numerosMatriculas: [''],
                    dataAbertura: '',
                    livroVinculado: '0',
                    anotacoes: '0',
                    descricaoLivroVinculado: '',
                    descricaoAnotacoes: '',
                    tipoRegistro: '',
                    numeroOficio: '',
                    dataOficio: '',
                    processo: {
                        numeroProcesso: '',
                        dataEncerramento: '',
                        numeroInscricao: '',
                        livro: ''
                    },
                    beneficiariosLivroVinculado: [
                        {
                            naturezaTitulo: '',
                            numero: '',
                            documento: '',
                            nome: ''
                        }
                    ],
                    tipoLivro3: '',
                    inscricao: '',
                    registroAuxiliar: '',
                    livroTranscricao: '',
                    folhaTranscricao: '',
                    cep: '',
                    enderecos: [],
                    tipoImovel: '',
                    unidade: [],
                    bloco: [],
                    contribuinte: '',
                    fracaoAreaComum: '',
                    fracaoTempo: '',
                    vagas: '',
                    nomeCondominio: '',
                    complemento: '',
                    setor: [],
                    loteamento: '',
                    cidade: 'São Paulo',
                    uf: '35',
                    inscricaoMunicipal: '',
                    nirf: '',
                    sigef: '',
                    conjunto: '',
                    condominioConjunto: '',
                    acidenteGeografico: '',
                    numeroIncra: '',
                    torre: [],
                    andar: [],
                    andarVaga: [],
                    numeroVagas: [],
                    tipoVaga: '',
                    naturezaJuridica: '',
                    subdistrito: '',
                    sncrCcir: '',
                    car: '',
                    denominacaoRural: '',
                    cib: '',
                    area: '',
                    situacaoConstrucao: '',
                    localizacao: '0',
                    descricaoRegistroAuxiliar: {
                        numeroAto: '',
                        dataRegistro: '',
                        tipoAto: '',
                        tipoRegistro: '',
                        dataInstrumento: '',
                        tipoInstrumento: '',
                        titulo: '',
                        numeroProcesso: '',
                        bensPenhorados: [
                            ''
                        ],
                        objetoGarantia: [
                            ''
                        ],
                        dataVencimento: '',
                        tipoMoeda: '',
                        valor: '',
                        denominacaoEmpreendimento: '',
                        valorAtribuido: '',
                        dataCasamento: '',
                        tabelionato: '',
                        livro: '',
                        folha: '',
                        regimeBens: '',
                        contribuintes: [''],
                        cidade: 'São Paulo',
                        uf: '35',
                        subdistrito: '',
                        pessoal: [],
                        averbacoes: [],
                        enderecos: [
                            {
                                tipoImovel: '',
                                setor: [''],
                                bloco: [''],
                                andar: '',
                                loteamentoCondominio: '',
                                nomeCondominio: '',
                                conjuntoCondominio: '',
                                loteamento: '',
                                torreEdificio: [''],
                                conjuntoEmpreeendimento: '',
                                vagas: '',
                                cep: '',
                                tipoLogradouro: '',
                                nomeLogradouro: '',
                                bairro: '',
                                numero: '',
                                complemento: '',
                                complementoQuadra: '',
                                loteQuadras: [
                                    {
                                        quadra: '',
                                        lote: ''
                                    }
                                ]
                            }
                        ]
                    },
                    contribuintes: [],
                    matriculaNova: '',
                    vigenciaRegistro: '',
                    ato: [],
                    registroAnterior: [],
                    indisponibilidade: [],
                    rascunho: true
                },
                contribuinte: '',
                bloco: '',
                setor: '',
                torre: '',
                andar: '',
                andarVaga: '',
                numeroVagas: '',
                tipoVaga: '',
                unidade: '',
                indiceContribuinte: null,
                indicebloco: null,
                indicesetor: null,
                indicetorre: null,
                indiceandar: null,
                indiceandarVaga: null,
                indicenumeroVagas: null,
                indiceunidade: null,
                matriculaJaCadastrada: false,
                modalConfimacao: false,
                modalRascunho: false,
                matriculaCadastrada: false,
                modalCancelamento: false,
                modalCarregarForm: false,
                modalCarregarCopia: false,
                modalRemoverAto: false,
                modalRemoverAverbacao: false,
                modalRemoverAverbacaoParte: false,
                modalRemoverRAParte: false,
                indiceRemoverAto: '',
                modalRemoverParte: false,
                modalRemoverParteRegistroAuxiliar: false,
                modalSelecionarPartes: false,
                modalCopiarAto: false,
                modalCopiarEndereco: false,
                modalTransferirAto: false,
                numeroAto: '',
                numeroMatriculaInicial: '',
                numeroMatriculaFinal: '',
                numeroAtoInicial: '',
                numeroAtoFinal: '',
                qtdeEndereco: '',
                numerico: false,
                alfabetica: false,
                alfanumerico: false,
                loteInicial: '',
                loteFinal: '',
                quadraInicial: '',
                quadraFinal: '',
                dataRegistro: '',
                transferirPartes: true,
                indiceAto: '',
                indiceRemoverParte: '',
                modalAjuda: false,
                buscarMatricula: true,
                modalCamposVazios: false,
                mensagemCamposVazios: '',
                carregando: false,
                edicao: false,
                copia: false,
                formAtivo: 'real',
                onusAtivos: [],
                abasComErros: [],
                mascara: [],
                mascaraConjuge: [],
                resultadosEndereco: [],
                resultadosTabelionato: [],
                mascaraBeneficiario: [''],
                mascaraBeneficiarioLivroVinculado: [],
                mascaraIndisponibilidade: [],
                inconsistencia: [],
                botaoInconsistencia: [],
                partesAdicionadas: [],
                autenticacao: {
                    autenticacao: null
                },
                loading: {
                    numeroRegistro: false,
                    cep: false,
                    enderecos: [
                        {
                            cep: false
                        }
                    ],
                    pessoal: [
                        {
                            nome: false,
                            nomeConjuge: false
                        }
                    ],
                    ato: [],
                    descricaoRegistroAuxiliar: [],
                    registroAuxiliar: [],
                    registroAnterior: {
                        beneficiario: [
                            {
                                documento: false,
                                nome: false
                            }
                        ]
                    },
                    beneficiariosLivroVinculado: [
                        {
                            nome: false
                        }
                    ],
                    indisponibilidade: []
                },
                token: {},
                tiposLogradouro: {
                    '1': '1 - Acampamento',
                    '2': '2 - Acesso',
                    '3': '3 - Açude',
                    '4': '4 - Adro',
                    '5': '5 - Aeroporto',
                    '6': '6 - Afluente',
                    '7': '7 - Aglomerado',
                    '8': '8 - Agrovila',
                    '9': '9 - Alagado',
                    '10': '10 - Alameda',
                    '11': '11 - Aldeia',
                    '12': '12 - Aleia',
                    '13': '13 - Alto',
                    '14': '14 - Anel',
                    '15': '15 - Antiga',
                    '16': '16 - Antigo',
                    '17': '17 - Área',
                    '18': '18 - Areal',
                    '19': '19 - Arraial',
                    '20': '20 - Arroio',
                    '21': '21 - Artéria',
                    '22': '22 - Assentamento',
                    '23': '23 - Atalho',
                    '24': '24 - Aterro',
                    '25': '25 - Autódromo',
                    '26': '26 - Avenida',
                    '27': '27 - Baia',
                    '28': '28 - Bairro',
                    '29': '29 - Baixa',
                    '30': '30 - Baixada',
                    '31': '31 - Baixadão',
                    '32': '32 - Baixão',
                    '33': '33 - Baixo',
                    '34': '34 - Balão',
                    '35': '35 - Balneário',
                    '36': '36 - Barra',
                    '37': '37 - Barragem',
                    '38': '38 - Barranca',
                    '39': '39 - Barranco',
                    '40': '40 - Barreiro',
                    '41': '41 - Barro',
                    '42': '42 - Beco',
                    '43': '43 - Beira',
                    '44': '44 - Beirada',
                    '45': '45 - Belvedere',
                    '46': '46 - Bloco',
                    '47': '47 - Bocaina',
                    '48': '48 - Boqueirão',
                    '49': '49 - Bosque',
                    '50': '50 - Boulevard',
                    '51': '51 - Brejo',
                    '52': '52 - Buraco',
                    '53': '53 - Cabeceira',
                    '54': '54 - Cachoeira',
                    '55': '55 - Cachoeirinha',
                    '56': '56 - Cais',
                    '57': '57 - Calcada',
                    '58': '58 - Calçadão',
                    '59': '59 - Caminho',
                    '60': '60 - Campo',
                    '61': '61 - Canal',
                    '62': '62 - Canteiro',
                    '63': '63 - Capão',
                    '64': '64 - Capoeira',
                    '65': '65 - Cartódromo',
                    '66': '66 - Central',
                    '67': '67 - Centro',
                    '68': '68 - Cerca',
                    '69': '69 - Cerrado',
                    '70': '70 - Cerro',
                    '71': '71 - Chácara',
                    '72': '72 - Chapada',
                    '73': '73 - Chapadão',
                    '74': '74 - Charco',
                    '75': '75 - Cidade',
                    '76': '76 - Circular',
                    '77': '77 - Cohab',
                    '78': '78 - Colina',
                    '79': '79 - Colônia',
                    '80': '80 - Comunidade',
                    '81': '81 - Condomínio',
                    '82': '82 - Conjunto',
                    '83': '83 - Continuação',
                    '84': '84 - Contorno',
                    '85': '85 - Corredor',
                    '86': '86 - Córrego',
                    '87': '87 - Costa',
                    '88': '88 - Coxilha',
                    '89': '89 - Cruzamento',
                    '90': '90 - Descida',
                    '91': '91 - Desvio',
                    '92': '92 - Dique',
                    '93': '93 - Distrito',
                    '94': '94 - Divisa',
                    '95': '95 - Divisão',
                    '96': '96 - Divisor',
                    '97': '97 - Edifício',
                    '98': '98 - Eixo',
                    '99': '99 - Elevado',
                    '100': '100 - Encosta',
                    '101': '101 - Engenho',
                    '102': '102 - Enseada',
                    '103': '103 - Entrada',
                    '104': '104 - Entreposto',
                    '105': '105 - Entroncamento',
                    '106': '106 - Escada',
                    '107': '107 - Escadão',
                    '108': '108 - Escadaria',
                    '109': '109 - Escadinha',
                    '110': '110 - Espigão',
                    '111': '111 - Esplanada',
                    '112': '112 - Esquina',
                    '113': '113 - Estação',
                    '114': '114 - Estacionamento',
                    '115': '115 - Estádio',
                    '116': '116 - Estância',
                    '117': '117 - Estrada',
                    '118': '118 - Extensão',
                    '119': '119 - Faixa',
                    '120': '120 - Favela',
                    '121': '121 - Fazenda',
                    '122': '122 - Feira',
                    '123': '123 - Ferrovia',
                    '124': '124 - Final',
                    '125': '125 - Floresta',
                    '126': '126 - Folha',
                    '127': '127 - Fonte',
                    '128': '128 - Fortaleza',
                    '129': '129 - Freguesia',
                    '130': '130 - Fundos',
                    '131': '131 - Furo',
                    '132': '132 - Galeria',
                    '133': '133 - Gameleira',
                    '134': '134 - Garimpo',
                    '135': '135 - Gleba',
                    '136': '136 - Granja',
                    '137': '137 - Grota',
                    '138': '138 - Habitacional',
                    '139': '139 - Haras',
                    '140': '140 - Hipódromo',
                    '141': '141 - Horto',
                    '142': '142 - Igarapé',
                    '143': '143 - Ilha',
                    '144': '144 - Inaplicável',
                    '145': '145 - Invasão',
                    '146': '146 - Jardim',
                    '147': '147 - Jardinete',
                    '148': '148 - Ladeira',
                    '149': '149 - Lado',
                    '150': '150 - Lago',
                    '151': '151 - Lagoa',
                    '152': '152 - Lagoinha',
                    '153': '153 - Largo',
                    '154': '154 - Lateral',
                    '155': '155 - Leito',
                    '156': '156 - Ligação',
                    '157': '157 - Limeira',
                    '158': '158 - Limite',
                    '159': '159 - Limites',
                    '160': '160 - Linha',
                    '161': '161 - Lote',
                    '162': '162 - Loteamento',
                    '163': '163 - Lugarejo',
                    '164': '164 - Maloca',
                    '165': '165 - Manancial',
                    '166': '166 - Mangue',
                    '167': '167 - Margem',
                    '168': '168 - Margens',
                    '169': '169 - Marginal',
                    '170': '170 - Marina',
                    '171': '171 - Mata',
                    '172': '172 - Mato',
                    '173': '173 - Módulo',
                    '174': '174 - Monte',
                    '175': '175 - Morro',
                    '176': '176 - Muro',
                    '177': '177 - Não Especificado',
                    '178': '178 - Núcleo',
                    '179': '179 - Oca',
                    '180': '180 - Oleoduto',
                    '181': '181 - Olho',
                    '182': '182 - Olhos',
                    '183': '183 - Orla',
                    '184': '184 - Outros',
                    '185': '185 - Paco',
                    '186': '186 - Palafita',
                    '187': '187 - Pântano',
                    '188': '188 - Parada',
                    '189': '189 - Paradouro',
                    '190': '190 - Paralela',
                    '191': '191 - Parque',
                    '192': '192 - Particular',
                    '193': '193 - Passagem',
                    '194': '194 - Passarela',
                    '195': '195 - Passeio',
                    '196': '196 - Passo',
                    '197': '197 - Pasto',
                    '198': '198 - Pátio',
                    '199': '199 - Pavilhão',
                    '200': '200 - Pedra',
                    '201': '201 - Pedras',
                    '202': '202 - Pedreira',
                    '203': '203 - Penhasco',
                    '204': '204 - Perimetral',
                    '205': '205 - Perímetro',
                    '206': '206 - Perto',
                    '207': '207 - Planalto',
                    '208': '208 - Plataforma',
                    '209': '209 - Ponta',
                    '210': '210 - Ponte',
                    '211': '211 - Ponto',
                    '212': '212 - Porto',
                    '213': '213 - Posto',
                    '214': '214 - Povoado',
                    '215': '215 - Praça',
                    '216': '216 - Praia',
                    '217': '217 - Projeção',
                    '218': '218 - Projetada',
                    '219': '219 - Projeto',
                    '220': '220 - Prolongamento',
                    '221': '221 - Propriedade',
                    '222': '222 - Próximo',
                    '223': '223 - Quadra',
                    '224': '224 - Quarteirão',
                    '225': '225 - Quilombo',
                    '226': '226 - Quilometro',
                    '227': '227 - Quinta',
                    '228': '228 - Quintas',
                    '229': '229 - Rachão',
                    '230': '230 - Ramal',
                    '231': '231 - Rampa',
                    '232': '232 - Rancho',
                    '233': '233 - Recanto',
                    '234': '234 - Região',
                    '235': '235 - Represa',
                    '236': '236 - Residencial',
                    '237': '237 - Reta',
                    '238': '238 - Retiro',
                    '239': '239 - Retorno',
                    '240': '240 - Riacho',
                    '241': '241 - Ribanceira',
                    '242': '242 - Ribeirão',
                    '243': '243 - Rincão',
                    '244': '244 - Rio',
                    '245': '245 - Rocha',
                    '246': '246 - Rochedo',
                    '247': '247 - Rodovia',
                    '248': '248 - Rotatória',
                    '249': '249 - Rotula',
                    '250': '250 - Rua',
                    '251': '251 - Ruela',
                    '252': '252 - Saco',
                    '253': '253 - Saída',
                    '254': '254 - Sanga',
                    '255': '255 - Sede',
                    '256': '256 - Sem',
                    '257': '257 - Seringal',
                    '258': '258 - Serra',
                    '259': '259 - Sertão',
                    '260': '260 - Servidão',
                    '261': '261 - Seta',
                    '262': '262 - Setor',
                    '263': '263 - Sitio',
                    '264': '264 - Sopé',
                    '265': '265 - Subida',
                    '266': '266 - Superquadra',
                    '267': '267 - Tapera',
                    '268': '268 - Terminal',
                    '269': '269 - Terra',
                    '270': '270 - Terreno',
                    '271': '271 - Terrenos',
                    '272': '272 - Transversal',
                    '273': '273 - Travessa',
                    '274': '274 - Travessão',
                    '275': '275 - Travessia',
                    '276': '276 - Trecho',
                    '277': '277 - Trevo',
                    '278': '278 - Trilha',
                    '279': '279 - Trilho',
                    '280': '280 - Trilhos',
                    '281': '281 - Trincheira',
                    '282': '282 - Túnel',
                    '283': '283 - Unidade',
                    '284': '284 - Usina',
                    '285': '285 - Vala',
                    '286': '286 - Valão',
                    '287': '287 - Vale',
                    '288': '288 - Vargem',
                    '289': '289 - Variante',
                    '290': '290 - Várzea',
                    '291': '291 - Velódromo',
                    '292': '292 - Vereda',
                    '293': '293 - Vertente',
                    '294': '294 - Via',
                    '295': '295 - Viaduto',
                    '296': '296 - Vicinal',
                    '297': '297 - Viela',
                    '298': '298 - Vila',
                    '299': '299 - Vilarejo',
                    '300': '300 - Volta',
                    '301': '301 - Zona',
                    '302': '302 - 1a Travessa da Avenida',
                    '303': '303 - 1a Travessa da Rua',
                    '304': '304 - 2a Travessa da Avenida',
                    '305': '305 - 2a Travessa da Rua',
                    '306': '306 - 3a Travessa da Avenida',
                    '307': '307 - 3a Travessa da Rua',
                    '308': '308 - 4a Travessa da Avenida',
                    '309': '309 - 4a Travessa da Rua',
                    '310': '310 - 5a Travessa da Avenida',
                    '311': '311 - 5a Travessa da Rua'
                },
                tiposImovel: {
                    '1': '1 - Casa',
                    '2': '2 - Apartamento',
                    '3': '3 - Loja',
                    '4': '4 - Sala/conjunto',
                    '5': '5 - Terreno/fração',
                    '6': '6 - Galpão',
                    '7': '7 - Prédio comercial',
                    '8': '8 - Prédio residencial',
                    '9': '9 - Fazenda/sítio/chácara',
                    '10': '10 - Garagem/Vaga',
                    '11': '11 - Box/Depósito',
                    '12': '12 - Públicos',
                    '13': '13 - Outros'
                },
                tiposRegistro: {
                    '1': '1 - Matrícula',
                    '2': '2 - Matrícula MÃE',
                    '3': '3 - Livro 3-reg-aux',
                    '4': '4 - Transcrição'
                },
                tiposLivro3: {
                    '1': '1 - Cédulas',
                    '2': '2 - Convenção, instituição e especificação de condomínio',
                    '3': '3 - Bem de Família',
                    '4': '4 - Pacto Antenupcial/Pós-Nupcial',
                    '5': '5 - União Estável',
                    '6': '6 - Processo administrativo decorrente da falha de numeração',
                    '7': '7 - Penhor por instrumento público/particular',
                    '8': '8 - Emissão de debêntures',
                    '9': '9 - Alteração do regime de bens',
                    '10': '10 - Tombamento',
                    '11': '11 - Outros'
                },
                tiposMoedas: {
                    'Real': '1 - (R$) Real',
                    'Cruzeiro Real': '2 - (CR$) Cruzeiro Real',
                    'Cruzeiro': '3 - (Cr$) Cruzeiro',
                    'Cruzado Novo': '4 - (NCz$) Cruzado Novo',
                    'Cruzado': '5 - (Cz$) Cruzado',
                    'Réis': '6 - (Rs) Réis',
                    'Cruzeiro Novo': '7 - (NCr$) Cruzeiro Novo'
                },
                tiposNacionalidade: {
                    'Brasileira': '1 - Brasileira',
                    'Afegãne': '2 - Afegãne',
                    'Alemã': '3 - Alemã',
                    'Albanesa': '4 - Albanesa',
                    'Angolana': '5 - Angolana',
                    'Anguillana': '6 - Anguillana',
                    'Antiguana': '7 - Antiguana',
                    'Argelina': '8 - Argelina',
                    'Argentina': '9 - Argentina',
                    'Armênia': '10 - Armênia',
                    'Arubana': '11 - Arubana',
                    'Australiana': '12 - Australiana',
                    'Austríaca': '13 - Austríaca',
                    'Azerbaijano': '14 - Azerbaijano',
                    'Bahamense': '15 - Bahamense',
                    'Barenita': '16 - Barenita',
                    'Bengali': '17 - Bengali',
                    'Barbadiana': '18 - Barbadiana',
                    'Bielo-russa': '19 - Bielo-russa',
                    'Belga': '20 - Belga',
                    'Belizenha': '21 - Belizenha',
                    'Beninense': '22 - Beninense',
                    'Bermudiana': '23 - Bermudiana',
                    'Boliviana': '24 - Boliviana',
                    'Bósnia': '25 - Bósnia',
                    'Britânica': '26 - Britânica',
                    'Bruneiana': '27 - Bruneiana',
                    'Búlgara': '28 - Búlgara',
                    'Burquinês': '29 - Burquinês',
                    'Burundinesa': '30 - Burundinesa',
                    'Butanesa': '31 - Butanesa',
                    'Cabo-verdiana': '32 - Cabo-verdiana',
                    'Camaronesa': '33 - Camaronesa',
                    'Cambojana': '34 - Cambojana',
                    'Canadense': '35 - Canadense',
                    'Canário': '36 - Canário',
                    'Cazaque': '37 - Cazaque',
                    'Chilena': '38 - Chilena',
                    'Chinesa': '39 - Chinesa',
                    'Cipriota': '40 - Cipriota',
                    'Cingapuriana': '41 - Cingapuriana',
                    'Colombiana': '42 - Colombiana',
                    'Comorense': '43 - Comorense',
                    'Congolesa': '44 - Congolesa',
                    'Costarriquenha': '45 - Costarriquenha',
                    'Coreana': '46 - Coreana',
                    'Croata': '47 - Croata',
                    'Cubana': '48 - Cubana',
                    'Curaçauense': '49 - Curaçauense',
                    'Chagositano': '50 - Chagositano',
                    'Dinamarquesa': '51 - Dinamarquesa',
                    'Djibutiana': '52 - Djibutiana',
                    'Dominiquense': '53 - Dominiquense',
                    'Egípcia': '54 - Egípcia',
                    'Salvadorenha': '55 - Salvadorenha',
                    'Árabe': '56 - Árabe',
                    'Equatoriana': '57 - Equatoriana',
                    'Eritreia': '58 - Eritreia',
                    'Eslovaco': '59 - Eslovaco',
                    'Esloveno': '60 - Esloveno',
                    'Espanhola': '61 - Espanhola',
                    'Norte-americana': '62 - Norte-americana',
                    'Estoniana': '63 - Estoniana',
                    'Etíope': '64 - Etíope',
                    'Fijiana': '65 - Fijiana',
                    'Filipina': '66 - Filipina',
                    'Finlandesa': '67 - Finlandesa',
                    'Francesa': '68 - Francesa',
                    'Gabonesa': '69 - Gabonesa',
                    'Gambiana': '70 - Gambiana',
                    'Ganense': '71 - Ganense',
                    'Georgiana': '72 - Georgiana',
                    'Gibraltariana': '73 - Gibraltariana',
                    'Inglesa': '74 - Inglesa',
                    'Granadina': '75 - Granadina',
                    'Grega': '76 - Grega',
                    'Groenlandesa': '77 - Groenlandesa',
                    'Guadalupense': '78 - Guadalupense',
                    'Guamês': '79 - Guamês',
                    'Guatemalteca': '80 - Guatemalteca',
                    'Guernesiano': '81 - Guernesiano',
                    'Guianense': '82 - Guianense',
                    'Franco-guianense': '83 - Franco-guianense',
                    'Guinéu-equatoriano ou equatoguineana': '84 - Guinéu-equatoriano ou equatoguineana',
                    'Guinéu-equatoriano': '85 - Guinéu-equatoriano',
                    'Guineense': '86 - Guineense',
                    'Haitiana': '87 - Haitiana',
                    'Holandês': '88 - Holandês',
                    'Hondurenha': '89 - Hondurenha',
                    'Hong-konguiana ou chinesa': '90 - Hong-konguiana ou chinesa',
                    'Húngara': '91 - Húngara',
                    'Iemenita': '92 - Iemenita',
                    'Da ilha Bouvet': '93 - Da ilha Bouvet',
                    'Da ilha de Ascensão': '94 - Da ilha de Ascensão',
                    'Da ilha de Clipperton': '95 - Da ilha de Clipperton',
                    'Manês': '96 - Manês',
                    'Natalense': '97 - Natalense',
                    'Pitcairnense': '98 - Pitcairnense',
                    'Reunionense': '99 - Reunionense',
                    'Alandês': '100 - Alandês',
                    'Caimanês': '101 - Caimanês',
                    'Coquense': '102 - Coquense',
                    'Cookense': '103 - Cookense',
                    'Faroense': '104 - Faroense',
                    'Das ilhas Geórgia do Sul e Sandwich do Sul': '105 - Das ilhas Geórgia do Sul e Sandwich do Sul',
                    'Das ilhas Heard e McDonald': '106 - Das ilhas Heard e McDonald',
                    'Malvinense': '107 - Malvinense',
                    'Norte-marianense': '108 - Norte-marianense',
                    'Marshallino': '109 - Marshallino',
                    'Das ilhas Menores Afastadas': '110 - Das ilhas Menores Afastadas',
                    'Norfolquino': '111 - Norfolquino',
                    'Salomônico': '112 - Salomônico',
                    'Seichelense': '113 - Seichelense',
                    'Toquelauano': '114 - Toquelauano',
                    'Turquês': '115 - Turquês',
                    'Virginense': '116 - Virginense',
                    'Indiano': '117 - Indiano',
                    'Indonésia': '118 - Indonésia',
                    'Iraniano': '119 - Iraniano',
                    'Iraquiana': '120 - Iraquiana',
                    'Irlandesa': '121 - Irlandesa',
                    'Islandesa': '122 - Islandesa',
                    'Israelense': '123 - Israelense',
                    'Italiana': '124 - Italiana',
                    'Jamaicana': '125 - Jamaicana',
                    'Japonesa': '126 - Japonesa',
                    'Canalina': '127 - Canalina',
                    'Jordaniana': '128 - Jordaniana',
                    'Kiribatiana': '129 - Kiribatiana',
                    'Kosovar': '130 - Kosovar',
                    'Kuwaitiano': '131 - Kuwaitiano',
                    'Laosiana': '132 - Laosiana',
                    'Lesota': '133 - Lesota',
                    'Letão': '134 - Letão',
                    'Libanesa': '135 - Libanesa',
                    'Liberiana': '136 - Liberiana',
                    'Líbia': '137 - Líbia',
                    'Liechtensteiniense': '138 - Liechtensteiniense',
                    'Lituana': '139 - Lituana',
                    'Luxemburguesa': '140 - Luxemburguesa',
                    'Macauense': '141 - Macauense',
                    'Macedônio': '142 - Macedônio',
                    'Malgaxe': '143 - Malgaxe',
                    'Malaia': '144 - Malaia',
                    'Malauiano': '145 - Malauiano',
                    'Maldívia': '146 - Maldívia',
                    'Malinesa': '147 - Malinesa',
                    'Maltesa': '148 - Maltesa',
                    'Marroquina': '149 - Marroquina',
                    'Martiniquense': '150 - Martiniquense',
                    'Mauriciana': '151 - Mauriciana',
                    'Mauritana': '152 - Mauritana',
                    'Maiotense': '153 - Maiotense',
                    'Mexicana': '154 - Mexicana',
                    'Micronésia': '155 - Micronésia',
                    'Moçambicana': '156 - Moçambicana',
                    'Moldavo': '157 - Moldavo',
                    'Monegasca': '158 - Monegasca',
                    'Mongol': '159 - Mongol',
                    'Montenegra': '160 - Montenegra',
                    'Montserratiano': '161 - Montserratiano',
                    'Birmanês': '162 - Birmanês',
                    'Namíbia': '163 - Namíbia',
                    'Nauruana': '164 - Nauruana',
                    'Nepalesa': '165 - Nepalesa',
                    'Nicaraguense': '166 - Nicaraguense',
                    'Nigerina': '167 - Nigerina',
                    'Nigeriana': '168 - Nigeriana',
                    'Niueana': '169 - Niueana',
                    'Norueguesa': '170 - Norueguesa',
                    'Caledônia': '171 - Caledônia',
                    'Neozelandesa': '172 - Neozelandesa',
                    'Omani': '173 - Omani',
                    'Dos países Baixos Caribenhos': '174 - Dos países Baixos Caribenhos',
                    'Palauense': '175 - Palauense',
                    'Palestino': '176 - Palestino',
                    'Zona do Canal do Panamá': '177 - Zona do Canal do Panamá',
                    'Pauásia': '178 - Pauásia',
                    'Paquistanesa': '179 - Paquistanesa',
                    'Paraguaia': '180 - Paraguaia',
                    'Peruana': '181 - Peruana',
                    'Franco-polinésia': '182 - Franco-polinésia',
                    'Polonesa': '183 - Polonesa',
                    'Portorriquenha': '184 - Portorriquenha',
                    'Portuguesa': '185 - Portuguesa',
                    'Catarense': '186 - Catarense',
                    'Queniano': '187 - Queniano',
                    'Quirguiz': '188 - Quirguiz',
                    'Centro-africana': '189 - Centro-africana',
                    'Dominicana': '190 - Dominicana',
                    'Tcheco': '191 - Tcheco',
                    'Romena': '192 - Romena',
                    'Ruandesa': '193 - Ruandesa',
                    'Russa': '194 - Russa',
                    'Saariano': '195 - Saariano',
                    'Pedro-miquelonense': '196 - Pedro-miquelonense',
                    'Samoana': '197 - Samoana',
                    'Samoano': '198 - Samoano',
                    'Samarinês': '199 - Samarinês',
                    'Helenense': '200 - Helenense',
                    'Santa-lucense': '201 - Santa-lucense',
                    'São-bartolomeense': '202 - São-bartolomeense',
                    'São-cristovense': '203 - São-cristovense',
                    'São-martinhense': '204 - São-martinhense',
                    'São-tomense': '205 - São-tomense',
                    'Sao-vicentino': '206 - Sao-vicentino',
                    'Senegalesa': '207 - Senegalesa',
                    'Leonesa': '208 - Leonesa',
                    'Sérvia': '209 - Sérvia',
                    'Síria': '210 - Síria',
                    'Somali': '211 - Somali',
                    'Cingalesa': '212 - Cingalesa',
                    'Suazi': '213 - Suazi',
                    'Sudanesa': '214 - Sudanesa',
                    'Sul-sudanês': '215 - Sul-sudanês',
                    'Sueca': '216 - Sueca',
                    'Suíça': '217 - Suíça',
                    'Surinamesa': '218 - Surinamesa',
                    'Svalbardense': '219 - Svalbardense',
                    'Tadjique': '220 - Tadjique',
                    'Tailandesa': '221 - Tailandesa',
                    'Taiwanês': '222 - Taiwanês',
                    'Tanzaniana': '223 - Tanzaniana',
                    'Do Território Britânico do Oceano Índico': '224 - Do Território Britânico do Oceano Índico',
                    'Do Territórios do Sul da França': '225 - Do Territórios do Sul da França',
                    'Timorense': '226 - Timorense',
                    'Togolesa': '227 - Togolesa',
                    'Tonganesa': '228 - Tonganesa',
                    'Trinitário-tobagense': '229 - Trinitário-tobagense',
                    'Tristanita': '230 - Tristanita',
                    'Tunisiana': '231 - Tunisiana',
                    'Turcomana': '232 - Turcomana',
                    'Turca': '233 - Turca',
                    'Tuvaluana': '234 - Tuvaluana',
                    'Ucraniana': '235 - Ucraniana',
                    'Ugandense': '236 - Ugandense',
                    'Uruguaia': '237 - Uruguaia',
                    'Uzbeque': '238 - Uzbeque',
                    'Vanuatuense': '239 - Vanuatuense',
                    'Vaticano': '240 - Vaticano',
                    'Venezuelana': '241 - Venezuelana',
                    'Vietnamita': '242 - Vietnamita',
                    'Wallis-futunense': '243 - Wallis-futunense',
                    'Zambiana': '244 - Zambiana',
                    'Zimbabuana': '245 - Zimbabuana'
                },
                tiposCns: {
                    '111179': '111179 - 11\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111211': '111211 - 14\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111450': '111450 - 1\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '113282': '113282 - 3\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '113746': '113746 - 8\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '142935': '142935 - 6\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                },
                tiposClassificacao: {
                    'Proprietário': '0 - Proprietário',
                    'Adquirente': '1 - Adquirente',
                    'Transmitente': '2 - Transmitente',
                    'Devedor': '3 - Devedor',
                    'Credor': '4 - Credor',
                    'Anuente': '5 - Anuente',
                    'Interveniente': '6 - Interveniente',
                    'Fiador': '7 - Fiador',
                    'Avalista': '8 - Avalista',
                    'Requerente': '9 - Requerente',
                    'Autor': '10 - Autor',
                    'Réu': '11 - Réu',
                    'Exequente': '12 - Exequente',
                    'Executado': '13 - Executado',
                    'Instituição de condomínio': '14 - Instituição de condomínio',
                    'Convenção de Condomínio': '15 - Convenção de Condomínio',
                    'Especificação de Condomínio': '16 - Especificação de Condomínio',
                    'Retificação de instituição': '17 - Retificação de instituição',
                    'Retificação de convenção': '18 - Retificação de convenção',
                    'Retificação de Especificação': '19 - Retificação de Especificação',
                    'Cônjuge': '20 - Cônjuge',
                    'Convivente': '21 - Convivente',
                    'Endossatário': '22 - Endossatário',
                    'Endossante': '23 - Endossante',
                    'Outorgante': '24 - Outorgante',
                    'Outorgado': '25 - Outorgado',
                    'Emissor': '26 - Emissor',
                    'Emissora': '27 - Emissora',
                    'Agente fiduciário': '28 - Agente fiduciário',
                    'Interveniente garantidor': '29 - Interveniente garantidor',
                    'Garantidor': '30 - Garantidor',
                    'Cedente': '31 - Cedente',
                    'Cessionário': '32 - Cessionário',
                    'Promitente vendedor': '33 - Promitente vendedor',
                    'Promitente comprador': '34 - Promitente comprador',
                    'Expropriante': '35 - Expropriante',
                    'Expropriado': '36 - Expropriado',
                    'Interessado': '37 - Interessado',
                    'Nu-proprietário': '38 - Nu-proprietário',
                    'Usufrutuário': '39 - Usufrutuário',
                    'Incorporadora': '40 - Incorporadora',
                    'Incorporada': '41 - Incorporada',
                    'Instituição Custodiante': '42 - Instituição Custodiante',
                    'Agente de Garantia': '43 - Agente de Garantia',
                    'Agente de Cobrança': '44 - Agente de Cobrança'
                },
                tiposEstadosCivil: {
                    '1': '1 - Casada',
                    '2': '2 - Casado',
                    '3': '3 - Divorciada',
                    '4': '4 - Divorciado',
                    '5': '5 - Espólio',
                    '6': '6 - Separada',
                    '7': '7 - Separada judicialmente',
                    '8': '8 - Separado',
                    '9': '9 - Separado judicialmente',
                    '10': '10 - Solteira',
                    '11': '11 - Solteira, maior',
                    '12': '12 - Solteira, menor impúbere',
                    '13': '13 - Solteira, menor púbere',
                    '14': '14 - Solteiro',
                    '15': '15 - Solteiro, maior',
                    '16': '16 - Solteiro, menor impúbere',
                    '17': '17 - Solteiro, menor púbere',
                    '18': '18 - Viúva',
                    '19': '19 - Viúvo',
                    '20': '20 - Menor emancipado',
                    '21': '21 - Outros',
                    '22': '22 - Desquitado',
                    '23': '23 - Desquitada'
                },
                tiposEstadosCivilOposto: {
                    '1': '2',
                    '2': '1',
                    '3': '4',
                    '4': '3',
                    '5': '5',
                    '6': '8',
                    '7': '9',
                    '8': '6',
                    '9': '7',
                    '10': '14',
                    '11': '15',
                    '12': '16',
                    '13': '17',
                    '14': '10',
                    '15': '11',
                    '16': '12',
                    '17': '13',
                    '18': '19',
                    '19': '18',
                    '20': '20',
                    '21': '21',
                    '22': '23',
                    '23': '22'
                },
                tiposEstados: {
                    '11': '11 - RO',
                    '12': '12 - AC',
                    '13': '13 - AM',
                    '14': '14 - RR',
                    '15': '15 - PA',
                    '16': '16 - AP',
                    '17': '17 - TO',
                    '21': '21 - MA',
                    '22': '22 - PI',
                    '23': '23 - CE',
                    '24': '24 - RN',
                    '25': '25 - PB',
                    '26': '26 - PE',
                    '27': '27 - AL',
                    '28': '28 - SE',
                    '29': '29 - BA',
                    '31': '31 - MG',
                    '32': '32 - ES',
                    '33': '33 - RJ',
                    '35': '35 - SP',
                    '41': '41 - PR',
                    '42': '42 - SC',
                    '43': '43 - RS',
                    '50': '50 - MS',
                    '51': '51 - MT',
                    '52': '52 - GO',
                    '53': '53 - DF'
                },
                tiposRegimeBens: {
                    '1': '1 - Comunhão de bens',
                    '2': '2 - Comunhão de bens vigente na Venezuela',
                    '3': '3 - Comunhão parcial de bens',
                    '4': '4 - Comunhão parcial de bens às Leis da Noruega',
                    '5': '5 - Comunhão parcial de bens, à Lei Suiça',
                    '6': '6 - Comunhão parcial de bens, antes da vigência da Lei 6.515/77',
                    '7': '7 - Comunhão parcial de bens, na vigência da Lei 6.515/77',
                    '8': '8 - Comunhão universal de bens',
                    '9': '9 - Comunhão universal de bens, antes da vigência da Lei 6.515/77',
                    '10': '10 - Comunhão universal de bens, às Leis de Ângola',
                    '11': '11 - Comunhão universal de bens, às Leis italianas',
                    '12': '12 - Comunhão universal de bens, na vigência da Lei 6.515/77',
                    '13': '13 - Conforme a lei vigente em Israel',
                    '14': '14 - Leis da Alemanha',
                    '15': '15 - Leis da Argentina',
                    '16': '16 - Leis da Austrália',
                    '17': '17 - Leis da Bolívia',
                    '18': '18 - Leis da China',
                    '19': '19 - Leis da Colômbia',
                    '20': '20 - Leis da Costa do Marfim',
                    '21': '21 - Leis da Costa Rica',
                    '22': '22 - Leis da Dinamarca',
                    '23': '23 - Leis da Espanha',
                    '24': '24 - Leis da Finlândia',
                    '25': '25 - Leis da França',
                    '26': '26 - Leis da Guatemala',
                    '27': '27 - Leis da Holanda',
                    '28': '28 - Leis da Inglaterra',
                    '29': '29 - Leis da Itália',
                    '30': '30 - Leis da Islândia',
                    '31': '31 - Leis da Jordânia',
                    '32': '32 - Leis da Polonia',
                    '33': '33 - Leis da República da Coréia',
                    '34': '34 - Leis da Suíça',
                    '35': '35 - Leis de Angola',
                    '36': '36 - Leis de Cuba',
                    '37': '37 - Leis de Moscou',
                    '38': '38 - Leis de Taiwan',
                    '39': '39 - Leis do Canadá',
                    '40': '40 - Leis do Japão',
                    '41': '41 - Leis do Líbano',
                    '42': '42 - Leis do Paraguai',
                    '43': '43 - Leis do Uruguai',
                    '44': '44 - Leis dos Estados Unidos',
                    '45': '45 - Leis Egípcias',
                    '46': '46 - Leis Portuguesas',
                    '47': '47 - Participação final nos aquestos',
                    '48': '48 - Regime de bens conforme as Leis americanas',
                    '49': '49 - Regime vigente no Chile',
                    '50': '50 - Separação de bens',
                    '51': '51 - Separação de bens conforme as Leis da Áustria',
                    '52': '52 - Separação de bens, antes da vigência da Lei 6.515/77',
                    '53': '53 - Separação de bens, na vigência da Lei 6.515/77',
                    '54': '54 - Separação obrigatória de bens',
                    '55': '55 - Separação parcial, antes da vigência da Lei 6.515/77',
                    '56': '56 - Separação parcial, na vigência da Lei 6.515/77',
                    '57': '57 - Separação total de bens, na vigência da Lei 6.515/77'
                },
                tiposAto: {
                    '15': '15 - Adjudicação',
                    '41': '41 - Arrematação em Hasta Pública',
                    '37': '37 - Cessão de Direitos',
                    '11': '11 - Compra e Venda',
                    '19': '19 - Dação em Pagamento',
                    '25': '25 - Desapropriação',
                    '43': '43 - Dissolução de Sociedade',
                    '21': '21 - Distrato de Negócio',
                    '53': '53 - Doação',
                    '55': '55 - Doação em adiantamento da legítima',
                    '27': '27 - Herança, Legado ou Meação (adjudicação)',
                    '45': '45 - Incorporação e loteamento',
                    '47': '47 - Integralização/Subscrição de capital',
                    '49': '49 - Partilha amigável ou litigiosa',
                    '13': '13 - Permuta',
                    '31': '31 - Procuração em Causa Própria',
                    '35': '35 - Promessa de Cessão de Direitos',
                    '33': '33 - Promessa de Compra e Venda',
                    '51': '51 - Retorno de Capital próprio',
                    '99': '99 - Outros',
                    '101': '101 - Usucapião',
                    '102': '102 - Alienação Fiduciária',
                    '103': '103 - Consolidação da Propriedade',
                    '104': '104 - Hipoteca',
                    '201': '201 - Encerramento da matrícula pelo cartório',
                    '202': '202 - Cancelamento da matrícula administrativamente',
                    '203': '203 - Cancelamento da matrícula judicialmente',
                    '204': '204 - Requalificação da matrícula (Prov. 13 CGJ/PA)',
                    '205': '205 - Abertura da matrícula',
                    '206': '206 - Averbação',
                    '207': '207 - Cancelamento de ônus',
                    '208': '208 - Compromisso de Compra e Venda',
                    '209': '209 - Sub-rogação',
                    '210': '210 - Transporte de ônus',
                    '211': '211 - Formal de Partilha',
                    '212': '212 - Construção, Reforma ou Ampliação',
                    '213': '213 - Demolição',
                    '214': '214 - Desmembramento',
                    '215': '215 - Atualização de Dados do Imóvel',
                    '216': '216 - Retificação de Dados do Imóvel',
                    '217': '217 - Ex-Officio',
                    '218': '218 - Casamento',
                    '219': '219 - Divórcio',
                    '220': '220 - Separação',
                    '221': '221 - União Estável ',
                    '222': '222 - Aditivo',
                    '223': '223 - Ratificação',
                    '224': '224 - Retificação ',
                    '225': '225 - Óbito',
                    '226': '226 - Confrontações',
                    '227': '227 - Cláusula',
                    '228': '228 - Arresto',
                    '229': '229 - Assunção de Dívida',
                    '230': '230 - Constituição de Sociedade',
                    '231': '231 - Transferência de bens',
                    '232': '232 - Cisão',
                    '233': '233 - Penhora',
                    '234': '234 - Usufruto',
                    '235': '235 - Endosso ',
                    '236': '236 - Locação',
                    '237': '237 - Caução',
                    '238': '238 - Indisponibilidade de bens',
                    '239': '239 - Cédula de Crédito',
                    '240': '240 - Instituição de Condomínio',
                    '241': '241 - Instituição de Bem de Família',
                    '242': '242 - Cessão de Crédito',
                    '243': '243 - Bloqueio de Matrícula ',
                    '244': '244 - Ação de Execução',
                    '245': '245 - Pacto Comissório ',
                    '246': '246 - Desdobro',
                    '247': '247 - Arrolamento de bens',
                    '248': '248 - Divisão ',
                    '249': '249 - Incorporação de Condomínio',
                    '250': '250 - Sequestro',
                    '251': '251 - Servidão',
                    '252': '252 - Penhor',
                    '253': '253 - Alvará de Execução',
                    '254': '254 - Patrimônio de Afetação ',
                    '255': '255 - Unificação',
                    '256': '256 - Domínio',
                    '257': '257 - Nota Promissória ',
                    '258': '258 - Transporte de atos',
                    '259': '259 - Incomunicabilidade (Consórcio - p.5º do art. 5º da lei 11.795/2008)',
                    '260': '260 - Suspensão do procedimento de execução',
                    '261': '261 - Transformação de Natureza Jurídica',
                    '262': '262 - Endosso para efeito de caução',
                    '263': '263 - Direito de Uso',
                    '264': '264 - Auto de Arrecadação',
                    '265': '265 - Cláusula de Reversão',
                    '266': '266 - Incorporação de Sociedade',
                    '267': '267 - Conferência de bens',
                    '268': '268 - Existência de Ação',
                    '269': '269 - Compra e venda do Usufruto',
                    '270': '270 - Termo de Restauração',
                    '271': '271 - Alteração do Estado Civil',
                    '272': '272 - Retificação do Estado Civil ',
                    '273': '273 - Naturalização',
                    '274': '274 - Emancipação',
                    '275': '275 - Inclusão de Documentação',
                    '276': '276 - Retificação de Documentação',
                    '277': '277 - Alteração de Razão Social',
                    '278': '278 - Atribuição',
                    '279': '279 - Cancelamento parcial de ônus',
                    '280': '280 - Compra e venda da nua-propriedade',
                    '281': '281 - Emissão',
                    '282': '282 - Fusão',
                },
                tiposNatureza: {
                    '1': '1 - Convenção de Condomínio',
                    '2': '2 - Instituição de Condomínio',
                    '3': '3 - Especificação de Condomínio',
                    '4': '4 - Bem de Família',
                    '5': '5 - Pacto Antenupcial',
                    '6': '6 - União Estável',
                    '7': '7 - Cédula de Crédito bancário',
                    '8': '8 - Cédula de Crédito Comercial',
                    '9': '9 - Cédula de Crédito Industrial',
                    '10': '10 - Cédula Rural Hipotecária',
                    '11': '11 - Cédula Rural Pignoratícia e Hipotecária',
                    '12': '12 - Cédula Rural Pignoratícia',
                    '13': '13 - Outros'
                },
                ceps: [],
                cnjs: [],
                expiracaoInterval: null,
                horaAtual: moment().format('HH:mm'),
                contagemRegressiva: '00:00:00',
                contagemRegressivaClass: 'default',
                teste: 0
            }
        },
        async created(){
            /**
             * Realizar a autenticação
             */
            this.autenticar()

            /**
             * Define o form armazenado
             * 
             * @var object oForm
             * @var object oFormCopia
             */
            const oForm = await this.getItem('form')
            const oFormCopia = await this.getItem('formCopia')

            this.edicao = this.$route.params.edicao == 'editar' || this.$route.params.edicao == 'copiar'
            this.copia = this.$route.params.edicao == 'copiar'
            this.form.numeroRegistro = this.edicao ? sessionStorage.getItem('numeroRegistro') : ''
            this.form.tipoRegistro = this.edicao ? sessionStorage.getItem('tipoRegistro') : ''


            if(!this.edicao && !this.copia && oForm){
                this.modalCarregarForm = true
            }

            if(!oForm && oFormCopia && !this.edicao && !this.copia){
                this.modalCarregarCopia = true
            }

            if(this.edicao){
                await this.removeItem('formCopia')
            }

            this.verificarRegistro(false)
            
            /**
             * Grava os dados do CNJ
             */
            this.salvarCnjIndexedDB()

            /**
             * Grava os dados do CEP
             */
            this.consultarCepPorEndereco()
        },
        mounted() {
            /**
             * Realizar a autenticação
             */
            this.autenticar()

            /**
             * Realiza a contagem da expiração
             */
            this.calcularExpiracao()
            this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)
            
            /**
             * Evento para capturar os comandos
             */
            document.addEventListener('keydown', async (event) => {
                /**
                 * Realizar a autenticação
                 */
                this.autenticar()

                if (event.altKey && (event.key === 'A' || event.key === 'a') && this.formAtivo == 'registroAuxiliar') {
                    this.adicionarAverbacao()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.altKey && (event.key === 'Q' || event.key === 'q') && this.formAtivo == 'registroAuxiliar') {
                    this.adicionarParteAverbacao()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.altKey && (event.key === 'Q' || event.key === 'q') && this.formAtivo == 'atos') {
                    this.adicionarParteAto()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.ctrlKey && (event.key === 'B' || event.key === 'b') && this.formAtivo == 'real') {
                    this.adicionarBeneficiarioLivroVinculado()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.ctrlKey && (event.key === 'Q' || event.key === 'q')  && this.formAtivo == 'atos') {
                    this.adicionarAto()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.ctrlKey && (event.key === 'Q' || event.key === 'q')  && this.formAtivo == 'imovel') {
                    this.adicionarEnderecoL2()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.ctrlKey && (event.key === 'Q' || event.key === 'q') && this.formAtivo == 'registroAnterior') {
                    this.adicionarRegistroAnterior()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.ctrlKey && (event.key === 'Q' || event.key === 'q') && this.formAtivo == 'indisponibilidade') {
                    this.adicionarIndisponibilidade()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.ctrlKey && event.key === 'Enter') {
                    this.enviar()
                }
                if (event.altKey && event.key === '1') {
                    this.formAtivo = 'real'
                }
                if (event.altKey && event.key === '2' && this.form.tipoRegistro != '3') {
                    this.formAtivo = 'imovel'
                }
                if (event.altKey && event.key === '2' && this.form.tipoRegistro == '3') {
                    this.formAtivo = 'registroAuxiliar'
                }
                if (event.altKey && event.key === '4') {
                    this.formAtivo = 'atos'
                }
                if (event.altKey && event.key === '3') {
                    this.formAtivo = 'registroAnterior'
                }
                if (event.altKey && event.key === '5') {
                    this.formAtivo = 'indisponibilidade'
                }
                if (event.altKey && (event.key === 'n' || event.key === 'N')) {
                    this.adicionarEndereco(this.form.tipoRegistro == '3' ? 'descricaoRegistroAuxiliar' : '')
                }
                if (event.altKey && (event.key === 'c' || event.key === 'C')) {
                    await this.adicionarContribuinte()
                    document.querySelector('.dropdownContribuintes').style.display = 'block'
                }
                if (event.altKey && (event.key === 'g' || event.key === 'G')) {
                    this.adicionarObjetoGarantia()
                }
                if (event.altKey && (event.key === 'b' || event.key === 'B')) {
                    this.adicionarBemPenhorado()
                }
                if (event.altKey && (event.key === 'm' || event.key === 'M')) {
                    this.adicionarMatriculas()
                }
                if (event.altKey && (event.key === 'l' || event.key === 'L') && this.form.tipoRegistro == '3') {
                    this.adicionarLoteQuadra()
                }
                if (event.altKey && (event.key === 'l' || event.key === 'L') && this.form.tipoRegistro != '3') {
                    this.adicionarLoteQuadraL2()
                }
                if (event.altKey && (event.key === 'p' || event.key === 'P')) {
                    this.adicionarParteRA()
                    document.querySelector('button.btn-focus').focus()
                }
                if (event.key === 'Enter' && this.contribuinte != '') {
                    await this.adicionarContribuinte()
                    document.querySelector('.dropdownContribuintes').style.display = 'block'
                }
                if (event.key === 'Tab') {
                    if(document.querySelector('.dropdownContribuintes') && document.querySelector('.dropdownContribuintes').style.display != 'none'){
                        document.querySelector('.dropdownContribuintes').style.display = 'none'
                    }
                }
                if (event.key === 'Escape') {
                    this.modalCarregarCopia = false
                    this.modalCarregarForm = false
                }
            })

            /**
             * Evento para capturar o clique
             */
            document.addEventListener('click', (event) => {
                const divsElement = document.querySelectorAll('.dropdownEndereco')
                
                for (let i = 0; i < divsElement.length; i++) {
                    if (divsElement[i].contains(event.target)) {
                        divsElement[i].style.display = 'block'
                    } else {
                        divsElement[i].style.display = 'none'
                    }
                }

                const divsElementRegistroAuxiliar = document.querySelectorAll('.dropdownEnderecoRegistroAuxiliar')
                
                for (let i = 0; i < divsElementRegistroAuxiliar.length; i++) {
                    if (divsElementRegistroAuxiliar[i].contains(event.target)) {
                        divsElementRegistroAuxiliar[i].style.display = 'block'
                    } else {
                        divsElementRegistroAuxiliar[i].style.display = 'none'
                    }
                }

                const divsElementTabelionato = document.querySelectorAll('.dropdownTabelionato')
                
                for (let i = 0; i < divsElementTabelionato.length; i++) {
                    if (divsElementTabelionato[i].contains(event.target)) {
                        divsElementTabelionato[i].style.display = 'block'
                    } else {
                        divsElementTabelionato[i].style.display = 'none'
                    }
                }

                if ((event.target.tagName === 'BUTTON' || event.target.closest('button')) && this.edicao) {
                    this.$forceUpdate()
                }
            })
        },
        updated() {
            /**
             * Define o ref do select
             * 
             * @var object oSelectTituloAto
             * @var object that
             */
            const oSelectTituloAto = this.$refs.selectTituloAto
            const that = this

            oSelectTituloAto.$el.querySelector('input').addEventListener('blur', function() {
                if(oSelectTituloAto.$el.querySelector('div > div.selectize-input.items.has-options.full.has-items > div') && oSelectTituloAto.$el.querySelector('div > div.selectize-input.items.has-options.full.has-items > div').getAttribute('data-value') == 201){
                    that.form.vigenciaRegistro = 2
                }
            })

            /**
             * Define o ref da data de abertura
             * 
             * @var object oDataAbertura
             */
            const oDataAbertura = this.$refs.dataAbertura

            oDataAbertura.$el.querySelector('input').addEventListener('blur', function() {
                if(this.value != ''){
                    that.ato.dataRegistro = this.value
                }
            })

            /**
             * Define o ref do input
             * 
             * @var object oInputContribuintes
             */
            const oInputContribuintes = this.$refs.inputContribuintes

            oInputContribuintes.$el.querySelector('input').addEventListener('blur', function() {
                if(document.querySelector('.dropdownContribuintes') && document.querySelector('.dropdownContribuintes').style.display != 'none'){
                    const divs = document.querySelectorAll('.indicador')
                    divs.forEach(div => {
                        div.addEventListener('click', (event) => {
                            const clickedDiv = event.target

                            if(!clickedDiv.classList.contains('optionContribuinte') && !clickedDiv.hasAttribute('placeholder') && document.querySelector('.dropdownContribuintes') && document.querySelector('.dropdownContribuintes').style.display != 'none'){
                                document.querySelector('.dropdownContribuintes').style.display = 'none'
                            }
                        })
                    })
                }                    
            })
            
            oInputContribuintes.$el.querySelector('input').addEventListener('focus', function() {
                if(document.querySelector('.dropdownContribuintes') && document.querySelector('.dropdownContribuintes').style.display != 'block'){
                    document.querySelector('.dropdownContribuintes').style.display = 'block'
                }
            })
        },
        computed: {
            /**
             * Função para iterar os numeros
             */
            numeroWatcherRegistroAuxiliar() {
                return this.form.descricaoRegistroAuxiliar.enderecos.map((item) => {
                    if(item.numero != ''){
                        return item.numero
                    }
                })
            },
            /**
             * Função para iterar os documentos dos beneficiários
             */
            documentoIndisponibilidadeWatcher() {
                return this.form.indisponibilidade.map((item) => item);
            }
        },
        watch: {
            /**
             * Função para limpar o campo de numero
             */
            numeroWatcherRegistroAuxiliar: {
                deep: true,
                immediate: false,
                async handler(novoValor, valorAntigo) {
                    novoValor.forEach(async (item, i) => {      
                        if(!item){
                            return
                        }       

                        // Filtra os caracteres especiais e atualiza o valor em textoFiltrado
                        this.form.descricaoRegistroAuxiliar.enderecos[i].numero = item.toString().replace(',', '').replace(';', '')      
                        
                        /**
                         * Define os inputs dos números
                         * 
                         * @var array aInputNumero
                         */
                        const aInputNumero = document.querySelectorAll('.campoNumeroRegistroAuxiliar > div > input')

                        if(aInputNumero[i]){
                            aInputNumero[i].value = this.form.descricaoRegistroAuxiliar.enderecos[i].numero
                        }
                    })
                }
            },  
            /**
             * Função para definir a máscara do documento
             */
            documentoIndisponibilidadeWatcher: {
                deep: true,
                handler(novoValor, valorAntigo) {
                    novoValor.forEach(async (item, i) => {
                        const { documento, nome } = item;

                        if (documento !== valorAntigo[i] && this.form.indisponibilidade[i]) {
                            if (documento && !nome) {
                                // Define a máscara com base no comprimento do documento digitado
                                if(documento.replace(/[^\w\s]/gi, "").length == 11){
                                    this.mascaraIndisponibilidade[i] = '###.###.###-##'
                                }else if(documento.replace(/[^\w\s]/gi, "").length == 14){
                                    this.mascaraIndisponibilidade[i] = '##.###.###/####-##'
                                }else{
                                    this.mascaraIndisponibilidade[i] = '###################'
                                }

                                if(/\./.test(documento) && (documento.replace(/[^\w\s]/gi, "").length == 11 || documento.replace(/[^\w\s]/gi, "").length == 14)){
                                    this.loading.indisponibilidade[i].nome = true

                                    /**
                                     * Consulta o documento
                                     *
                                     * @var {Object} oDadosDocumento
                                     */
                                    const oDadosDocumento = await this.consultarDocumento(documento.replace(/[^\w\s]/gi, ""))
                                    
                                    if(oDadosDocumento != null){
                                        this.form.indisponibilidade[i].nome = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial
                                    }else{
                                        this.form.indisponibilidade[i].nome = ''
                                    }

                                    this.loading.indisponibilidade[i].nome = false
                                }
                            }
                        }
                    })
                },
            },
            form: {
                deep: true,
                immediate: false,
                async handler(novoValor, valorAntigo) {
                    if((novoValor.tipoRegistro != '' || novoValor.numeroRegistro != '') && !this.edicao){
                        await this.setItem('form', novoValor)
                    }
                    
                    if(!/^\d{4}-\d{2}-\d{2}$/.test(this.form.descricaoRegistroAuxiliar.dataRegistro) && parseInt(novoValor.dataAbertura.split('-')[0], 10) > 1000){
                        this.form.descricaoRegistroAuxiliar.dataRegistro = novoValor.dataAbertura
                    }
                }
            }
        },
        methods: {
            forcarRerenderGeral() {
                this.rerenderKeyGeral = this.rerenderKeyGeral + 1    

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInputNumeroRegistro
                     */
                    const oInputNumeroRegistro = this.$refs.numeroRegistro

                    if (oInputNumeroRegistro && this.form.tipoRegistro != 3) {
                        oInputNumeroRegistro.$el.querySelector('input').focus()
                    }else{
                        /**
                         * Define o focus no input
                         *
                         * @var {Object} oSelectTipoLivro3
                         */
                        const oSelectTipoLivro3 = this.$refs.tipoLivro3
                    }
                })
            },
            forcarRerenderTipo3() {
                this.rerenderKey = this.rerenderKey + 1       
                this.form.descricaoRegistroAuxiliar.descricaoAto = ''  
            },
            forcarRerender() {
                if(this.edicao){
                    this.rerenderKey = this.rerenderKey + 1                
                }
            },
            /**
             * Função para realizar a autenticação da aplicação
             */
            async autenticar(){
                if(this.autenticacao.autenticado == false){
                    return false
                }

                /**
                 * Busca os parametros da URL
                 *
                 * @var {object} oParams
                 */
                const oParams = new URLSearchParams(window.location.hash)

                /**
                 * Limpa os dados do URL
                 */
                window.location.hash = ''
                
                /**
                 * Verifica se existe o token de autenticação
                 */
                if(!oParams.get('access_token') && !sessionStorage.getItem('access_token')){
                    toastAlert('Você não está autenticado, redirecionando para a autenticação...')

                    setTimeout(() => {
                        /**
                         * Desloga da aplicação
                         */
                        this.sair()
                    }, 2000)

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                if(oParams.get('access_token')){
                    sessionStorage.setItem('refresh_token', oParams.get('#refresh_token'))
                    sessionStorage.setItem('access_token', oParams.get('access_token'))
                    sessionStorage.setItem('token_type', oParams.get('token_type'))
                }

                /**
                 * Define os dados nas variaveis
                 */
                this.autenticacao.autenticado = true
                this.autenticacao.refresh_token = oParams.get('#refresh_token') ?? sessionStorage.getItem('refresh_token')
                this.autenticacao.access_token = oParams.get('access_token') ?? sessionStorage.getItem('access_token')
                this.autenticacao.token_type = oParams.get('token_type') ?? sessionStorage.getItem('token_type')

                /**
                 * Transforma o token JWT em um array, quebrando pelo caractere ponto (.)
                 *
                 * @var {string} sHeader
                 * @var {string} sPayload
                 * @var {string} sSignature
                 */
                const [sHeader, sPayload, sSignature] = this.autenticacao.access_token
                    .toString()
                    .split('.')

                /**
                 * Transforma o dado em base64 para string legível
                 *
                 * @var {Object} oDadosDescriptografados
                 */
                const oDadosDescriptografados = JSON.parse(
                    (Buffer.from(sPayload, 'base64'))
                    .toString('ascii')
                )

                /**
                 * Verifica se o usuario é admin
                 */
                if(oDadosDescriptografados['cognito:groups'].includes('Administradores')
                    || oDadosDescriptografados['cognito:groups'].includes('administradores')
                ){
                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.admin = true
                }

                /**
                 * Verifica se o usuario é escrevente
                 */
                if(oDadosDescriptografados['cognito:groups'].includes('Escreventes')
                    || oDadosDescriptografados['cognito:groups'].includes('escreventes')
                ){
                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.escreventes = true
                }

                /**
                 * Verifica se o usuario é coordenador
                 */
                if(oDadosDescriptografados['cognito:groups'].includes('Coordenadores')
                    || oDadosDescriptografados['cognito:groups'].includes('coordenadores')
                ){
                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.coordenadores = true
                }

                this.autenticacao.usuario = oDadosDescriptografados.username
                this.autenticacao.exp = oDadosDescriptografados.exp

                /**
                 * Verifica se o token não está expirado
                 */
                if(moment().isAfter(moment.unix(oDadosDescriptografados.exp))){
                    await this.refreshToken()
                }

                /**
                 * Remove o # qque é informado na URL de retorno da AWS
                 */
                // if (window.location.href.charAt(window.location.href.length - 1) == "#") {
                //     window.location.replace(window.location.href.split("#")[0]);
                // }

                return true
            },  
            /**
             * Função para definir a próxima aba caso seja o último campo da aba atual
             */      
            tabUltimoCampo(ativo) {
                this.formAtivo = ativo
            },  
            /**
             * Função para verificar se o documento é um CPF
             */      
            verificaCpfCnpj(sValor) {
                if(!sValor || sValor.length == 0){
                    return undefined
                }

                if(sValor.length < 14){
                    return true
                }

                return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(sValor)
            },
            /**
             * Função para adicionar um novo nome da parte
             */
            adicionarNome(sTipo, sRef){
                /**
                 * Define os dados do formulario
                 *
                 * @var {Object} oDados
                 */
                let oDados = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao.pessoal
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar.pessoal
                    sTipo = 'descricaoRegistroAuxiliar'
                }else{
                    oDados = this.ato.pessoal
                }

                if(!oDados.nomes){
                    oDados.nomes = []
                }
                
                oDados.nomes.push(oDados.nome)

                setTimeout(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInput
                     */
                    const oInput = this.$refs[`${sRef}_${oDados.nomes.length - 1}`][0].$refs.input

                    if (oInput) {
                        oInput.focus();
                    }
                }, 100)
            },
            /**
             * Função para remover um nome da parte
             */
            removerNome(sTipo, iIndiceNome){
                /**
                 * Define os dados do formulario
                 *
                 * @var {Object} oDados
                 */
                let oDados = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao.pessoal
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar.pessoal
                    sTipo = 'descricaoRegistroAuxiliar'
                }else{
                    oDados = this.ato.pessoal
                }

                oDados.nomes = [...oDados.nomes]
                oDados.nomes.splice(iIndiceNome, 1)
            },
            /**
             * Função para adicionar um novo nome do conjuge
             */
            adicionarNomeConjuge(sTipo, sRef){
                /**
                 * Define os dados do formulario
                 *
                 * @var {Object} oDados
                 */
                let oDados = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao.pessoal.conjuge
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar.pessoal.conjuge
                    sTipo = 'descricaoRegistroAuxiliar'
                }else{
                    oDados = this.ato.pessoal.conjuge
                }

                if(!oDados.nomes){
                    oDados.nomes = []
                }
                
                oDados.nomes.push(oDados.nome)

                setTimeout(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInput
                     */
                    const oInput = this.$refs[`${sRef}_${oDados.nomes.length - 1}`][0].$refs.input

                    if (oInput) {
                        oInput.focus();
                    }
                }, 100)
            },
            /**
             * Função para remover um nome do conjuge
             */
            removerNomeConjuge(sTipo, iIndiceNome){
                /**
                 * Define os dados do formulario
                 *
                 * @var {Object} oDados
                 */
                let oDados = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao.pessoal.conjuge
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar.pessoal.conjuge
                    sTipo = 'descricaoRegistroAuxiliar'
                }else{
                    oDados = this.ato.pessoal.conjuge
                }

                oDados.nomes = [...oDados.nomes]
                oDados.nomes.splice(iIndiceNome, 1)
            },
            /**
             * Função para adicionar uma nova averbação
             */
            adicionarAverbacao() {
                if(this.averbacao.numeroAto == ''){
                    toastAlert('Informe o número do ato')

                    return
                }

                if(this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]){
                    this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice] = {
                        numeroAto: this.averbacao.numeroAto,
                        dataRegistro: this.averbacao.dataRegistro,
                        tipoAto: this.averbacao.tipoAto,
                        descricaoAto: this.averbacao.descricaoAto,
                        dataInstrumento: this.averbacao.dataInstrumento,
                        pessoal: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal ?? []
                    }

                    this.listaAverbacoes[this.averbacao.indice] = {
                        numeroAto: this.averbacao.numeroAto,
                        descricaoAto: this.averbacao.descricaoAto
                    }

                    toastAlert(`Ato alterado com sucesso`, 'success')
                }else{
                    this.form.descricaoRegistroAuxiliar.averbacoes.push({
                        numeroAto: this.averbacao.numeroAto,
                        dataRegistro: this.averbacao.dataRegistro,
                        tipoAto: this.averbacao.tipoAto,
                        descricaoAto: this.averbacao.descricaoAto,
                        dataInstrumento: this.averbacao.dataInstrumento,
                        pessoal: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal ?? []
                    })

                    this.listaAverbacoes.push({
                        numeroAto: this.averbacao.numeroAto,
                        descricaoAto: this.averbacao.descricaoAto
                    })

                    toastAlert(`Ato adicionado com sucesso`, 'success')
                }

                this.cancelarEdicaoAverbacao()
            },
            /**
             * Função para remover uma averbação
             */
            removerAverbacao(bAbrirModal = true){
                if(bAbrirModal){
                    this.modalRemoverAverbacao = true

                    return
                }
                    
                this.modalRemoverAverbacao = false

                if(this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]){
                    this.form.descricaoRegistroAuxiliar.averbacoes = [...this.form.descricaoRegistroAuxiliar.averbacoes]
                    this.form.descricaoRegistroAuxiliar.averbacoes.splice(this.averbacao.indice, 1)
                }

                this.listaAverbacoes.splice(this.averbacao.indice, 1)

                this.cancelarEdicaoAverbacao()

                toastAlert('Ato removido com sucesso', 'success')
            },
            /**
             * Função para selecionar a averbação
             */
            selecionarAverbacao(iAverbacao) {
                if(iAverbacao == ''){
                    return
                }

                this.averbacao = {
                    indice: parseInt(iAverbacao),
                    edicao: true,
                    numeroAto: this.form.descricaoRegistroAuxiliar.averbacoes[iAverbacao].numeroAto,
                    dataRegistro: this.form.descricaoRegistroAuxiliar.averbacoes[iAverbacao].dataRegistro,
                    tipoAto: this.form.descricaoRegistroAuxiliar.averbacoes[iAverbacao].tipoAto,
                    descricaoAto: this.form.descricaoRegistroAuxiliar.averbacoes[iAverbacao].descricaoAto,
                    dataInstrumento: this.form.descricaoRegistroAuxiliar.averbacoes[iAverbacao].dataInstrumento,
                    pessoal: {}
                }

                this.cancelarEdicaoParteAverbacao()

                this.$forceUpdate()
            },
            /**
             * Função para adicionar uma nova parte na averbação
             */
            adicionarParteAverbacao() {
                if(this.averbacao.pessoal.documento == ''){
                    toastAlert('Informe o documento da parte')

                    return
                }

                if((this.averbacao.pessoal.tipoDocumento == 'CPF' || this.averbacao.pessoal.tipoDocumento == 'CNPJ') && !cpf.isValid(this.averbacao.pessoal.documento.replace(/[^\w\s]/gi, "")) && !cnpj.isValid(this.averbacao.pessoal.documento.replace(/[^\w\s]/gi, ""))){
                    toastAlert('CPF/CNPJ inválido')

                    return
                }

                if(this.averbacao.pessoal.nome == ''){
                    toastAlert('Informe o nome da parte')

                    return
                }

                if(!this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal){
                    this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice] = {
                        pessoal: []
                    }
                }

                if(this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[this.averbacao.pessoal.indice]){
                    this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[this.averbacao.pessoal.indice] = {
                        documento: this.averbacao.pessoal.documento,
                        nome: this.averbacao.pessoal.nome,
                        nacionalidade: this.averbacao.pessoal.nacionalidade,
                        classificacao: this.averbacao.pessoal.classificacao,
                        tipoDocumento: this.averbacao.pessoal.tipoDocumento,
                        tipoDocumentoConjuge: this.averbacao.pessoal.tipoDocumentoConjuge,
                        documentoConjuge: this.averbacao.pessoal.documentoConjuge,
                        nomeConjuge: this.averbacao.pessoal.nomeConjuge,
                        inconsistencia: this.averbacao.pessoal.inconsistencia,
                        conjuge: this.averbacao.pessoal.conjuge,
                        estadoCivil: this.averbacao.pessoal.estadoCivil,
                        uniaoEstavel: this.averbacao.pessoal.uniaoEstavel,
                        regimeBens: this.averbacao.pessoal.regimeBens,
                        nomes: this.averbacao.pessoal.nomes
                    }

                    toastAlert(`Parte alterada com sucesso`, 'success')
                }else{
                    this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal.push({
                        documento: this.averbacao.pessoal.documento,
                        nome: this.averbacao.pessoal.nome,
                        nacionalidade: this.averbacao.pessoal.nacionalidade,
                        classificacao: this.averbacao.pessoal.classificacao,
                        tipoDocumento: this.averbacao.pessoal.tipoDocumento,
                        tipoDocumentoConjuge: this.averbacao.pessoal.tipoDocumentoConjuge,
                        documentoConjuge: this.averbacao.pessoal.documentoConjuge,
                        nomeConjuge: this.averbacao.pessoal.nomeConjuge,
                        inconsistencia: this.averbacao.pessoal.inconsistencia,
                        conjuge: this.averbacao.pessoal.conjuge,
                        estadoCivil: this.averbacao.pessoal.estadoCivil,
                        uniaoEstavel: this.averbacao.pessoal.uniaoEstavel,
                        regimeBens: this.averbacao.pessoal.regimeBens,
                        nomes: this.averbacao.pessoal.nomes
                    })

                    toastAlert(`Parte adicionada com sucesso`, 'success')
                }

                this.cancelarEdicaoParteAverbacao()
            },
            /**
             * Função para remover a parte da averbação
             */
            removerParteAverbacao(bAbrirModal = true){
                if(bAbrirModal){
                    this.modalRemoverAverbacaoParte = true

                    return
                }
                    
                this.modalRemoverAverbacaoParte = false

                if(this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[this.averbacao.pessoal.indice]){
                    this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal = [...this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal]
                    this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal.splice(this.averbacao.pessoal.indice, 1)
                }

                this.cancelarEdicaoParteAverbacao()

                toastAlert('Parte removida com sucesso', 'success')
            },
            /**
             * Função para selecionar a parte da averbação
             */
            selecionarParteAverbacao(iIndice) {
                if(iIndice == ''){
                    return
                }

                this.averbacao.pessoal = {
                    indice: parseInt(iIndice),
                    edicao: true,
                    documento: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].documento,
                    nome: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].nome,
                    nacionalidade: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].nacionalidade,
                    classificacao: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].classificacao,
                    tipoDocumento: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].tipoDocumento,
                    tipoDocumentoConjuge: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].tipoDocumentoConjuge,
                    documentoConjuge: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].documentoConjuge,
                    nomeConjuge: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].nomeConjuge,
                    inconsistencia: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].inconsistencia,
                    conjuge: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].conjuge,
                    estadoCivil: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].estadoCivil,
                    uniaoEstavel: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].uniaoEstavel,
                    regimeBens: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].regimeBens,
                    nomes: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].nomes,
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal[iIndice].inconsistencia != '',
                    mascara: false,
                    mascaraConjuge: false
                }

                this.$forceUpdate()
            },
            /**
             * Função para adicionar uma nova parte no registro auxiliar
             */
            adicionarParteRA() {
                if(this.descricaoRegistroAuxiliar.pessoal.documento == ''){
                    toastAlert('Informe o documento da parte')

                    return
                }

                if(
                    (this.descricaoRegistroAuxiliar.pessoal.tipoDocumento == 'CPF' || this.descricaoRegistroAuxiliar.pessoal.tipoDocumento == 'CNPJ') && 
                    !cpf.isValid(this.descricaoRegistroAuxiliar.pessoal.documento.replace(/[^\w\s]/gi, "")) && 
                    !cnpj.isValid(this.descricaoRegistroAuxiliar.pessoal.documento.replace(/[^\w\s]/gi, "")) &&
                    (this.descricaoRegistroAuxiliar.pessoal.documento.replace(/[^\w\s]/gi, "").length == 11 || this.descricaoRegistroAuxiliar.pessoal.documento.replace(/[^\w\s]/gi, "").length == 14)
                ){
                    toastAlert('CPF/CNPJ inválido')

                    return
                }

                if(this.descricaoRegistroAuxiliar.pessoal.nome == ''){
                    toastAlert('Informe o nome da parte')

                    return
                }

                if(!this.form.descricaoRegistroAuxiliar?.pessoal){
                    this.form.descricaoRegistroAuxiliar = {
                        pessoal: []
                    }
                }

                if(this.form.descricaoRegistroAuxiliar.pessoal[this.descricaoRegistroAuxiliar.pessoal.indice]){
                    this.form.descricaoRegistroAuxiliar.pessoal[this.descricaoRegistroAuxiliar.pessoal.indice] = {
                        documento: this.descricaoRegistroAuxiliar.pessoal.documento,
                        nome: this.descricaoRegistroAuxiliar.pessoal.nome,
                        nacionalidade: this.descricaoRegistroAuxiliar.pessoal.nacionalidade,
                        classificacao: this.descricaoRegistroAuxiliar.pessoal.classificacao,
                        tipoDocumento: this.descricaoRegistroAuxiliar.pessoal.tipoDocumento,
                        tipoDocumentoConjuge: this.descricaoRegistroAuxiliar.pessoal.tipoDocumentoConjuge,
                        documentoConjuge: this.descricaoRegistroAuxiliar.pessoal.documentoConjuge,
                        nomeConjuge: this.descricaoRegistroAuxiliar.pessoal.nomeConjuge,
                        inconsistencia: this.descricaoRegistroAuxiliar.pessoal.inconsistencia,
                        conjuge: this.descricaoRegistroAuxiliar.pessoal.conjuge,
                        estadoCivil: this.descricaoRegistroAuxiliar.pessoal.estadoCivil,
                        uniaoEstavel: this.descricaoRegistroAuxiliar.pessoal.uniaoEstavel,
                        regimeBens: this.descricaoRegistroAuxiliar.pessoal.regimeBens,
                        nomes: this.descricaoRegistroAuxiliar.pessoal.nomes
                    }
                }else{
                    this.form.descricaoRegistroAuxiliar.pessoal.push({
                        documento: this.descricaoRegistroAuxiliar.pessoal.documento,
                        nome: this.descricaoRegistroAuxiliar.pessoal.nome,
                        nacionalidade: this.descricaoRegistroAuxiliar.pessoal.nacionalidade,
                        classificacao: this.descricaoRegistroAuxiliar.pessoal.classificacao,
                        tipoDocumento: this.descricaoRegistroAuxiliar.pessoal.tipoDocumento,
                        tipoDocumentoConjuge: this.descricaoRegistroAuxiliar.pessoal.tipoDocumentoConjuge,
                        documentoConjuge: this.descricaoRegistroAuxiliar.pessoal.documentoConjuge,
                        nomeConjuge: this.descricaoRegistroAuxiliar.pessoal.nomeConjuge,
                        inconsistencia: this.descricaoRegistroAuxiliar.pessoal.inconsistencia,
                        conjuge: this.descricaoRegistroAuxiliar.pessoal.conjuge,
                        estadoCivil: this.descricaoRegistroAuxiliar.pessoal.estadoCivil,
                        uniaoEstavel: this.descricaoRegistroAuxiliar.pessoal.uniaoEstavel,
                        regimeBens: this.descricaoRegistroAuxiliar.pessoal.regimeBens,
                        nomes: this.descricaoRegistroAuxiliar.pessoal.nomes
                    })
                }

                toastAlert(`Parte ${this.averbacao.edicao ? 'alterada' : 'adicionada'} com sucesso`, 'success')

                this.cancelarEdicaoParteRA()
            },
            /**
             * Função para remover a parte no registro auxiliar
             */
            removerParteRA(bAbrirModal = true){
                if(bAbrirModal){
                    this.modalRemoverRAParte = true

                    return
                }
                    
                this.modalRemoverRAParte = false

                if(this.form.descricaoRegistroAuxiliar.pessoal[this.descricaoRegistroAuxiliar.pessoal.indice]){
                    this.form.descricaoRegistroAuxiliar.pessoal = [...this.form.descricaoRegistroAuxiliar.pessoal]
                    this.form.descricaoRegistroAuxiliar.pessoal.splice(this.descricaoRegistroAuxiliar.pessoal.indice, 1)
                }

                this.cancelarEdicaoParteRA()

                toastAlert('Parte removida com sucesso', 'success')
            },
            /**
             * Função para selecionar a parte no registro auxiliar
             */
            selecionarParteRA(iIndice) {
                if(iIndice == ''){
                    return
                }

                this.descricaoRegistroAuxiliar.pessoal = {
                    indice: parseInt(iIndice),
                    edicao: true,
                    documento: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].documento,
                    nome: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].nome,
                    nacionalidade: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].nacionalidade,
                    classificacao: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].classificacao,
                    tipoDocumento: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].tipoDocumento,
                    tipoDocumentoConjuge: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].tipoDocumentoConjuge,
                    documentoConjuge: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].documentoConjuge,
                    nomeConjuge: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].nomeConjuge,
                    inconsistencia: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].inconsistencia,
                    conjuge: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].conjuge,
                    estadoCivil: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].estadoCivil,
                    uniaoEstavel: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].uniaoEstavel,
                    regimeBens: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].regimeBens,
                    nomes: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].nomes,
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: this.form.descricaoRegistroAuxiliar.pessoal[iIndice].inconsistencia != '',
                    mascara: false,
                    mascaraConjuge: false
                }

                this.$forceUpdate()
            },
            /**
             * Função para selecionar o ato
             */
            selecionarAto(iIndice) {
                if(iIndice == ''){
                    return
                }
                
                for (const iIndiceAto in this.form.ato){
                    for (const iIndiceCancelamento in this.form.ato[iIndiceAto].cancelamento){
                        if(this.form.ato[iIndiceAto].cancelamento[iIndiceCancelamento] == ''){
                            continue
                        }
                        
                        /**
                         * Define o ato
                         *
                         * @var {object} oAto
                         */
                        const oAto = this.form.ato[this.form.ato.findIndex(ato => ato.numeroAto == this.form.ato[iIndiceAto].cancelamento[iIndiceCancelamento])]

                        this.onusAtivos[oAto.numeroAto] = `Ato ${oAto.numeroAto} ${oAto.descricao != '' ? '-' : ''} ${oAto.descricao} ${this.tiposAto[oAto.tipo] ? ` - ${this.tiposAto[oAto.tipo].split(' - ')[1]}` : ''} ${oAto.descricaoAdicional ? ` - ${oAto.descricaoAdicional}` : ''}`
                    }
                }

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectCancelamento
                 */
                const oSelectCancelamento = this.$refs.selectCancelamento
                
                oSelectCancelamento.clearSelectize()
                oSelectCancelamento.updateOptions(this.onusAtivos)
                oSelectCancelamento.clearSelectize()

                if(this.form.ato[iIndice].onus == ''){
                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectOnus
                     */
                    const oSelectOnus = this.$refs.selectOnus
                    oSelectOnus.clearSelectize()
                }

                if(this.form.ato[iIndice].tipoTransacao == ''){
                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectTipoTransacao
                     */
                    const oSelectTipoTransacao = this.$refs.selectTipoTransacao
                    oSelectTipoTransacao.clearSelectize()
                }
                
                this.ato = {
                    indice: parseInt(iIndice),
                    edicao: true,
                    numeroAto: this.form.ato[iIndice].numeroAto,
                    dataRegistro: this.form.ato[iIndice].dataRegistro,
                    descricao: this.form.ato[iIndice].descricao,
                    tipo: this.form.ato[iIndice].tipo,
                    descricaoAdicional: this.form.ato[iIndice].descricaoAdicional,
                    dataInstrumento: this.form.ato[iIndice].dataInstrumento,
                    tipoMoeda: this.form.ato[iIndice].tipoMoeda,
                    valorDeclarado: this.form.ato[iIndice].valorDeclarado,
                    valorFiscal: this.form.ato[iIndice].valorFiscal,
                    onus: this.form.ato[iIndice].onus,
                    tipoTransacao: this.form.ato[iIndice].tipoTransacao,
                    cancelamento: this.form.ato[iIndice].cancelamento,
                    seloFiscalizacao: this.form.ato[iIndice].seloFiscalizacao,
                    pessoal: {
                        indice: 0,
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        fracao: '',
                        valorFracional: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false,
                        conjuge: {
                            documento: '',
                            nome: '',
                            nacionalidade: '',
                            fracao: '',
                            valorFracional: '',
                            estadoCivil: '',
                            uniaoEstavel: '',
                            regimeBens: '',
                            classificacao: '',
                            tipoDocumento: '',
                            tipoDocumentoConjuge: '',
                            documentoConjuge: '',
                            nomeConjuge: '',
                            inconsistencia: '',
                            conjuge: '',
                            nomes: [],
                            loading: false,
                            loadingConjuge: false,
                            opcaoInconsistencia: false,
                            mascara: false,
                            mascaraConjuge: false                        
                        }
                    }
                }

                this.cancelarEdicaoParteAto(false)

                this.$forceUpdate()
            },
            /**
             * Função para adicionar uma nova parte no ato
             */
            adicionarParteAto(bExibirAlerta = true){
                if(this.ato.pessoal.documento == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o documento da parte')
                    }

                    return
                }

                if(
                    (this.ato.pessoal.tipoDocumento == 'CPF' || this.ato.pessoal.tipoDocumento == 'CNPJ') && 
                    !cpf.isValid(this.ato.pessoal.documento.replace(/[^\w\s]/gi, "")) && 
                    !cnpj.isValid(this.ato.pessoal.documento.replace(/[^\w\s]/gi, "")) &&
                    (this.ato.pessoal.documento.replace(/[^\w\s]/gi, "").length == 11 || this.ato.pessoal.documento.replace(/[^\w\s]/gi, "").length == 14)
                ){
                    if(bExibirAlerta){
                        toastAlert('CPF/CNPJ inválido')
                    }

                    return
                }
                
                if(this.ato.pessoal.nome == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o nome da parte')
                    }

                    return
                }

                if(!this.form.ato[this.ato.indice]?.pessoal){
                    this.form.ato[this.ato.indice] = {
                        pessoal: []
                    }
                }

                if(this.form.ato[this.ato.indice].pessoal[this.ato.pessoal.indice]){
                    this.form.ato[this.ato.indice].pessoal[this.ato.pessoal.indice] = {
                        documento: this.ato.pessoal.documento,
                        nome: this.ato.pessoal.nome,
                        nacionalidade: this.ato.pessoal.nacionalidade,
                        fracao: this.ato.pessoal.fracao,
                        valorFracional: this.ato.pessoal.valorFracional,
                        classificacao: this.ato.pessoal.classificacao,
                        tipoDocumento: this.ato.pessoal.tipoDocumento,
                        tipoDocumentoConjuge: this.ato.pessoal.tipoDocumentoConjuge,
                        documentoConjuge: this.ato.pessoal.documentoConjuge,
                        nomeConjuge: this.ato.pessoal.nomeConjuge,
                        inconsistencia: this.ato.pessoal.inconsistencia,
                        conjuge: this.ato.pessoal.conjuge,
                        estadoCivil: this.ato.pessoal.estadoCivil,
                        uniaoEstavel: this.ato.pessoal.uniaoEstavel,
                        regimeBens: this.ato.pessoal.regimeBens,
                        nomes: this.ato.pessoal.nomes
                    }

                    if(bExibirAlerta){
                        toastAlert(`Parte alterada com sucesso`, 'success')
                    }
                }else{
                    this.form.ato[this.ato.indice].pessoal.push({
                        documento: this.ato.pessoal.documento,
                        nome: this.ato.pessoal.nome,
                        nacionalidade: this.ato.pessoal.nacionalidade,
                        fracao: this.ato.pessoal.fracao,
                        valorFracional: this.ato.pessoal.valorFracional,
                        classificacao: this.ato.pessoal.classificacao,
                        tipoDocumento: this.ato.pessoal.tipoDocumento,
                        tipoDocumentoConjuge: this.ato.pessoal.tipoDocumentoConjuge,
                        documentoConjuge: this.ato.pessoal.documentoConjuge,
                        nomeConjuge: this.ato.pessoal.nomeConjuge,
                        inconsistencia: this.ato.pessoal.inconsistencia,
                        conjuge: this.ato.pessoal.conjuge,
                        estadoCivil: this.ato.pessoal.estadoCivil,
                        uniaoEstavel: this.ato.pessoal.uniaoEstavel,
                        regimeBens: this.ato.pessoal.regimeBens,
                        nomes: this.ato.pessoal.nomes
                    })

                    if(this.ato.pessoal.conjuge.classificacao && this.ato.pessoal.conjuge.classificacao != ''){	
                        if(!this.form.ato[this.ato.indice]?.pessoal){	
                            this.form.ato[this.ato.indice] = {	
                                pessoal: []	
                            }	
                        }	

                        this.form.ato[this.ato.indice].pessoal.push({	
                            documento: this.ato.pessoal.conjuge.documento,	
                            nome: this.ato.pessoal.conjuge.nome,	
                            nacionalidade: this.ato.pessoal.conjuge.nacionalidade,	
                            fracao: this.ato.pessoal.conjuge.fracao,	
                            valorFracional: this.ato.pessoal.conjuge.valorFracional,	
                            classificacao: this.ato.pessoal.conjuge.classificacao,	
                            tipoDocumento: this.ato.pessoal.conjuge.tipoDocumento,	
                            tipoDocumentoConjuge: this.ato.pessoal.conjuge.tipoDocumentoConjuge,	
                            documentoConjuge: this.ato.pessoal.conjuge.documentoConjuge,	
                            nomeConjuge: this.ato.pessoal.conjuge.nomeConjuge,	
                            inconsistencia: this.ato.pessoal.conjuge.inconsistencia,	
                            conjuge: this.ato.pessoal.conjuge.conjuge,	
                            estadoCivil: this.ato.pessoal.conjuge.estadoCivil,	
                            uniaoEstavel: this.ato.pessoal.conjuge.uniaoEstavel,	
                            regimeBens: this.ato.pessoal.conjuge.regimeBens,	
                            nomes: this.ato.pessoal.conjuge.nomes	
                        })
                    }

                    if(bExibirAlerta){
                        toastAlert(`Parte adicionada com sucesso`, 'success')
                    }
                }

                this.cancelarEdicaoParteAto()
            },
            /**
             * Função para remover a parte do ato
             */
            removerParteAto(bAbrirModal = true){
                if(bAbrirModal){
                    this.modalRemoverParte = true

                    return
                }
                    
                this.modalRemoverParte = false

                if(this.form.ato[this.ato.indice].pessoal[this.ato.pessoal.indice]){
                    this.form.ato[this.ato.indice].pessoal = [...this.form.ato[this.ato.indice].pessoal]
                    this.form.ato[this.ato.indice].pessoal.splice(this.ato.pessoal.indice, 1)
                }

                this.cancelarEdicaoParteAto(false)

                toastAlert('Parte removida com sucesso', 'success')
            },
            /**
             * Função para selecionar a parte no ato
             */
            selecionarParteAto(iIndice) {
                if(iIndice == ''){
                    return
                }

                this.ato.pessoal = {
                    indice: parseInt(iIndice),
                    edicao: true,
                    documento: this.form.ato[this.ato.indice].pessoal[iIndice].documento,
                    nome: this.form.ato[this.ato.indice].pessoal[iIndice].nome,
                    nacionalidade: this.form.ato[this.ato.indice].pessoal[iIndice].nacionalidade,
                    fracao: this.form.ato[this.ato.indice].pessoal[iIndice].fracao,
                    valorFracional: this.form.ato[this.ato.indice].pessoal[iIndice].valorFracional,
                    classificacao: this.form.ato[this.ato.indice].pessoal[iIndice].classificacao,
                    tipoDocumento: this.form.ato[this.ato.indice].pessoal[iIndice].tipoDocumento,
                    tipoDocumentoConjuge: this.form.ato[this.ato.indice].pessoal[iIndice].tipoDocumentoConjuge,
                    documentoConjuge: this.form.ato[this.ato.indice].pessoal[iIndice].documentoConjuge,
                    nomeConjuge: this.form.ato[this.ato.indice].pessoal[iIndice].nomeConjuge,
                    inconsistencia: this.form.ato[this.ato.indice].pessoal[iIndice].inconsistencia,
                    conjuge: this.form.ato[this.ato.indice].pessoal[iIndice].conjuge,
                    estadoCivil: this.form.ato[this.ato.indice].pessoal[iIndice].estadoCivil,
                    uniaoEstavel: this.form.ato[this.ato.indice].pessoal[iIndice].uniaoEstavel,
                    regimeBens: this.form.ato[this.ato.indice].pessoal[iIndice].regimeBens,
                    nomes: this.form.ato[this.ato.indice].pessoal[iIndice].nomes,
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: this.form.ato[this.ato.indice].pessoal[iIndice].inconsistencia != '',
                    mascara: false,
                    mascaraConjuge: false,
                    conjuge: {
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        fracao: '',
                        valorFracional: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false                        
                    }
                }

                this.$forceUpdate()
            },
            /**
             * Função para cancelar a edição da averbação
             */
            cancelarEdicaoAverbacao(bFocar = true) {
                this.averbacao = {
                    indice: this.listaAverbacoes.length,
                    edicao: false,
                    numeroAto: '',
                    dataRegistro: '',
                    tipoAto: '',
                    descricaoAto: '',
                    dataInstrumento: '',
                    pessoal: {
                        indice: 0,
                        edicao: false,
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        nomes: '',
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false
                    }
                }

                this.$forceUpdate()

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosAverbacoes
                 */
                const aDadosAverbacoes = this.listaAverbacoes.map(averbacao => {
                    return `${averbacao.descricaoAto != '' ? `${averbacao.descricaoAto} - ` : ''} ${averbacao.numeroAto != '' ? `${averbacao.numeroAto}` : ''}`
                })
                
                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelect
                 */
                const oSelect = this.$refs.selectAv
                
                oSelect.clearSelectize()
                oSelect.updateOptions(aDadosAverbacoes)
                oSelect.clearSelectize()

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosPartesAv
                 */
                const aDadosPartesAv = this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal.map(pessoal => {
                    return `${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectParteAv
                 */
                const oSelectParteAv = this.$refs.selectAvParte
                
                oSelectParteAv.clearSelectize()
                oSelectParteAv.updateOptions(aDadosPartesAv)
                oSelectParteAv.clearSelectize()
            },
            /**
             * Função para cancelar a edição da parte da averbação
             */
            cancelarEdicaoParteAverbacao(bFocar = true) {
                this.averbacao.pessoal = {
                    indice: this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal ? this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal.length : 0,
                    edicao: false,
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    nomes: '',
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false
                }

                this.$forceUpdate()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectClassificacao
                 * @var {object} oSelectTipoDocumento
                 * @var {object} oSelectNacionalidade
                 * @var {object} oSelectEstadoCivil
                 * @var {object} oSelectRegime
                 * @var {object} oSelectUniaoEstavel
                 * @var {object} oSelectTipoDocumentoConjuge
                 * @var {object} oSelectInconsistencia
                 */
                 const oSelectClassificacao = this.$refs.selectClassificacao;
                oSelectClassificacao.clearSelectize()
                const oSelectTipoDocumento = this.$refs.selectTipoDocumento;
                oSelectTipoDocumento.clearSelectize()
                const oSelectNacionalidade = this.$refs.selectNacionalidade;
                oSelectNacionalidade.clearSelectize()
                const oSelectEstadoCivil = this.$refs.selectEstadoCivil;
                oSelectEstadoCivil.clearSelectize()
                const oSelectRegime = this.$refs.selectRegime;
                oSelectRegime.clearSelectize()
                const oSelectUniaoEstavel = this.$refs.selectUniaoEstavel;
                oSelectUniaoEstavel.clearSelectize()
                const oSelectTipoDocumentoConjuge = this.$refs.selectTipoDocumentoConjuge;
                oSelectTipoDocumentoConjuge.clearSelectize()
                const oSelectInconsistencia = this.$refs.selectInconsistencia;
                oSelectInconsistencia.clearSelectize()

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosPartesAv
                 */
                const aDadosPartesAv = this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal.map((pessoal, idx) => {
                    return `${idx+1} - ${pessoal.classificacao} ${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelect
                 */
                const oSelect = this.$refs.selectAvParte
                
                oSelect.clearSelectize()
                oSelect.updateOptions(aDadosPartesAv)
                oSelect.clearSelectize()

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInput
                     */
                    const oInput = this.$refs.parte_averbacao;

                    if (oInput && bFocar) {
                        oInput.querySelector('input').focus();
                    }
                })
            },
            atualizarPartesSelecionadas(){
                this.partesAdicionadas = []
                this.listaAtos = []

                Object.keys(this.form.ato).map((idx) => {
                    if(this.form.ato[idx].numeroAto && this.form.ato[idx].numeroAto != ''){
                        this.listaAtos.push({
                            numeroAto: this.form.ato[idx].numeroAto,
                            descricao: this.form.ato[idx].descricao,
                            tipo: this.form.ato[idx].tipo,
                            descricaoAdicional: this.form.ato[idx].descricaoAdicional,
                            onus: this.form.ato[idx].onus
                        })
                        
                        if(this.form.ato[idx]?.pessoal){
                            Object.keys(this.form.ato[idx].pessoal).map((indice) => {
                                if(this.partesAdicionadas.filter(obj => obj.nome == this.form.ato[idx].pessoal[indice].nome).length == 0){
                                    this.partesAdicionadas.push({
                                        ato: idx,
                                        indice: indice,
                                        classificacao: this.form.ato[idx].pessoal[indice].classificacao,
                                        documento: this.form.ato[idx].pessoal[indice].documento,
                                        nome: this.form.ato[idx].pessoal[indice].nome,
                                        selecionado: false
                                    })
                                }else{
                                    this.partesAdicionadas[this.partesAdicionadas.findIndex(obj => obj.nome == this.form.ato[idx].pessoal[indice].nome)] = {
                                        ato: idx,
                                        indice: indice,
                                        classificacao: this.form.ato[idx].pessoal[indice].classificacao,
                                        documento: this.form.ato[idx].pessoal[indice].documento,
                                        nome: this.form.ato[idx].pessoal[indice].nome,
                                        selecionado: false
                                    }
                                }
                            }) 
                        }
                    }
                })
            },
            /**
             * Função para cancelar a edição da parte do registro auxiliar
             */
            cancelarEdicaoParteRA(bFocar = true) {
                this.descricaoRegistroAuxiliar.pessoal = {
                    indice: this.form.descricaoRegistroAuxiliar.pessoal.length,
                    edicao: false,
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    nomes: '',
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false
                }

                this.$forceUpdate()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectClassificacao
                 * @var {object} oSelectTipoDocumento
                 * @var {object} oSelectNacionalidade
                 * @var {object} oSelectEstadoCivil
                 * @var {object} oSelectRegime
                 * @var {object} oSelectUniaoEstavel
                 * @var {object} oSelectTipoDocumentoConjuge
                 * @var {object} oSelectInconsistencia
                 */
                const oSelectClassificacao = this.$refs.selectClassificacaoRA
                oSelectClassificacao.clearSelectize()
                const oSelectTipoDocumento = this.$refs.selectTipoDocumentoRA
                oSelectTipoDocumento.clearSelectize()
                const oSelectNacionalidade = this.$refs.selectNacionalidadeRA
                oSelectNacionalidade.clearSelectize()
                const oSelectEstadoCivil = this.$refs.selectEstadoCivilRA
                oSelectEstadoCivil.clearSelectize()
                const oSelectRegime = this.$refs.selectRegimeBensRA
                oSelectRegime.clearSelectize()
                const oSelectUniaoEstavel = this.$refs.selectUniaoEstavelRA
                oSelectUniaoEstavel.clearSelectize()
                const oSelectTipoDocumentoConjuge = this.$refs.selectTipoDocumentoConjugeRA
                oSelectTipoDocumentoConjuge.clearSelectize()
                const oSelectInconsistencia = this.$refs.selectInconsistenciaRA
                oSelectInconsistencia.clearSelectize()

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosPartesAv
                 */
                const aDadosPartesAv = this.form.descricaoRegistroAuxiliar.pessoal.map((pessoal, idx) => {
                    return `${idx+1} - ${pessoal.classificacao} ${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelect
                 */
                const oSelect = this.$refs.selectAvParteRA
                
                oSelect.clearSelectize()
                oSelect.updateOptions(aDadosPartesAv)
                oSelect.clearSelectize()

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInput
                     */
                    const oInput = this.$refs.parte_RA;

                    if (oInput && bFocar) {
                        oInput.querySelector('input').focus();
                    }
                })
            },
            /**
             * Função para cancelar a edição do ato
             */
            cancelarEdicaoAto(bRemover = false) {
                if(bRemover && !this.ato.edicao){
                    if(this.form.ato[this.ato.indice]?.pessoal){
                        this.form.ato[this.ato.indice].pessoal = []
                    }
                }

                this.ato = {
                    indice: this.listaAtos.length,
                    edicao: false,
                    numeroAto: '',
                    dataRegistro: '',
                    descricao: '',
                    tipo: '',
                    descricaoAdicional: '',
                    dataInstrumento: '',
                    tipoMoeda: '',
                    valorDeclarado: '',
                    valorFiscal: '',
                    onus: '',
                    tipoTransacao: '',
                    cancelamento: '',
                    seloFiscalizacao: '',
                    pessoal: {
                        indice: 0,
                        edicao: false,
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        fracao: '',
                        valorFracional: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        nomes: '',
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false
                    }
                }

                this.$forceUpdate()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectTipoAto
                 * @var {object} oSelectTituloAto
                 * @var {object} oSelectTipoMoeda
                 * @var {object} oSelectOnus
                 * @var {object} oSelectTipoTransacao
                 */
                const oSelectTipoAto = this.$refs.selectTipoAto
                oSelectTipoAto.clearSelectize()
                const oSelectTituloAto = this.$refs.selectTituloAto
                oSelectTituloAto.clearSelectize()
                const oSelectTipoMoeda = this.$refs.selectTipoMoeda
                oSelectTipoMoeda.clearSelectize()
                const oSelectOnus = this.$refs.selectOnus
                oSelectOnus.clearSelectize()
                const oSelectTipoTransacao = this.$refs.selectTipoTransacao
                oSelectTipoTransacao.clearSelectize()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelect
                 */
                const oSelect = this.$refs.selectAto
                
                /**
                 * Reestrutura os atos adicionados
                 *
                 * @var {object} aDadosAto
                 */
                const aDadosAto =  Object.entries(this.form.ato).filter(([_, valor]) => valor.numeroAto !== undefined).map(([chave, atoUnico]) => {
                    return `${ atoUnico.numeroAto } ${ atoUnico.descricao != '' ? '-' : '' } ${ atoUnico.descricao } ${ this.tiposAto[atoUnico.tipo] ? ` - ${this.tiposAto[atoUnico.tipo].split(' - ')[1]}` : '' } ${ atoUnico.descricaoAdicional ? ` - ${atoUnico.descricaoAdicional}` : '' } ${ atoUnico.onus == 1 ? ` - Ônus ativo` : '' }`           
                })
                
                oSelect.clearSelectize()
                oSelect.updateOptions(aDadosAto)
                oSelect.clearSelectize()

                Object.entries(this.form.ato).filter(([_, valor]) => valor.numeroAto !== undefined).map(([idx, atoUnico]) => {
                    const option = document.querySelectorAll("div.column.is-half.listaAtos > div > div > div.selectize-dropdown.single > div > div")
                    
                    if(atoUnico.onus == 1){
                        option[idx].classList.add('onusAtivo')
                    }
                })

                for (const iIndiceCancelamento in this.form.ato){
                    if(this.form.ato[iIndiceCancelamento].onus == '1'){
                        this.onusAtivos[this.form.ato[iIndiceCancelamento].numeroAto] = `Ato ${this.form.ato[iIndiceCancelamento].numeroAto} ${this.form.ato[iIndiceCancelamento].descricao != '' ? '-' : ''} ${this.form.ato[iIndiceCancelamento].descricao} ${this.tiposAto[this.form.ato[iIndiceCancelamento].tipo] ? ` - ${this.tiposAto[this.form.ato[iIndiceCancelamento].tipo].split(' - ')[1]}` : ''} ${this.form.ato[iIndiceCancelamento].descricaoAdicional ? ` - ${this.form.ato[iIndiceCancelamento].descricaoAdicional}` : ''}`
                    }
                }

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectCancelamento
                 */
                const oSelectCancelamento = this.$refs.selectCancelamento
                
                oSelectCancelamento.clearSelectize()
                oSelectCancelamento.updateOptions(this.onusAtivos)
                oSelectCancelamento.clearSelectize()

                this.cancelarEdicaoParteAto(false)
            },
            /**
             * Função para cancelar a edição da parte do ato
             */
            cancelarEdicaoParteAto(bFocar = true) {
                this.ato.pessoal = {
                    indice: this.form.ato[this.ato.indice]?.pessoal.length ?? 0,
                    edicao: false,
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    fracao: '',
                    valorFracional: '',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    nomes: '',
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false,
                    conjuge: {
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        fracao: '',
                        valorFracional: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false                        
                    }
                }

                this.$forceUpdate()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectClassificacao
                 * @var {object} oSelectTipoDocumento
                 * @var {object} oSelectNacionalidade
                 * @var {object} oSelectEstadoCivil
                 * @var {object} oSelectRegime
                 * @var {object} oSelectUniaoEstavel
                 * @var {object} oSelectTipoDocumentoConjuge
                 * @var {object} oSelectInconsistencia
                 */
                 const oSelectClassificacao = this.$refs.selectClassificacaoAto
                oSelectClassificacao.clearSelectize()
                const oSelectTipoDocumento = this.$refs.selectTipoDocumentoAto
                oSelectTipoDocumento.clearSelectize()
                const oSelectNacionalidade = this.$refs.selectNacionalidadeAto
                oSelectNacionalidade.clearSelectize()
                const oSelectEstadoCivil = this.$refs.selectEstadoCivilAto
                oSelectEstadoCivil.clearSelectize()
                const oSelectRegime = this.$refs.selectRegimeAto
                oSelectRegime.clearSelectize()
                const oSelectUniaoEstavel = this.$refs.selectUniaoEstavelAto
                oSelectUniaoEstavel.clearSelectize()
                const oSelectTipoDocumentoConjuge = this.$refs.selectTipoDocumentoConjugeAto
                oSelectTipoDocumentoConjuge.clearSelectize()
                const oSelectInconsistencia = this.$refs.selectInconsistenciaAto
                oSelectInconsistencia.clearSelectize()

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosPartesAto
                 */
                const aDadosPartesAto = this.form.ato[this.ato.indice]?.pessoal.map((pessoal, idx) => {
                    return `${idx+1} - ${pessoal.classificacao} ${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectParteAto
                 */
                const oSelectParteAto = this.$refs.selectParteAto
                
                oSelectParteAto.clearSelectize()
                oSelectParteAto.updateOptions(aDadosPartesAto)
                oSelectParteAto.clearSelectize()

                this.atualizarPartesSelecionadas()

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInput
                     */
                    const oInput = this.$refs.parte_ato;

                    if (oInput && bFocar) {
                        oInput.querySelector('input').focus();
                    }
                })
            },
            /**
             * Função para cancelar a edição do registro anterior
             */
            cancelarEdicaoRegistroAnterior(bRemover = false) {
                this.registroAnterior = {
                    indice: this.listaRegistros.length,
                    edicao: false,
                    loading: [false],
                    ato: '',
                    numeroRegistroAnterior: '',
                    tipoRegistroAnterior: parseInt(this.form?.registroAnterior[this.listaRegistros.length - 1]?.tipoRegistroAnterior ?? ''),
                    cnsRegistroAnterior: '111211',
                    dataRegistroAnterior: '',
                    orgaoEmissor: '',
                    beneficiario: [
                        {
                            documento: '',
                            nome: ''
                        }
                    ]
                }

                this.listaRegistros = []

                Object.keys(this.form.registroAnterior).map((idx) => {
                    this.listaRegistros.push({
                        ato: this.form.registroAnterior[idx].ato,
                        numeroRegistroAnterior: this.form.registroAnterior[idx].numeroRegistroAnterior,
                        tipoRegistroAnterior: this.form.registroAnterior[idx].tipoRegistroAnterior
                    })
                })

                this.$forceUpdate()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectTipoRegistro
                 * @var {object} oSelectCns
                 * @var {object} oSelectOrgaoEmissor
                 */
                // const oSelectTipoRegistro = this.$refs.selectTipoRegistro
                // oSelectTipoRegistro.clearSelectize()
                // const oSelectCns = this.$refs.selectCns
                // oSelectCns.clearSelectize()
                const oSelectOrgaoEmissor = this.$refs.selectOrgaoEmissor
                oSelectOrgaoEmissor.clearSelectize()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelect
                 */
                const oSelect = this.$refs.selectRegistroAnterior
                
                /**
                 * Reestrutura os registros adicionados
                 *
                 * @var {object} aDadosRegistroAnterior
                 */
                const aDadosRegistroAnterior = Object.entries(this.form.registroAnterior).filter(([_, valor]) => valor.numeroRegistroAnterior !== undefined).map(([chave, registroUnico]) => {
                    return `${this.tiposRegistro[registroUnico.tipoRegistroAnterior] ? this.tiposRegistro[registroUnico.tipoRegistroAnterior].split(' - ')[1] : ''} ${ registroUnico.ato != '' ? `- ${registroUnico.ato}` : '' } ${ registroUnico.numeroRegistroAnterior != '' ? `- ${registroUnico.numeroRegistroAnterior}` : ''}`           
                })
                
                oSelect.clearSelectize()
                oSelect.updateOptions(aDadosRegistroAnterior)
                oSelect.clearSelectize()
            },
            /**
             * Função para selecionar o registro anterior
             */
            selecionarRegistroAnterior(iIndice) {
                if(iIndice == ''){
                    return
                }
                
                this.registroAnterior = {
                    indice: parseInt(iIndice),
                    edicao: true,
                    loading: [false],
                    ato: this.form.registroAnterior[iIndice].ato,
                    numeroRegistroAnterior: this.form.registroAnterior[iIndice].numeroRegistroAnterior,
                    tipoRegistroAnterior: this.form.registroAnterior[iIndice].tipoRegistroAnterior,
                    cnsRegistroAnterior: this.form.registroAnterior[iIndice].cnsRegistroAnterior,
                    dataRegistroAnterior: this.form.registroAnterior[iIndice].dataRegistroAnterior,
                    orgaoEmissor: this.form.registroAnterior[iIndice].orgaoEmissor,
                    beneficiario: this.form.registroAnterior[iIndice].beneficiario
                }

                this.$forceUpdate()
            },
            /**
             * Função para selecionar o endereço
             */
            selecionarEndereco(iIndice) {
                if(iIndice == ''){
                    return
                }
                
                this.endereco = {
                    indice: parseInt(iIndice),
                    edicao: true,
                    loading: false,
                    cep: this.form.enderecos[iIndice].cep,
                    tipoLogradouro: this.form.enderecos[iIndice].tipoLogradouro,
                    nomeLogradouro: this.form.enderecos[iIndice].nomeLogradouro,
                    bairro: this.form.enderecos[iIndice].bairro,
                    numero: this.form.enderecos[iIndice].numero,
                    complemento: this.form.enderecos[iIndice].complemento,
                    complementoQuadra: this.form.enderecos[iIndice].complementoQuadra,
                    quadra: '',
                    lote: '',
                    loteQuadras: this.form.enderecos[iIndice].loteQuadras
                }

                this.$forceUpdate()

                this.cancelarEdicaoLoteQuadra(false)
            },
            /**
             * Função para cancelar a edição do endereço
             */
            cancelarEdicaoEndereco(bFocar = true) {
                this.endereco = {
                    indice: this.listaEnderecos.length,
                    edicao: false,
                    loading: false,
                    cep: '',
                    tipoLogradouro: '',
                    nomeLogradouro: '',
                    bairro: '',
                    numero: '',
                    complemento: '',
                    complementoQuadra: '',
                    quadra: '',
                    lote: '',
                    loteQuadras: []
                }

                this.listaLotesQuadras = []

                this.$forceUpdate()

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectTipoLogradouro
                 */
                 const oSelectTipoLogradouro = this.$refs.selectTipoLogradouro
                oSelectTipoLogradouro.clearSelectize()

                /**
                 * Reestrutura os endereços adicionados
                 *
                 * @var {object} aDadosEnderecos
                 */
                const aDadosEnderecos = this.form.enderecos.map((endereco, idx) => {
                    return `${ endereco.cep } ${ endereco.cep != '' ? `-` : `` } ${ endereco.tipoLogradouro && this.tiposLogradouro[endereco.tipoLogradouro] ? this.tiposLogradouro[endereco.tipoLogradouro].split(' - ')[1] : '' } ${ !endereco.tipoLogradouro && endereco.nomeLogradouro  ? '-' : '' } ${ endereco.nomeLogradouro }${ endereco.numero ? `, ${endereco.numero}` : '' }`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectEndereco
                 */
                const oSelectEndereco = this.$refs.selectEndereco
                
                oSelectEndereco.clearSelectize()
                oSelectEndereco.updateOptions(aDadosEnderecos)
                oSelectEndereco.clearSelectize()

                this.listaEnderecos = []

                Object.keys(this.form.enderecos).map((idx) => {
                    this.listaEnderecos.push({
                        cep: this.form.enderecos[idx].cep,
                        tipoLogradouro: this.form.enderecos[idx].tipoLogradouro,
                        nomeLogradouro: this.form.enderecos[idx].nomeLogradouro,
                        numero: this.form.enderecos[idx].numero
                    })
                })

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInput
                     */
                    const oInput = this.$refs.enderecos;

                    if (oInput && bFocar) {
                        oInput.querySelector('input').focus();
                    }
                })
            },
            /**
             * Função para selecionar o lote/quadra
             */
             selecionarLoteQuadra(iIndice) {
                if(iIndice == ''){
                    return
                }
                
                this.endereco.loteQuadraIndice = iIndice
                this.endereco.lote = this.endereco.loteQuadras[iIndice].lote
                this.endereco.quadra = this.endereco.loteQuadras[iIndice].quadra

                this.$forceUpdate()
            },
            /**
             * Função para cancelar a edição do lote/quadra
             */
            cancelarEdicaoLoteQuadra(bFocar = true) {
                this.endereco.loteQuadraIndice = null
                this.endereco.quadra = ''
                this.endereco.lote = ''

                this.$forceUpdate()

                this.listaLotesQuadras = []

                /**
                 * Reestrutura os lote/quadra adicionados
                 *
                 * @var {object} aDadosLotesQuadras
                 */
                const aDadosLotesQuadras = this.endereco?.loteQuadras?.map((loteQuadra, idx) => {
                    this.listaLotesQuadras.push({
                        lote: loteQuadra.lote,
                        quadra: loteQuadra.quadra,
                    })

                    return `${ loteQuadra.lote != '' ? `Lote: ${ loteQuadra.lote }` : '' } ${ loteQuadra.lote != '' && loteQuadra.quadra != '' ? ` - ` : ''} ${ loteQuadra.quadra != '' ? `Quadra: ${ loteQuadra.quadra }` : '' }`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectLoteQuadra
                 */
                 const oSelectLoteQuadra = this.$refs.selectLoteQuadra
                
                oSelectLoteQuadra.clearSelectize()
                oSelectLoteQuadra.updateOptions(aDadosLotesQuadras)
                oSelectLoteQuadra.clearSelectize()

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {Object} oInput
                     */
                    const oInput = this.$refs.lote;

                    if (oInput && bFocar) {
                        oInput.querySelector('input').focus();
                    }
                })
            },
            /**
             * Função para definir a inconsistência da parte
             */
            definirInconsistencia(sTipo = 'ato', iDesativar = 0){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                 let oDados = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar
                    sTipo = 'descricaoRegistroAuxiliar'
                }else{
                    oDados = this.ato
                }

                oDados.pessoal.inconsistencia = ''

                if(iDesativar == 1){
                    oDados.pessoal.opcaoInconsistencia = true
                }else if(iDesativar == 2){
                    oDados.pessoal.opcaoInconsistencia = false
                }else{
                    oDados.pessoal.opcaoInconsistencia = !oDados.pessoal.opcaoInconsistencia
                }
            },
            /**
             * Função para definir a inconsistência da parte
             */
            definirInconsistenciaConjuge(sTipo = 'ato', iDesativar = 0){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                 let oDados = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar
                    sTipo = 'descricaoRegistroAuxiliar'
                }else{
                    oDados = this.ato
                }

                oDados.pessoal.conjuge.inconsistencia = ''

                if(iDesativar == 1){
                    oDados.pessoal.conjuge.opcaoInconsistencia = true
                }else if(iDesativar == 2){
                    oDados.pessoal.conjuge.opcaoInconsistencia = false
                }else{
                    oDados.pessoal.conjuge.opcaoInconsistencia = !oDados.pessoal.conjuge.opcaoInconsistencia
                }
            },
            /**
             * Função para adicionar um novo ato
             */
            adicionarAto(bExibirAlerta = true){
                if(this.ato.numeroAto == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o número do ato')
                    }

                    return
                }

                if(this.ato.dataRegistro == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe a data do registro do ato')
                    }

                    return
                }

                if(this.ato.descricao == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o tipo do ato')
                    }

                    return
                }

                if(this.ato.tipo == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o título do ato')
                    }

                    return
                }

                if(this.ato.dataInstrumento == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe a data do instrumento do ato')
                    }

                    return
                }

                if(!this.form.ato){
                    this.form.ato = []
                }
         
                if(this.ato.cancelamento.length > 0){
                    for(const iNumeroAto of this.ato.cancelamento){
                        if(iNumeroAto == ''){
                            continue
                        }
             
                        this.form.ato[this.form.ato.findIndex(ato => ato.numeroAto === iNumeroAto)].onus = '0'
                    }
                }

                if(this.form.ato[this.ato.indice]){
                    this.form.ato[this.ato.indice] = {
                        numeroAto: this.ato.numeroAto,
                        dataRegistro: this.ato.dataRegistro,
                        descricao: this.ato.descricao,
                        tipo: this.ato.tipo,
                        descricaoAdicional: this.ato.descricaoAdicional,
                        dataInstrumento: this.ato.dataInstrumento,
                        tipoMoeda: this.ato.tipoMoeda,
                        valorDeclarado: this.ato.valorDeclarado,
                        valorFiscal: this.ato.valorFiscal,
                        onus: this.ato.onus,
                        tipoTransacao: this.ato.tipoTransacao,
                        cancelamento: this.ato.cancelamento,
                        seloFiscalizacao: this.ato.seloFiscalizacao,
                        pessoal: this.form.ato[this.ato.indice].pessoal ?? []
                    }

                    this.listaAtos[this.ato.indice] = {
                        numeroAto: this.ato.numeroAto,
                        descricao: this.ato.descricao,
                        tipo: this.ato.tipo,
                        descricaoAdicional: this.ato.descricaoAdicional,
                        onus: this.ato.onus
                    }

                    if(bExibirAlerta){
                        toastAlert(`Ato alterado com sucesso`, 'success')
                    }
                }else{
                    this.form.ato.push({
                        numeroAto: this.ato.numeroAto,
                        dataRegistro: this.ato.dataRegistro,
                        descricao: this.ato.descricao,
                        tipo: this.ato.tipo,
                        descricaoAdicional: this.ato.descricaoAdicional,
                        dataInstrumento: this.ato.dataInstrumento,
                        tipoMoeda: this.ato.tipoMoeda,
                        valorDeclarado: this.ato.valorDeclarado,
                        valorFiscal: this.ato.valorFiscal,
                        onus: this.ato.onus,
                        tipoTransacao: this.ato.tipoTransacao,
                        cancelamento: this.ato.cancelamento,
                        seloFiscalizacao: this.ato.seloFiscalizacao,
                        pessoal: []
                    })

                    this.listaAtos.push({
                        numeroAto: this.ato.numeroAto,
                        descricao: this.ato.descricao,
                        tipo: this.ato.tipo,
                        descricaoAdicional: this.ato.descricaoAdicional,
                        onus: this.ato.onus
                    })

                    if(bExibirAlerta){
                        toastAlert(`Ato adicionado com sucesso`, 'success')
                    }
                }

                if(this.ato.onus == 1){
                    this.onusAtivos[this.ato.numeroAto] = `Ato ${this.form.ato[this.ato.indice].numeroAto} ${this.form.ato[this.ato.indice].descricao != '' ? '-' : ''} ${this.form.ato[this.ato.indice].descricao} ${this.tiposAto[this.form.ato[this.ato.indice].tipo] ? ` - ${this.tiposAto[this.form.ato[this.ato.indice].tipo].split(' - ')[1]}` : ''} ${this.form.ato[this.ato.indice].descricaoAdicional ? ` - ${this.form.ato[this.ato.indice].descricaoAdicional}` : ''}`

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectCancelamento
                     */
                    const oSelectCancelamento = this.$refs.selectCancelamento
                    
                    oSelectCancelamento.clearSelectize()
                    oSelectCancelamento.updateOptions(this.onusAtivos)
                    oSelectCancelamento.clearSelectize()
                }

                if(this.ato.tipo == '238'){
                    this.form.ato[this.ato.indice].pessoal.map((pessoal, idx) => {
                        if(pessoal.classificacao == 'Executado' && !this.form.indisponibilidade.filter((indisponibilidade) => indisponibilidade.documento == pessoal.documento && indisponibilidade.ato == `${this.form.ato[this.ato.indice].numeroAto} ${this.form.ato[this.ato.indice].descricao != '' ? '-' : ''} ${this.form.ato[this.ato.indice].descricao}`).length > 0){
                            this.adicionarIndisponibilidade(false, {
                                dataIndisponibilidade: this.form.ato[this.ato.indice].dataRegistro,
                                dataRevogacao: '',
                                documento: pessoal.documento,
                                nome: pessoal.nome,
                                motivo: '',
                                ato: `${this.form.ato[this.ato.indice].numeroAto} ${this.form.ato[this.ato.indice].descricao != '' ? '-' : ''} ${this.form.ato[this.ato.indice].descricao}`
                            })
                        }      
                    })                    
                }

                if(this.ato.cancelamento.length > 0){
                    for(const iIndiceAto of this.ato.cancelamento){
                        if(iIndiceAto == ''){
                            continue
                        }

                        if(this.form.ato[iIndiceAto].tipo == '238'){
                            Object.values(this.form.ato[iIndiceAto].pessoal).forEach((pessoal, idx) => {
                                if(this.form.indisponibilidade.filter((indisponibilidade) => indisponibilidade.documento == pessoal.documento && indisponibilidade.ato == `${this.form.ato[iIndiceAto].numeroAto} ${this.form.ato[iIndiceAto].descricao != '' ? '-' : ''} ${this.form.ato[iIndiceAto].descricao}`).length == 0){
                                    return
                                }

                                this.form.indisponibilidade.filter((indisponibilidade) => indisponibilidade.documento == pessoal.documento && indisponibilidade.ato == `${this.form.ato[iIndiceAto].numeroAto} ${this.form.ato[iIndiceAto].descricao != '' ? '-' : ''} ${this.form.ato[iIndiceAto].descricao}`).forEach((indisponibilidade) => {
                                    indisponibilidade.dataRevogacao = this.ato.dataRegistro
                                })                                
                            })
                        }
                    }
                }

                this.cancelarEdicaoAto()
            },
            /**
             * Função para remover um ato
             */
            removerAto(iIndice, bAbrirModal = false){
                if(bAbrirModal){
                    this.modalRemoverAto = true

                    return
                }
                    
                this.modalRemoverAto = false

                this.form.ato.splice(this.ato.indice, 1)
                this.listaAtos.splice(this.ato.indice, 1)

                toastAlert('Ato removido com sucesso', 'success')

                this.cancelarEdicaoAto(false)
            },
            /**
             * Função para adicionar um novo registro anterior
             */
            adicionarRegistroAnterior(bExibirAlerta = true){
                if(this.registroAnterior.tipoRegistroAnterior == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o tipo do registro anterior')
                    }

                    return
                }

                if(this.registroAnterior.numeroRegistroAnterior == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o número do registro anterior')
                    }

                    return
                }

                if(this.form.registroAnterior[this.registroAnterior.indice]){
                    this.form.registroAnterior[this.registroAnterior.indice] = {
                        ato: this.registroAnterior.ato,
                        numeroRegistroAnterior: this.registroAnterior.numeroRegistroAnterior,
                        tipoRegistroAnterior: this.registroAnterior.tipoRegistroAnterior,
                        cnsRegistroAnterior: this.registroAnterior.cnsRegistroAnterior,
                        dataRegistroAnterior: this.registroAnterior.dataRegistroAnterior,
                        orgaoEmissor: this.registroAnterior.orgaoEmissor,
                        beneficiario: this.registroAnterior.beneficiario
                    }

                    toastAlert(`Registro anterior alterado com sucesso`, 'success')
                }else{
                    this.form.registroAnterior.push({
                        ato: this.registroAnterior.ato,
                        numeroRegistroAnterior: this.registroAnterior.numeroRegistroAnterior,
                        tipoRegistroAnterior: this.registroAnterior.tipoRegistroAnterior,
                        cnsRegistroAnterior: this.registroAnterior.cnsRegistroAnterior,
                        dataRegistroAnterior: this.registroAnterior.dataRegistroAnterior,
                        orgaoEmissor: this.registroAnterior.orgaoEmissor,
                        beneficiario: this.registroAnterior.beneficiario
                    })

                    toastAlert('Registro anterior adicionado com sucesso', 'success')

                    this.listaRegistros.push({
                        tipoRegistroAnterior: this.registroAnterior.tipoRegistroAnterior,
                        ato: this.registroAnterior.ato,
                        numeroRegistroAnterior: this.registroAnterior.numeroRegistroAnterior
                    })
                } 

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.registroAnterior[this.form.registroAnterior.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });

                this.cancelarEdicaoRegistroAnterior(false)
            },
            /**
             * Função para remover um registro anterior
             */
            removerRegistroAnterior(iIndice){ 
                this.form.registroAnterior.splice(this.registroAnterior.indice, 1)
                this.listaRegistros.splice(this.registroAnterior.indice, 1)

                this.loading.registroAnterior = {
                    beneficiario: [
                        {
                            documento: false,
                            nome: false
                        }
                    ]
                }
                
                toastAlert('Registro anterior removido com sucesso', 'success')

                this.cancelarEdicaoRegistroAnterior(false)
            },
            /**
             * Função para adicionar um novo beneficiário do registro anterior
             */
            adicionarBeneficiario(){
                this.registroAnterior.beneficiario.push({
                    documento: '',
                    nome: ''
                })

                this.registroAnterior.loading.push(false)

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.beneficiario[this.registroAnterior.beneficiario.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover um beneficiário do registro anterior
             */
            removerBeneficiario(iIndice){
                this.registroAnterior.loading.splice(iIndice, 1)
                this.registroAnterior.beneficiario.splice(iIndice, 1)
            },
            /**
             * Função para adicionar um novo beneficiário do registro anterior
             */
            adicionarBeneficiarioLivroVinculado(){
                this.form.beneficiariosLivroVinculado.push({
                    naturezaTitulo: '',
                    numero: '',
                    documento: '',
                    nome: ''
                })

                this.loading.beneficiariosLivroVinculado.push({
                    nome: false
                })

                this.validacao.beneficiariosLivroVinculado.push({
                    naturezaTitulo: true,
                    numero: true,
                    documento: true,
                    nome: true
                })

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.beneficiariosLivroVinculado[this.form.beneficiariosLivroVinculado.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover um beneficiário do registro anterior
             */
            removerBeneficiarioLivroVinculado(iIndice){
                this.form.beneficiariosLivroVinculado.splice(iIndice, 1)
            },
            /**
             * Função para adicionar uma nova indisponibilidade
             */
            adicionarIndisponibilidade(bExibirAlerta = true, oIndisponibilidade = null){
                if(this.form.indisponibilidade[this.indisponibilidade.indice] && !oIndisponibilidade){
                    this.form.indisponibilidade[this.indisponibilidade.indice] = {
                        dataIndisponibilidade: this.indisponibilidade.dataIndisponibilidade,
                        dataRevogacao: this.indisponibilidade.dataRevogacao,
                        documento: this.indisponibilidade.documento,
                        nome: this.indisponibilidade.nome,
                        motivo: this.indisponibilidade.motivo,
                        ato: this.indisponibilidade.ato
                    }

                    this.listaIndisponibilidade[this.indisponibilidade.indice] = {
                        ato: this.indisponibilidade.ato,
                        documento: this.indisponibilidade.documento,
                        nome: this.indisponibilidade.nome
                    }

                    if(bExibirAlerta){
                        toastAlert(`Indisponibilidade alterada com sucesso`, 'success')
                    }
                }else{
                    this.form.indisponibilidade.push({
                        dataIndisponibilidade: oIndisponibilidade?.dataIndisponibilidade ?? this.indisponibilidade.dataIndisponibilidade,
                        dataRevogacao: oIndisponibilidade?.dataRevogacao ?? this.indisponibilidade.dataRevogacao,
                        documento: oIndisponibilidade?.documento ?? this.indisponibilidade.documento,
                        nome: oIndisponibilidade?.nome ?? this.indisponibilidade.nome,
                        motivo: oIndisponibilidade?.motivo ?? this.indisponibilidade.motivo,
                        ato: oIndisponibilidade?.ato ?? this.indisponibilidade.ato
                    })

                    if(bExibirAlerta){
                        toastAlert(`Indisponibilidade adicionada com sucesso`, 'success')
                    }
                }
            
                this.cancelarEdicaoIndisponibilidade()

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.indisponibilidade[this.form.indisponibilidade.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover uma indisponibilidade
             */
            removerIndisponibilidade(){
                this.form.indisponibilidade.splice(this.indisponibilidade.indice, 1)
                this.listaIndisponibilidade.splice(this.indisponibilidade.indice, 1)
                
                toastAlert('Indisponibilidade removida com sucesso', 'success')

                this.cancelarEdicaoIndisponibilidade(false)
            },
            /**
             * Função para cancelar a edição da indisponibilidade
             */
            cancelarEdicaoIndisponibilidade() {
                this.indisponibilidade = {
                    indice: this.listaIndisponibilidade.length,
                    edicao: false,
                    loading: false,
                    dataIndisponibilidade: '',
                    dataRevogacao: '',
                    documento: '',
                    nome: '',
                    motivo: '',
                    ato: '',
                    mascara: '###################'
                }

                this.listaIndisponibilidade = []

                Object.keys(this.form.indisponibilidade).map((idx) => {
                    this.listaIndisponibilidade.push({
                        ato: this.form.indisponibilidade[idx].ato ?? '',
                        documento: this.form.indisponibilidade[idx].documento,
                        nome: this.form.indisponibilidade[idx].nome
                    })
                })

                this.indisponibilidade.indice = this.listaIndisponibilidade.length

                this.$forceUpdate()
            
                /**
                 * Reestrutura os registros adicionados
                 *
                 * @var {object} aDadosIndisponibilidade
                 */
                const aDadosIndisponibilidade = Object.entries(this.form.indisponibilidade).filter(([_, valor]) => valor.dataIndisponibilidade !== undefined).map(([chave, indisponibilidadeUnica]) => {
                    return `${indisponibilidadeUnica.ato ?? ''} ${ indisponibilidadeUnica.ato && indisponibilidadeUnica.ato != '' ? `-` : ''} ${ indisponibilidadeUnica.documento != '' ? indisponibilidadeUnica.documento : ''}  ${ indisponibilidadeUnica.nome != '' ? `- ${indisponibilidadeUnica.nome}` : ''}`           
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectIndisponibilidade
                 */
                 const oSelectIndisponibilidade = this.$refs.selectIndisponibilidade
                
                oSelectIndisponibilidade.clearSelectize()
                oSelectIndisponibilidade.updateOptions(aDadosIndisponibilidade)
                oSelectIndisponibilidade.clearSelectize()
            },
            /**
             * Função para selecionar a indisponibilidade
             */
             selecionarIndisponibilidade(iIndice) {
                if(iIndice == ''){
                    return
                }
               
                this.indisponibilidade = {
                    indice: parseInt(iIndice),
                    edicao: true,
                    loading: false,
                    mascara: this.form.indisponibilidade[iIndice].documento.replace(/[^\w\s]/gi, "").length == 11 ? '###.###.###-##' : this.form.indisponibilidade[iIndice].documento.replace(/[^\w\s]/gi, "").length == 14 ? '##.###.###/####-##' : '###################',
                    ato: this.form.indisponibilidade[iIndice].ato ?? '',
                    dataIndisponibilidade: this.form.indisponibilidade[iIndice].dataIndisponibilidade,
                    dataRevogacao: this.form.indisponibilidade[iIndice].dataRevogacao,
                    documento: this.form.indisponibilidade[iIndice].documento,
                    nome: this.form.indisponibilidade[iIndice].nome,
                    motivo: this.form.indisponibilidade[iIndice].motivo
                }

                this.$forceUpdate()
            },
            /**
             * Função para adicionar um novo endereço
             */
            adicionarEndereco(sTipo = ''){
                this.hideAccordion(-1)

                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 * @var object oValidacao
                 * @var string sCampoAuxiliar
                 */
                let oDados = {}
                let oValidacao = {}
                let sCampoAuxiliar = ''

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.form[sTipo].enderecos
                    oValidacao = this.validacao[sTipo][0].enderecos
                    sCampoAuxiliar = 'RegistroAuxiliar'
                }else{
                    oDados = this.form.enderecos
                    oValidacao = this.validacao.enderecos
                }

                oDados.push(
                    {
                        tipoImovel: '',
                        setor: [''],
                        bloco: [''],
                        andar: '',
                        loteamentoCondominio: '',
                        nomeCondominio: '',
                        conjuntoCondominio: '',
                        loteamento: '',
                        torreEdificio: [''],
                        conjuntoEmpreeendimento: '',
                        vagas: '',
                        cep: '',
                        tipoLogradouro: '',
                        nomeLogradouro: '',
                        bairro: '',
                        numero: '',
                        complemento: '',
                        complementoQuadra: '',
                        quadra: '',
                        lote: '',
                        loteQuadras: []
                    }
                )

                oValidacao.push(
                    {
                        cep: true,
                        tipoLogradouro: true,
                        nomeLogradouro: true,
                        bairro: true,
                        numero: true,
                        complemento: true,
                        complementoQuadra: true,
                        loteQuadras: [
                            {
                                quadra: true,
                                lote: true
                            }
                        ]
                    }
                )

                this.loading.enderecos.push({
                    cep: false
                })

                this.resultadosEndereco.push([])

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs[`enderecos${sCampoAuxiliar}`][oDados.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            
            /**
             * Função para adicionar um novo endereço
             */
             adicionarEnderecoL2(bExibirAlerta = true){
                if(this.endereco.nomeLogradouro == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o logradouro')
                    }

                    return
                }

                if(this.endereco.numero == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o número do endereço')
                    }

                    return
                }

                if(this.endereco.tipoLogradouro == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o tipo do logradouro')
                    }

                    return
                }
                
                if(this.endereco.bairro == ''){
                    if(bExibirAlerta){
                        toastAlert('Informe o bairro do endereço')
                    }

                    return
                }

                if(this.endereco.cep != '' && (!/^[0-9]{8}$/.test(this.endereco.cep.replace('-', '')) || !this.ceps.some(endereco => this.endereco.cep.replace('-', '') == endereco.cep.replace('-', '')))){
                    if(bExibirAlerta){
                        toastAlert('CEP inválido')
                    }

                    return
                }

                if(!this.form.enderecos){
                    this.form.enderecos = []
                }

                if(this.form.enderecos[this.endereco.indice]){
                    this.form.enderecos[this.endereco.indice] = {
                        cep: this.endereco.cep,
                        tipoLogradouro: this.endereco.tipoLogradouro,
                        nomeLogradouro: this.endereco.nomeLogradouro,
                        bairro: this.endereco.bairro,
                        numero: this.endereco.numero,
                        complemento: this.endereco.complemento,
                        complementoQuadra: this.endereco.complementoQuadra,
                        loteQuadras: this.endereco.loteQuadras
                    }

                    this.listaEnderecos[this.endereco.indice] = {
                        cep: this.endereco.cep,
                        tipoLogradouro: this.endereco.tipoLogradouro,
                        nomeLogradouro: this.endereco.nomeLogradouro,
                        numero: this.endereco.numero
                    }

                    toastAlert(`Endereço alterado com sucesso`, 'success')
                }else{
                    this.form.enderecos.push({
                        cep: this.endereco.cep,
                        tipoLogradouro: this.endereco.tipoLogradouro,
                        nomeLogradouro: this.endereco.nomeLogradouro,
                        bairro: this.endereco.bairro,
                        numero: this.endereco.numero,
                        complemento: this.endereco.complemento,
                        complementoQuadra: this.endereco.complementoQuadra,
                        loteQuadras: this.endereco.loteQuadras
                    })
                    
                    this.listaEnderecos.push({
                        cep: this.endereco.cep,
                        tipoLogradouro: this.endereco.tipoLogradouro,
                        nomeLogradouro: this.endereco.nomeLogradouro,
                        numero: this.endereco.numero
                    })

                    toastAlert(`Endereço adicionado com sucesso`, 'success')
                }

                this.cancelarEdicaoEndereco()
            },
            /**
             * Função para remover um endereço
             */
            removerEndereco(iIndice, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].enderecos
                }else{
                    oDados = this.form.enderecos
                }

                oDados.splice(iIndice, 1)

                if(sTipo == ''){
                    this.cancelarEdicaoEndereco()
                }
            },
            /**
             * Função para adicionar um novo contribuinte
             */
            adicionarContribuinte(sTipo = '', bExibirAlerta = true){
                if(this.contribuinte == '' && this.form.tipoRegistro != 3){
                    return
                }   

                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].contribuintes
                }else{
                    oDados = this.form.contribuintes
                }

                if(this.indiceContribuinte == null){
                    oDados.push(this.contribuinte)
                }else{
                    oDados[this.indiceContribuinte] = this.contribuinte
                }

                this.contribuinte = ''
                this.indiceContribuinte = null

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.inputContribuintes

                    if (oInput) {
                        setTimeout(() => {
                            if(bExibirAlerta){
                                oInput.$el.querySelector('input').focus();
                            }
                        }, 50)
                    }
                });
            },
            /**
             * Função para remover um contribuinte
             */
            removerContribuinte(iIndice, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].contribuintes
                }else{
                    oDados = this.form.contribuintes
                }

                oDados.splice(iIndice, 1)

                this.contribuinte = ''
                this.indiceContribuinte = null

                setTimeout(() => {
                    if(document.querySelector('.dropdownContribuintes')){
                        document.querySelector('.dropdownContribuintes').style.display = 'block'
                        document.querySelector(`.contribuintes > .control > input`).focus()
                    }
                }, 100)
            },
            /**
             * Função para adicionar um novo setor
             */
            adicionarSetor(sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].setor
                }else{
                    oDados = this.form.setor
                }

                oDados.push('')

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.setores[oDados.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover um setor
             */
            removerSetor(iIndice, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].setor
                }else{
                    oDados = this.form.setor
                }

                oDados.splice(iIndice, 1)
            },
            /**
             * Função para adicionar um novo bloco
             */
            adicionarBloco(sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].bloco
                }else{
                    oDados = this.form.bloco
                }

                oDados.push('')

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.blocos[oDados.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover um bloco
             */
            removerBloco(iIndice, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].bloco
                }else{
                    oDados = this.form.bloco
                }

                oDados.splice(iIndice, 1)
            },
            
            /**
             * Função para adicionar um novo Torre/Edificio
             */
             adicionarTorreEdificio(sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].torreEdificio
                }else{
                    oDados = this.form.torre
                }

                oDados.push('')

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.torreEdificios[oDados.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover uma Torre/Edificio
             */
            removerTorreEdificio(iIndice, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].torreEdificio
                }else{
                    oDados = this.form.torre
                }

                oDados.splice(iIndice, 1)
            },
            /**
             * Função para adicionar um novo objeto de garantia
             */
            adicionarObjetoGarantia(){
                this.form.descricaoRegistroAuxiliar.objetoGarantia.push('')

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.objetosGarantia[this.form.descricaoRegistroAuxiliar.objetoGarantia.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover um objeto de garantia
             */
            removerObjetoGarantia(iIndice){
                this.form.descricaoRegistroAuxiliar.objetoGarantia.splice(iIndice, 1)
            },
            /**
             * Função para adicionar um novo bem penhorado
             */
            adicionarBemPenhorado(){
                this.form.descricaoRegistroAuxiliar.bensPenhorados.push('')

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.bensPenhorados[this.form.descricaoRegistroAuxiliar.bensPenhorados.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover um bem penhorado
             */
            removerBemPenhorado(iIndice){
                this.form.descricaoRegistroAuxiliar.bensPenhorados.splice(iIndice, 1)
            },
            /**
             * Função para adicionar um numero da matricula
             */
            adicionarMatriculas(){
                this.form.numerosMatriculas.push('')

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs.numerosMatriculas[this.form.numerosMatriculas.length - 1];

                    if (oInput) {
                        oInput.querySelector('input').focus();
                    }
                });
            },
            /**
             * Função para remover um numero da matricula
             */
            removerMatriculas(iIndice){
                this.form.numerosMatriculas.splice(iIndice, 1)
            },
            /**
             * Função para adicionar um novo endereço
             */
            adicionarLoteQuadra(iIndice, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 * @var object oValidacao
                 * @var string sCampoAuxiliar
                 */
                let oDados = {}
                let oValidacao = {}
                let sCampoAuxiliar = ''

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.form[sTipo].enderecos[iIndice]
                    sCampoAuxiliar = 'RegistroAuxiliar'
                }else{
                    oDados = this.endereco
                }

                oDados.loteQuadras.push({
                    lote: '',
                    quadra: ''
                })

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    // const oInput = this.$refs[`loteQuadras${sCampoAuxiliar}`][oDados.loteQuadras.length - 1];

                    // if (oInput) {
                    //     oInput.querySelector('input').focus();
                    // }
                });
            },
            /**
             * Função para remover um endereço
             */
            removerLoteQuadra(iIndice, iIndiceLote, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo != ''){
                    oDados = this.form[sTipo].enderecos[iIndice]
                }else{
                    oDados = this.endereco
                }

                oDados.loteQuadras.splice(iIndiceLote, 1)
            },
            /**
             * Função para adicionar um novo lote/quadra
             */
            adicionarLoteQuadraL2(){
                if(this.endereco.lote == '' && this.endereco.quadra == ''){
                    return
                }
                
                if(this.endereco.loteQuadras[this.endereco.loteQuadraIndice]){
                    this.endereco.loteQuadras[this.endereco.loteQuadraIndice] = {
                        lote: this.endereco.lote,
                        quadra: this.endereco.quadra
                    }
                }else{
                    this.endereco.loteQuadras.push({
                        lote: this.endereco.lote,
                        quadra: this.endereco.quadra
                    })
                }

                this.cancelarEdicaoLoteQuadra(true)
            },
            /**
             * Função para remover um lote/quadra
             */
            removerLoteQuadraL2(){
                this.endereco.loteQuadras.splice(this.endereco.loteQuadraIndice, 1)

                this.cancelarEdicaoLoteQuadra(false)
            },
            /**
             * Função para cancelar o registro
             */
            async cancelar(iTipo){
                if(iTipo == 1){
                    this.modalCancelamento = true
                }else{
                    await this.removeItem('form')
                    window.location.reload()
                }
            },
            /**
             * Função para voltar a última página
             */
            voltar(){
                window.history.back()
            },
            /**
             * Função para enviar o registro
             */
            async enviar(iEnviar = 0){
                if(this.matriculaJaCadastrada && this.edicao){
                    toastAlert('<b>Atenção:</b> Matrícula já cadastrada')
                    return
                }

                if(iEnviar == 2){
                    this.modalRascunho = true

                    return
                }else{
                    this.modalRascunho = false
                }

                if(iEnviar != 3){
                    /**
                     * Realiza a validação dos campos
                     *
                     * @var {boolean} bValidarCampos
                     */
                    const bValidarCampos = this.validarCampos()

                    if(!bValidarCampos){
                        return
                    }

                    this.form.rascunho = false
                }else{
                    this.form.rascunho = 'true'
                }

                this.modalConfimacao = false

                if(this.matriculaCadastrada == true && iEnviar == 0 && !this.edicao){
                    this.modalConfimacao = true

                    return
                }

                this.buscarMatricula = false
                this.adicionarLoteQuadraL2()
                this.adicionarEnderecoL2(false)
                this.adicionarRegistroAnterior(false)
                this.adicionarCampo('unidade', false)
                this.adicionarCampo('andar', false)
                this.adicionarCampo('bloco', false)
                this.adicionarCampo('torre', false)
                this.adicionarCampo('setor', false)
                this.adicionarCampo('andarVaga', false)
                this.adicionarCampo('numeroVagas', false)
                this.adicionarContribuinte('', false)
                this.adicionarParteAto(false)
                this.adicionarAto(false)

                for(var i = 0; i <  this.form.beneficiariosLivroVinculado.length; i++){
                    if(
                        this.form.beneficiariosLivroVinculado[i].documento != '' && 
                        !cpf.isValid(this.form.beneficiariosLivroVinculado[i].documento.replace(/[^\w\s]/gi, "")) && 
                        !cnpj.isValid(this.form.beneficiariosLivroVinculado[i].documento.replace(/[^\w\s]/gi, "")) &&
                        (this.form.beneficiariosLivroVinculado[i].documento.replace(/[^\w\s]/gi, "").length == 11 || this.form.beneficiariosLivroVinculado[i].documento.replace(/[^\w\s]/gi, "").length == 14)
                    ){
                        toastAlert('CPF/CNPJ do beneficiário inválido')

                        return
                    }
                }

                if(this.form.loteamento == '' && this.form.empreendimentoLoteamento == 2){
                    toastAlert('Informe o loteamento')
                    return
                }

                this.mensagemCamposVazios = ''

                if(this.form.enderecos.length == 0){
                    this.mensagemCamposVazios += 'Nenhum endereço foi adicionado<br>'
                }

                if(this.form.registroAnterior.length == 0){
                    this.mensagemCamposVazios += 'Nenhum registro anterior foi adicionado<br>'
                }

                if(this.form.ato.length == 0){
                    this.mensagemCamposVazios += 'Nenhum ato foi adicionado<br>'
                }

                if(!this.form.ato.reduce((acc, ato) => acc || ato.pessoal.some(pessoal => pessoal.nome), false)){
                    this.mensagemCamposVazios += 'Nenhuma parte foi adicionada<br>'
                }

                if(this.form.indisponibilidade.length == 0){
                    this.mensagemCamposVazios += 'Nenhuma indisponibilidade foi adicionada<br>'
                }
                
                if(this.mensagemCamposVazios != '' && !this.modalCamposVazios && this.form.rascunho != 'true' && this.form.tipoRegistro != 3){
                    this.modalCamposVazios = true
                    return
                }

                this.modalCamposVazios = false
                // this.carregando = true

                this.form.cns = `${process.env.VUE_APP_CODIGO_CNS}`
                this.form.cnm = this.gerarCNM(`${process.env.VUE_APP_CODIGO_CNS}`, this.form.numeroRegistro, this.form.tipoRegistro == 3 ? 3 : 2)
                this.form.usuario = this.autenticacao.usuario

                this.carregando = true
                this.form.copia = this.copia

                this.form.ato = this.form.ato.filter(atos => 'numeroAto' in atos)
                this.form.descricaoRegistroAuxiliar.averbacoes = this.form.descricaoRegistroAuxiliar.averbacoes.filter(averbacoes => 'numeroAto' in averbacoes)

                if(this.copia){
                    delete this.form._id
                }

                if(this.form.tipoVaga == '' || this.form.tipoVaga == 0){
                    this.form.vagas = ''
                    this.form.naturezaJuridica = ''
                    this.form.numeroVagas = []
                    this.form.andarVaga = []
                }

                /**
                 * Define os atos
                 *
                 * @var {array} aAtos
                 */
                const aAtos = this.form.ato
                
                this.form.ato = []

                await axios.request({
                    method: 'post',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/indicadores`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    data: {
                        dataBase64: Buffer.from(JSON.stringify(this.form)).toString('base64')
                    }
                })
                .then(async (response) => {
                    /**
                     * Salva os atos a cada 100
                     */
                    for (let i = 0; i < aAtos.length; i += 100) {
                        await axios.request({
                            method: 'post',
                            url: `${process.env.VUE_APP_ENDPOINT_API}/v1/atos/${response.data.indicador}?parte=${i}`,
                            headers: { 
                                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                            },
                            data: {
                                dataBase64: Buffer.from(JSON.stringify(aAtos.slice(i, i + 100)).toString('base64'))
                            }
                        })
                    }

                    /**
                     * Define a mensagem do alerta
                     *
                     * @var {string} sMensagem
                     */
                    let sMensagem = 'Registro cadastrado com sucesso'

                    if(iEnviar == 3){
                        sMensagem = 'Rascunho salvo com sucesso'
                    }

                    if((this.edicao && !this.copia) || iEnviar == 1){
                        sMensagem = 'Registro atualizado com sucesso'
         
                        sessionStorage.setItem('numeroRegistro', this.form.numeroRegistro)
                        sessionStorage.setItem('tipoRegistro', this.form.tipoRegistro)
                    }

                    toastAlert(sMensagem, 'success')

                    this.form.ato = aAtos

                    if(this.form.tipoRegistro != 3){
                        await this.setItem('formCopia', this.form)
                    }

                    await this.removeItem('form')

                    setTimeout(() => {
                        if(this.edicao || iEnviar == 1){
                            window.location.href = `/pesquisa`
                        }else{
                            window.location.reload()
                        }
                    }, 250);
                })
                .catch((error) => {
                    /**
                     * Define a mensagem do alerta
                     *
                     * @var {string} sMensagem
                     */
                    let sMensagem = 'Erro ao cadastrar o registro'

                    if(iEnviar == 3){
                        sMensagem = 'Erro ao salvar o rascunho'
                    }

                    if(this.edicao || iEnviar == 1){
                        sMensagem = 'Erro ao atualizar o registro'
                    }

                    this.form.ato = aAtos
                    this.carregando = false
                    this.buscarMatricula = true
                    
                    toastAlert(`${sMensagem}${error.response.data.descricao ? `: ${error.response.data.descricao}` : ''}`)
                });
            },
            /**
             * Função para validar os campos obrigatórios
             */
            validarCampos() {
                this.abasComErros = []

                if(this.camposObrigatoriosInicial == null){
                    this.camposObrigatoriosInicial = this.camposObrigatorios
                }else{
                    this.camposObrigatorios = this.camposObrigatoriosInicial
                }

                for (const campo in this.camposObrigatorios) {
                    if(this.form.tipoRegistro == 3){
                        if(this.camposObrigatorios[campo] != 'Indicador Real' && this.camposObrigatorios[campo] != 'Descrição do Reg. Auxiliar'){
                            continue
                        }
                    }

                    /**
                     * Define o valor dos campos
                     *
                     * @var {string|object|array} mValor
                     */
                    const mValor = this.form[campo];

                    // Verificar se o campo é um objeto aninhado
                    if (Array.isArray(mValor)) {
                        mValor.forEach((item, idx) => {
                            this.validarObjeto(item, campo, idx);
                        });
                    } else if (typeof mValor === 'object') {
                        this.validarObjeto(mValor, campo);
                    } else {
                        this.validacao[campo] = true;
                        
                        // Verificar se o campo está preenchido de acordo com a validação
                        if (mValor === '' || mValor === null) {
                            // Campo inválido
                            this.validacao[campo] = false;
                            this.abasComErros.push(this.camposObrigatorios[campo]);
                        }
                    }
                }

                /**
                 * Define o array com os atos informados
                 *
                 * @var {object} aAtosInformados
                 */
                const aAtosInformados = []

                Object.keys(this.form.ato).map((idx) => {
                    if(this.form.ato[idx].numeroAto && this.form.ato[idx].numeroAto != ''){
                        aAtosInformados.push({
                            ato: this.form.ato[idx].numeroAto,
                            tipo: this.form.ato[idx].descricao
                        })
                    }
                })
                
                for(var i = 0; i < aAtosInformados.length; i++) {
                    /**
                     * Define o contador e a mensagem
                     *
                     * @var {string} sMensagem
                     * @var {int} iContador
                     */
                    let sMensagem = ''
                    let iContador = 0

                    for(var j = 0; j < aAtosInformados.length; j++) {
                        if(i != j && aAtosInformados[i].ato == aAtosInformados[j].ato && aAtosInformados[i].tipo == aAtosInformados[j].tipo){
                            sMensagem = `O ato ${aAtosInformados[i].ato} (${aAtosInformados[i].tipo}) está duplicado`
                        }

                        if(aAtosInformados[j].ato && aAtosInformados[j].ato == i){
                            iContador++
                        }
                    }
                    
                    if(sMensagem != ''){
                        // toastAlert(sMensagem)
                        // return false;
                    }
                    
                    if(iContador == 0){
                        toastAlert(`O ato ${i} não foi informado`)
                        return false;
                    }
                }

                if(this.form.tipoRegistro == 3){
                    if(['2', '3', '10'].includes(this.form.tipoLivro3)){
                        Object.keys(this.form.descricaoRegistroAuxiliar.enderecos).map((idx) => {
                            if(this.form.descricaoRegistroAuxiliar.enderecos[idx].tipoLogradouro == ''){
                                this.validacao.descricaoRegistroAuxiliar[0].enderecos[idx].tipoLogradouro = false
                                this.abasComErros.push('Descrição do Reg. Auxiliar')
                            }
                            if(this.form.descricaoRegistroAuxiliar.enderecos[idx].nomeLogradouro == ''){
                                this.validacao.descricaoRegistroAuxiliar[0].enderecos[idx].nomeLogradouro = false
                                this.abasComErros.push('Descrição do Reg. Auxiliar')
                            }
                            if(this.form.descricaoRegistroAuxiliar.enderecos[idx].numero == ''){
                                this.validacao.descricaoRegistroAuxiliar[0].enderecos[idx].numero = false
                                this.abasComErros.push('Descrição do Reg. Auxiliar')
                            }
                            if(this.form.descricaoRegistroAuxiliar.enderecos[idx].bairro == ''){
                                this.validacao.descricaoRegistroAuxiliar[0].enderecos[idx].bairro = false
                                this.abasComErros.push('Descrição do Reg. Auxiliar')
                            }
                        })
                    }
                }else{
                    Object.keys(this.form.enderecos).map((idx) => {
                        if(this.form.enderecos[idx].tipoLogradouro == ''){
                            this.abasComErros.push('Imóvel')
                        }
                        if(this.form.enderecos[idx].nomeLogradouro == ''){
                            this.abasComErros.push('Imóvel')
                        }
                    })
                }

                /**
                 * Realiza a limpeza de itens repetidos no array
                 *
                 * @var {array} aAbasComErrosUnicas
                 */
                let aAbasComErrosUnicas = [...new Set(this.abasComErros)];

                aAbasComErrosUnicas = aAbasComErrosUnicas.filter(item => typeof item === 'string');

                if (aAbasComErrosUnicas.length === 0) {
                    return true;
                }

                if(aAbasComErrosUnicas.length == 1){
                    toastAlert(`Existem campos obrigatórios na aba <b>${aAbasComErrosUnicas.join(', ')}</b> não preenchidos`);
                }else{
                    toastAlert(`Existem campos obrigatórios nas abas <b>${aAbasComErrosUnicas.join(', ')}</b> não preenchidos`);
                }

                return false;
            },
            /**
             * Função para validar os campos obrigatórios
             */
            validarObjeto(objeto, campo, idx = null) {
                /**
                 * Define os campos obrigatórios
                 *
                 * @var {object} oCamposObrigatorios
                 */
                const oCamposObrigatorios = idx !== null ? this.camposObrigatorios[campo][idx] : this.camposObrigatorios[campo];

                for (const prop in oCamposObrigatorios) {
                    /**
                     * Define o valor dos campos
                     *
                     * @var {string|object|array} mValor
                     */
                    const mValor = idx !== null ? objeto[prop] : this.form[campo][prop];

                    if ((mValor == '') && oCamposObrigatorios[prop]) {
                        if (idx !== null) {
                            if(this.validacao[campo] && this.validacao[campo][idx] && this.validacao[campo][idx][prop]){
                                this.validacao[campo][idx][prop] = false;
                                this.abasComErros.push(this.camposObrigatorios[campo][idx][prop]);
                            }
                        } else {
                            this.validacao[campo][prop] = false;
                            this.abasComErros.push(this.camposObrigatorios[campo][prop]);
                        }
                    } else if (typeof mValor === 'object' && mValor != null) {
                        if (Array.isArray(mValor) || Array.isObject(mValor)) {
                            mValor.forEach((item, indice) => {
                                this.validarObjeto(item, campo, indice);
                            });
                        } else {
                            this.validarObjeto(mValor, campo);
                        }
                    }
                }
            },
            /**
             * Função para ir para a pesquisa
             */
            pesquisa(){
                window.location.href = '/pesquisa'
            },
            /**
             * Função para ir para a exportacao
             */
            exportacao(){
                window.location.href = '/exportacao'
            },
            /**
             * Função para ir para os documentos
             */
            documentos(){
                window.location.href = '/documentos'
            },
            /**
             * Função para ir para os documentos
             */
            enderecos(){
                window.location.href = '/enderecos'
            },
            /**
             * Função para ir para os usuarios
             */
            usuarios(){
                window.location.href = '/usuarios'
            },
            /**
             * Função para ir para as estatisticas
             */
            estatisticas(){
                window.location.href = '/estatisticas'
            },
            /**
             * Função para sair da aplicação
             */
            sair(){
                sessionStorage.removeItem('access_token')

                window.location.href = '/'
            },
            /**
             * Função para abrir o modal de ajuda
             */
            ajuda(){
                this.modalAjuda = true
            },
            /**
             * Função para verificar se a matrícula já está cadastrada
             */
            verificarRegistro(bEdicao = true){       
                if(this.form.numeroRegistro != '' && this.buscarMatricula){
                    this.loading.numeroRegistro = true

                    setTimeout(() => {
                        if (this.loading.numeroRegistro) {
                            this.carregando = true
                        }
                    }, 500)

                    axios.request({
                        method: 'get',
                        url: `${process.env.VUE_APP_ENDPOINT_API}/v1/verificarRegistro/${this.form.numeroRegistro}?tipoRegistro=${this.form.tipoRegistro}`,
                        headers: { 
                            'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                        }
                    })
                    .then(async (response) => {    
                        if(this.edicao && response.data.cns && response.data.numeroRegistro != sessionStorage.getItem('numeroRegistro')){
                            toastAlert('<b>Atenção:</b> Matrícula já cadastrada')
                            this.matriculaJaCadastrada = true
                            this.loading.numeroRegistro = false
                            this.carregando = false

                            return
                        }

                        this.matriculaJaCadastrada = false

                        this.form.cnm = this.gerarCNM(`${process.env.VUE_APP_CODIGO_CNS}`, this.form.numeroRegistro, this.form.tipoRegistro == 3 ? 3 : 2)
                        
                        if(response.data.cns){
                            this.matriculaCadastrada = true
                     
                            if(this.copia){
                                response.data.numeroRegistro = ''
                                response.data.cnm = ''
                                response.data.letraRegistro = ''
                                response.data.exportacaoONR = 0
                                response.data.unidade = []
                            }

                            response.data.ato = []
                            response.data.quantidadeAtos = response.data.quantidadeAtos ?? 0

                            for(var iPagina = 0; iPagina < Math.ceil(response.data.quantidadeAtos/100); iPagina++){
                                await axios.request({
                                    method: 'get',
                                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/atos/${response.data._id}?pagina=${iPagina}&registrosPorPagina=100`,
                                    headers: { 
                                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                                    }
                                })
                                .then((responseAto) => { 
                                    response.data.ato = [...response.data.ato , ...responseAto.data.dados]
                                })
                            }

                            if(response.data.ato.length > 0){
                                response.data.ato.sort((a, b) => a.numeroAto - b.numeroAto)
                            }
                     
                            this.validacao = this.substituirValores(response.data)
                            this.$set(this, 'form', JSON.parse(JSON.stringify(response.data)))

                            this.validacao.descricaoRegistroAuxiliar[0] = {
                                numeroAto: true,
                                dataRegistro: true,
                                tipoAto: true,
                                tipoRegistro: true,
                                dataInstrumento: true,
                                objetoGarantia: true,
                                dataVencimento: true,
                                tipoMoeda: true,
                                valor: true,
                                cidade: true,
                                uf: true,
                                subdistrito: true,
                                denominacaoEmpreendimento: true,
                                valorAtribuido: true,
                                dataCasamento: true,
                                tabelionato: true,
                                livro: true,
                                folha: true,
                                regimeBens: true,
                                pessoal: [],
                                averbacoes: [],
                                enderecos: [
                                    {
                                        cep: true,
                                        tipoLogradouro: true,
                                        nomeLogradouro: true,
                                        bairro: true,
                                        numero: true,
                                        complemento: true,
                                        complementoQuadra: true,
                                        loteQuadras: [
                                            {
                                                quadra: true,
                                                lote: true
                                            }
                                        ]
                                    }
                                ]
                            }

                            if(!this.form.descricaoRegistroAuxiliar.pessoal){
                                this.form.descricaoRegistroAuxiliar.pessoal = []
                            }

                            if(this.form.descricaoRegistroAuxiliar.enderecos){
                                Object.keys(this.form.descricaoRegistroAuxiliar.enderecos).map((indice) => {
                                    this.validacao.descricaoRegistroAuxiliar[0].enderecos[indice] = {
                                        cep: true,
                                        tipoLogradouro: true,
                                        nomeLogradouro: true,
                                        bairro: true,
                                        complemento: true,
                                        complementoQuadra: true,
                                        numero: true,
                                        loteQuadras: []
                                    }

                                    this.loading.enderecos[indice] = {
                                        cep: false
                                    }

                                    if(this.form.descricaoRegistroAuxiliar?.enderecos[indice]?.loteQuadras){
                                        Object.keys(this.form.descricaoRegistroAuxiliar.enderecos[indice].loteQuadras).map((indiceLoteQuadras) => {
                                            if(this.validacao?.descricaoRegistroAuxiliar[0]?.enderecos[indice]){
                                                this.validacao.descricaoRegistroAuxiliar[0].enderecos[indice].loteQuadras[indiceLoteQuadras] = {
                                                    lote: true,
                                                    quadra: true
                                                }
                                            }
                                        })
                                    }
                                })
                            }else{
                                this.form.descricaoRegistroAuxiliar.enderecos = []
                            }

                            this.listaAverbacoes = []

                            if(this.form.descricaoRegistroAuxiliar.averbacoes){
                                Object.keys(this.form.descricaoRegistroAuxiliar.averbacoes).map((idx) => {     
                                    if(this.form.descricaoRegistroAuxiliar.averbacoes[idx].numeroAto && this.form.descricaoRegistroAuxiliar.averbacoes[idx].numeroAto != ''){
                                        this.listaAverbacoes.push({
                                            numeroAto: this.form.descricaoRegistroAuxiliar.averbacoes[idx].numeroAto,
                                            descricaoAto: this.form.descricaoRegistroAuxiliar.averbacoes[idx].descricaoAto
                                        })
                                    }

                                    if(!this.form.descricaoRegistroAuxiliar.averbacoes[idx].pessoal){
                                        this.form.descricaoRegistroAuxiliar.averbacoes[idx].pessoal = []
                                    }
                                })
                            }else{
                                this.form.descricaoRegistroAuxiliar.averbacoes = []
                            }

                            Object.keys(this.form.beneficiariosLivroVinculado).map((idx) => {
                                this.validacao.beneficiariosLivroVinculado[idx] = {
                                    naturezaTitulo: true,
                                    numero: true,
                                    documento: true,
                                    nome: true
                                }

                                this.loading.beneficiariosLivroVinculado[idx] = {
                                    nome: false
                                }

                                this.mascaraBeneficiarioLivroVinculado[idx] = ''
                            })
                        
                            this.cancelarEdicaoParteRA(false)
                            this.cancelarEdicaoAto(false)
                            this.cancelarEdicaoParteAto(false)
                            this.cancelarEdicaoEndereco(false)
                            this.cancelarEdicaoAverbacao(false)
                            this.cancelarEdicaoParteAverbacao(false)
                            this.cancelarEdicaoRegistroAnterior(false)
                            this.cancelarEdicaoIndisponibilidade(false)
 
                            this.endereco.indice = this.listaEnderecos.length
                            this.averbacao.indice = this.listaAverbacoes.length
                            this.descricaoRegistroAuxiliar.pessoal.indice = this.form.descricaoRegistroAuxiliar?.pessoal ? this.form.descricaoRegistroAuxiliar.pessoal.length : 0
                            this.ato.indice = this.listaAtos.length
                            this.registroAnterior.indice = this.listaRegistros.length
                            this.indisponibilidade.indice = this.listaIndisponibilidade.length

                            if(!this.form.processo){
                                this.form.processo = {
                                    numeroProcesso: '',
                                    dataEncerramento: '',
                                    numeroInscricao: '',
                                    livro: ''
                                }
                            }

                            /**
                             * Define o select pela referencia
                             *
                             * @var {object} oSelect
                             */
                            const oSelect = this.$refs.select;

                            for (const sChave in oSelect) {
                                if (oSelect.hasOwnProperty(sChave)) {
                                    /**
                                     * Define o selectize e o valor
                                     *
                                     * @var {object} oSelectize
                                     * @var {string} sValor
                                     */
                                    const oSelectize = oSelect[sChave][0].selectize;
                                    const sValor = this.form[sChave];

                                    oSelectize.setValue(sValor);
                                }
                            }
                        }

                        if(response.data.inativo){
                            toastAlert('<b>Atenção:</b> Matrícula inativada')
                        }

                        this.loading.numeroRegistro = false
                        this.carregando = false
                        this.rerenderKey = this.rerenderKey + 1

                        this.$forceUpdate()

                        if(bEdicao){
                            this.$nextTick(() => {
                                document.querySelector('.letraComplementar > input').focus()
                            })
                        }
                    })
                    .catch((error) => {
                        this.loading.numeroRegistro = false
                        this.carregando = false

                        this.form.cnm = this.gerarCNM(`${process.env.VUE_APP_CODIGO_CNS}`, this.form.numeroRegistro, this.form.tipoRegistro == 3 ? 3 : 2)
                        
                        if(bEdicao && !this.edicao){
                            this.$nextTick(() => {
                                document.querySelector('.letraComplementar > input').focus()
                            })

                            toastAlert('<b>Atenção:</b> Matrícula com cadastro em andamento')
                        }
                    });
                }    
                
                this.buscarMatricula = true                
            },
            /**
             * Função para substituir os valores do objeto por true
             */
            substituirValores(oObjeto) {
                /**
                 * Define o novo objeto
                 *
                 * @var {object} oNovoObjeto
                 */
                const oNovoObjeto = {};

                for (const mChave in oObjeto) {
                    if (Array.isArray(oObjeto[mChave])) {
                        oNovoObjeto[mChave] = oObjeto[mChave].map((item) => this.substituirValores(item));
                    } else if (typeof oObjeto[mChave] === 'object' && oObjeto[mChave] !== null) {
                        oNovoObjeto[mChave] = this.substituirValores(oObjeto[mChave]);
                    } else {
                        oNovoObjeto[mChave] = true;
                    }
                }
                return oNovoObjeto;
            },
            /**
             * Função para carregar os dados do sessionStorage
             */
            async carregarDados(bCopiar = false){
                this.carregando = true
                
                this.modalCarregarForm = false
                this.modalCarregarCopia = false

                if(bCopiar){
                    /**
                     * Define o form da cópia armazenado
                     * 
                     * @var object oForm
                     */
                    const oForm = await this.getItem('formCopia')
                    
                    this.copia = true
                    oForm.numeroRegistro = ''
                    oForm.letraRegistro = ''
                    oForm.exportacaoONR = 0
                    oForm.unidade = []
                
                    this.$set(this, 'form', oForm)
                    
                    // /**
                    //  * Define o select pela referencia
                    //  *
                    //  * @var {object} oTipoVaga
                    //  */
                    // const oTipoVaga = this.$refs.tipoVaga
                    // oTipoVaga.clearSelectize()
                    
                    // /**
                    //  * Define o select pela referencia
                    //  *
                    //  * @var {object} oNaturezaJuridica
                    //  */
                    // const oNaturezaJuridica = this.$refs.naturezaJuridica
                    // oNaturezaJuridica.clearSelectize()
                }else{
                    /**
                     * Define o form armazenado
                     * 
                     * @var object oForm
                     */
                    const oForm = await this.getItem('form')
                   
                    this.$set(this, 'form', oForm)
                }

                this.validacao.descricaoRegistroAuxiliar[0] = {
                    numeroAto: true,
                    dataRegistro: true,
                    tipoAto: true,
                    tipoRegistro: true,
                    dataInstrumento: true,
                    objetoGarantia: true,
                    dataVencimento: true,
                    tipoMoeda: true,
                    valor: true,
                    cidade: true,
                    uf: true,
                    subdistrito: true,
                    denominacaoEmpreendimento: true,
                    valorAtribuido: true,
                    dataCasamento: true,
                    tabelionato: true,
                    livro: true,
                    folha: true,
                    regimeBens: true,
                    pessoal: [],
                    averbacoes: [],
                    enderecos: [
                        {
                            cep: true,
                            tipoLogradouro: true,
                            nomeLogradouro: true,
                            bairro: true,
                            numero: true,
                            complemento: true,
                            complementoQuadra: true,
                            loteQuadras: [
                                {
                                    quadra: true,
                                    lote: true
                                }
                            ]
                        }
                    ]
                }

                if(this.form.descricaoRegistroAuxiliar.enderecos){
                    Object.keys(this.form.descricaoRegistroAuxiliar.enderecos).map((indice) => {
                        this.validacao.descricaoRegistroAuxiliar[0].enderecos[indice] = {
                            cep: true,
                            tipoLogradouro: true,
                            nomeLogradouro: true,
                            bairro: true,
                            complemento: true,
                            complementoQuadra: true,
                            numero: true,
                            loteQuadras: []
                        }

                        this.loading.enderecos[indice] = {
                            cep: false
                        }

                        if(this.form.descricaoRegistroAuxiliar?.enderecos[indice]?.loteQuadras){
                            Object.keys(this.form.descricaoRegistroAuxiliar.enderecos[indice].loteQuadras).map((indiceLoteQuadras) => {
                                if(this.validacao?.descricaoRegistroAuxiliar[0]?.enderecos[indice]){
                                    this.validacao.descricaoRegistroAuxiliar[0].enderecos[indice].loteQuadras[indiceLoteQuadras] = {
                                        lote: true,
                                        quadra: true
                                    }
                                }
                            })
                        }
                    })
                }

                if(this.form.descricaoRegistroAuxiliar.averbacoes){
                    Object.keys(this.form.descricaoRegistroAuxiliar.averbacoes).map((idx) => {     
                        this.listaAverbacoes.push({
                            numeroAto: this.form.descricaoRegistroAuxiliar.averbacoes[idx].numeroAto,
                            descricaoAto: this.form.descricaoRegistroAuxiliar.averbacoes[idx].descricaoAto
                        })
                        
                        if(!this.form.descricaoRegistroAuxiliar.averbacoes[idx].pessoal){
                            this.form.descricaoRegistroAuxiliar.averbacoes[idx].pessoal = []
                        }
                    })
                }else{
                    this.form.descricaoRegistroAuxiliar.averbacoes = []
                }

                Object.keys(this.form.beneficiariosLivroVinculado).map((idx) => {
                    this.validacao.beneficiariosLivroVinculado[idx] = {
                        naturezaTitulo: true,
                        numero: true,
                        documento: true,
                        nome: true
                    }

                    this.loading.beneficiariosLivroVinculado[idx] = {
                        nome: false
                    }

                    this.mascaraBeneficiarioLivroVinculado[idx] = ''
                })

                Object.keys(this.form.descricaoRegistroAuxiliar).map((idx) => {
                    this.validacao.descricaoRegistroAuxiliar[idx] = {
                        numeroAto: true,
                        dataRegistro: true,
                        tipoAto: true,
                        tipoRegistro: true,
                        dataInstrumento: true,
                        objetoGarantia: true,
                        dataVencimento: true,
                        tipoMoeda: true,
                        valor: true,
                        cidade: true,
                        uf: true,
                        subdistrito: true,
                        denominacaoEmpreendimento: true,
                        valorAtribuido: true,
                        dataCasamento: true,
                        tabelionato: true,
                        livro: true,
                        folha: true,
                        regimeBens: true,
                        pessoal: [],
                        averbacoes: [],
                        enderecos: [
                            {
                                cep: true,
                                tipoLogradouro: true,
                                nomeLogradouro: true,
                                bairro: true,
                                numero: true,
                                complemento: true,
                                complementoQuadra: true,
                                loteQuadras: [
                                    {
                                        quadra: true,
                                        lote: true
                                    }
                                ]
                            }
                        ]
                    }

                    Object.keys(this.form.enderecos).map((indice) => {
                        this.validacao.descricaoRegistroAuxiliar[idx].enderecos[indice] = {
                            cep: true,
                            tipoLogradouro: true,
                            nomeLogradouro: true,
                            bairro: true,
                            complemento: true,
                            complementoQuadra: true,
                            numero: true,
                            loteQuadras: []
                        }

                        this.loading.enderecos[indice] = {
                            cep: false
                        }

                        if(this.form.descricaoRegistroAuxiliar?.enderecos[indice]?.loteQuadras){
                            Object.keys(this.form.descricaoRegistroAuxiliar.enderecos[indice].loteQuadras).map((indiceLoteQuadras) => {
                                if(this.validacao?.descricaoRegistroAuxiliar[idx]?.enderecos[indice]){
                                    this.validacao.descricaoRegistroAuxiliar[idx].enderecos[indice].loteQuadras[indiceLoteQuadras] = {
                                        lote: true,
                                        quadra: true
                                    }
                                }
                            })
                        }
                    })
                })

                this.cancelarEdicaoParteRA(false)
                this.cancelarEdicaoAto(false)
                this.cancelarEdicaoParteAto(false)
                this.cancelarEdicaoAverbacao(false)
                this.cancelarEdicaoEndereco(false)
                this.cancelarEdicaoParteAverbacao(false)
                this.cancelarEdicaoRegistroAnterior(false)
                this.cancelarEdicaoIndisponibilidade(false)

                this.endereco.indice = this.listaEnderecos.length
                this.ato.indice = this.listaAtos.length
                this.averbacao.indice = this.listaAverbacoes.length
                this.descricaoRegistroAuxiliar.pessoal.indice = this.form.descricaoRegistroAuxiliar.pessoal.length
                this.registroAnterior.indice = this.listaRegistros.length
                this.indisponibilidade.indice = this.listaIndisponibilidade.length
                
                this.modalCarregarForm = false
                this.modalCarregarCopia = false
                this.carregando = false
            },
            /**
             * Função para não carregar os dados do armazenamento
             */
            async naoCarregarDados(){
                await this.removeItem('form')
                this.modalCarregarForm = false
                this.modalCarregarCopia = false
            },
            /**
             * Função para gerar o CNM
             */
            gerarCNM(cns, number, book = 2){
                const register = number.padStart(7, '0')
                const preCnm = cns + book + register + '00'
                const xCnmTemp = bigInt(preCnm).mod(97)
        
                const xDv = 98 - xCnmTemp.toString()
                return cns + "." + book + "." + register + "-" + xDv.toString().padStart(2, '0')
            },
            /**
             * Função para buscar o documento digitado
             */
            async buscarDocumento(sDocumento, sTipo = 'ato', bConjuge = false){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar
                    sTipo = 'descricaoRegistroAuxiliar'
                }else if(sTipo == 'indisponibilidade'){
                    oDados = this.indisponibilidade
                    sTipo = 'indisponibilidade'

                    oDados.tipoDocumento = 'CPF'
                }else{
                    oDados = this.ato
                }

                if(bConjuge){
                    oDados = oDados.pessoal.conjuge
                }else{
                    if(sTipo != 'indisponibilidade'){
                        oDados = oDados.pessoal
                    }
                }

                // Define a máscara com base no comprimento do documento digitado
                if(oDados.tipoDocumento == 'CPF' && sDocumento.replace(/[^\w\s]/gi, "").length == 11){
                    oDados.mascara = '###.###.###-##'
                }else if(oDados.tipoDocumento == 'CNPJ' && sDocumento.replace(/[^\w\s]/gi, "").length == 14){
                    oDados.mascara = '##.###.###/####-##'
                }else if(oDados.tipoDocumento == 'RG' || oDados.tipoDocumento == 'OUTROS'){
                    oDados.mascara = 'XXXXXXXXXXXXXXXXXXXXXXXXX'
                }else{
                    oDados.mascara = '###################'
                }
                
                if((oDados.tipoDocumento == 'CPF' || oDados.tipoDocumento == 'CNPJ') && (sDocumento.replace(/[^\w\s]/gi, "").length == 11 || sDocumento.replace(/[^\w\s]/gi, "").length == 14)){
                    oDados.loading = true

                    /**
                     * Consulta o documento
                     *
                     * @var {Object} oDadosDocumento
                     */
                    const oDadosDocumento = await this.consultarDocumento(sDocumento.replace(/[^\w\s]/gi, ""))
                    
                    if(oDadosDocumento != null){
                        oDados.nome = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial
                        this.documentosBuscados[sDocumento.replace(/[^\w\s]/gi, "")] = oDados.nome
                    }else{
                        if(sTipo != 'indisponibilidade'){
                            oDados.nome = ''
                        }
                    }

                    oDados.loading = false
                }
            },
            /**
             * Função para buscar o documento do conjuge digitado
             */
            async buscarDocumentoConjuge(sDocumento, sTipo = 'ato', bConjuge = false){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 * @var object oForm
                 */
                let oDados = {}
                let oForm = {}

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.averbacao
                    oForm = this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]
                }else if(sTipo == 'registroAuxiliar'){
                    oDados = this.descricaoRegistroAuxiliar
                    sTipo = 'descricaoRegistroAuxiliar'
                    oForm = this.form.descricaoRegistroAuxiliar[this.descricaoRegistroAuxiliar.indice]
                }else{
                    oDados = this.ato
                    oForm = this.form.ato[this.ato.indice]
                }

                if(bConjuge){
                    oDados = oDados.pessoal.conjuge
                }else{
                    oDados = oDados.pessoal
                }

                // Define a máscara com base no comprimento do documento digitado
                if(oDados.tipoDocumentoConjuge == 'CPF' && sDocumento.replace(/[^\w\s]/gi, "").length == 11){
                    oDados.mascaraConjuge = '###.###.###-##'
                }else if(oDados.tipoDocumento == 'RG' || oDados.tipoDocumento == 'OUTROS'){
                    oDados.mascara = 'XXXXXXXXXXXXXXXXXXXXXXXXX'
                }else{
                    oDados.mascaraConjuge = '###################'
                }

                if(sDocumento.replace(/[^\w\s]/gi, "").length == 11 && oDados.tipoDocumentoConjuge == 'CPF'){
                    oDados.loadingConjuge = true

                    /**
                     * Consulta o documento
                     *
                     * @var {Object} oDadosDocumento
                     */
                    const oDadosDocumento = await this.consultarDocumento(sDocumento.replace(/[^\w\s]/gi, ""))
                    
                    if(oDadosDocumento != null){
                        oDados.nomeConjuge = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial

                        if(!bConjuge){
                            if(sTipo == 'descricaoRegistroAuxiliar'){
                                // this.adicionarConjugeAverbacao()
                            }else if(sTipo == 'registroAuxiliar'){
                                // this.adicionarConjugeRA()
                            }else{
                                this.adicionarConjugeAto()
                            }
                        }
                    }else{
                        oDados.nomeConjuge = ''
                    }

                    oDados.loadingConjuge = false

                    if(oDados.conjuge){
                        return
                    }
                    
                    if(oForm?.pessoal && oForm.pessoal.filter(obj => oDados.documentoConjuge == obj.documento).length > 0){
                        return
                    }
                }
            },
            /**
             * Função para buscar o documento digitado
             */
            async buscarDocumentoBeneficiario(sDocumento, iIndice){                
                if(/\./.test(sDocumento) && (sDocumento.replace(/[^\w\s]/gi, "").length == 11 || sDocumento.replace(/[^\w\s]/gi, "").length == 14)){
                    this.registroAnterior.loading[iIndice] = true

                    await this.$forceUpdate()
                    
                    /**
                     * Consulta o documento
                     *
                     * @var {Object} oDadosDocumento
                     */
                    const oDadosDocumento = await this.consultarDocumento(sDocumento.replace(/[^\w\s]/gi, ""))
                    
                    if(oDadosDocumento != null){
                        this.registroAnterior.beneficiario[iIndice].nome = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial
                        this.documentosBuscados[sDocumento.replace(/[^\w\s]/gi, "")] = this.registroAnterior.beneficiario[iIndice].nome
                    }else{
                        this.registroAnterior.beneficiario[iIndice].nome = ''
                    }

                    this.registroAnterior.loading[iIndice] = false
                }
            },
            /**
             * Função para buscar o documento digitado
             */
            async buscarDocumentoBeneficiarioL2(sDocumento, iIndice){   
                if(/\./.test(sDocumento) && (sDocumento.replace(/[^\w\s]/gi, "").length == 11 || sDocumento.replace(/[^\w\s]/gi, "").length == 14)){
                    this.loading.beneficiariosLivroVinculado[iIndice].nome = true

                    await this.$forceUpdate()

                    /**
                     * Consulta o documento
                     *
                     * @var {Object} oDadosDocumento
                     */
                    const oDadosDocumento = await this.consultarDocumento(sDocumento.replace(/[^\w\s]/gi, ""))

                    if(oDadosDocumento != null){
                        this.form.beneficiariosLivroVinculado[iIndice].nome = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial
                        this.documentosBuscados[sDocumento.replace(/[^\w\s]/gi, "")] = this.form.beneficiariosLivroVinculado[iIndice].nome
                    }else{
                        this.form.beneficiariosLivroVinculado[iIndice].nome = ''
                    }

                    this.loading.beneficiariosLivroVinculado[iIndice].nome = false
                }
            },
            /**
             * Função para adicionar o conjuge manualmente
             */
            adicionarConjugeRA(){   
                if(!this.form.descricaoRegistroAuxiliar?.pessoal){
                    this.form.descricaoRegistroAuxiliar.pessoal = []
                }

                this.form.descricaoRegistroAuxiliar.pessoal.push({
                    classificacao: this.descricaoRegistroAuxiliar.pessoal.classificacao, 
                    nacionalidade: this.descricaoRegistroAuxiliar.pessoal.nacionalidade,
                    uniaoEstavel: this.descricaoRegistroAuxiliar.pessoal.uniaoEstavel, 
                    inconsistencia: '',
                    regimeBens: this.descricaoRegistroAuxiliar.pessoal.regimeBens, 
                    estadoCivil: this.tiposEstadosCivilOposto[this.descricaoRegistroAuxiliar.pessoal.estadoCivil], 
                    tipoDocumento: this.descricaoRegistroAuxiliar.pessoal.tipoDocumentoConjuge, 
                    documento: this.descricaoRegistroAuxiliar.pessoal.documentoConjuge, 
                    nome: this.descricaoRegistroAuxiliar.pessoal.nomeConjuge, 
                    tipoDocumentoConjuge: this.descricaoRegistroAuxiliar.pessoal.tipoDocumento, 
                    documentoConjuge: this.descricaoRegistroAuxiliar.pessoal.documento, 
                    nomeConjuge: this.descricaoRegistroAuxiliar.pessoal.nome,
                    conjuge: true
                }) 

                if(!this.descricaoRegistroAuxiliar.pessoal.edicao){
                    this.descricaoRegistroAuxiliar.pessoal.indice = parseInt(this.descricaoRegistroAuxiliar.pessoal.indice) + parseInt(1)
                }

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosPartesAv
                 */
                 const aDadosPartesAv = this.form.descricaoRegistroAuxiliar.pessoal.map((pessoal, idx) => {
                    return `${idx+1} - ${pessoal.classificacao} ${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelect
                 */
                const oSelect = this.$refs.selectAvParteRA
                
                oSelect.clearSelectize()
                oSelect.updateOptions(aDadosPartesAv)
                oSelect.clearSelectize()

                toastAlert('Cônjuge adicionado como parte com sucesso', 'success')
            },
            /**
             * Função para adicionar o conjuge manualmente
             */
            adicionarConjugeAverbacao(){        
                if(!this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal){
                    this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice] = {
                        pessoal: []
                    }
                }

                this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice].pessoal.push({
                    classificacao: this.averbacao.pessoal.classificacao, 
                    nacionalidade: this.averbacao.pessoal.nacionalidade,
                    uniaoEstavel: this.averbacao.pessoal.uniaoEstavel, 
                    inconsistencia: '',
                    regimeBens: this.averbacao.pessoal.regimeBens, 
                    estadoCivil: this.tiposEstadosCivilOposto[this.averbacao.pessoal.estadoCivil], 
                    tipoDocumento: this.averbacao.pessoal.tipoDocumentoConjuge, 
                    documento: this.averbacao.pessoal.documentoConjuge, 
                    nome: this.averbacao.pessoal.nomeConjuge, 
                    tipoDocumentoConjuge: this.averbacao.pessoal.tipoDocumento, 
                    documentoConjuge: this.averbacao.pessoal.documento, 
                    nomeConjuge: this.averbacao.pessoal.nome,
                    conjuge: true
                }) 

                if(!this.averbacao.pessoal.edicao){
                    this.averbacao.pessoal.indice = parseInt(this.averbacao.pessoal.indice) + parseInt(1)
                }

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosPartesAv
                 */
                 const aDadosPartesAv = this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]?.pessoal.map((pessoal, idx) => {
                    return `${idx+1} - ${pessoal.classificacao} ${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelect
                 */
                const oSelect = this.$refs.selectAvParte
                
                oSelect.clearSelectize()
                oSelect.updateOptions(aDadosPartesAv)
                oSelect.clearSelectize()

                toastAlert('Cônjuge adicionado como parte com sucesso', 'success')
            },
            /**
             * Função para adicionar o conjuge manualmente
             */
            adicionarConjugeAto(){
                if(this.ato.pessoal.edicao){
                    return
                } 

                this.ato.pessoal.conjuge = {
                    classificacao: this.ato.pessoal.classificacao, 
                    nacionalidade: this.ato.pessoal.nacionalidade,
                    fracao: '',
                    valorFracional: '',
                    uniaoEstavel: this.ato.pessoal.uniaoEstavel, 
                    inconsistencia: '',
                    regimeBens: this.ato.pessoal.regimeBens, 
                    estadoCivil: this.tiposEstadosCivilOposto[this.ato.pessoal.estadoCivil], 
                    tipoDocumento: this.ato.pessoal.tipoDocumentoConjuge, 
                    documento: this.ato.pessoal.documentoConjuge, 
                    nome: this.ato.pessoal.nomeConjuge, 
                    tipoDocumentoConjuge: this.ato.pessoal.tipoDocumento, 
                    documentoConjuge: this.ato.pessoal.documento, 
                    nomeConjuge: this.ato.pessoal.nome,
                    conjuge: true,
                    nomes: [],
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false
                }
            },
            /**
             * Função para consultar os dados do documento
             */
            async consultarDocumento(sValor){
                if(!cpf.isValid(sValor) && !cnpj.isValid(sValor)){
                    return null
                }

                if(this.documentosBuscados[sValor]){
                    return {
                        nome: this.documentosBuscados[sValor],
                        nomeEmpresarial: this.documentosBuscados[sValor]
                    }
                }

                return await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_CONSULTA_DOCUMENTO}/v1/consultar/${sValor}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(response.data.ni){
                        return response.data
                    }   

                    return null
                })
                .catch((error) => {
                    return null
                })                   
            },
            /**
             * Função para pegar o CEP informado e buscar na API
             */
            async buscarCep(sCep){
                if(sCep.replace(/[^\w\s]/gi, "").length == 8){
                    this.endereco.loading = true
                    
                    /**
                     * Consulta o CEP
                     *
                     * @var {Object} oDadosCep
                     */
                    let oDadosCep = this.ceps.filter(cep => sCep.replace(/[^\w\s]/gi, "") == cep.cep)[0]

                    if(oDadosCep == null){
                        oDadosCep = await this.consultarCep(sCep.replace(/[^\w\s]/gi, ""))
                    }

                    if(oDadosCep != null){
                        this.endereco.nomeLogradouro = oDadosCep.logradouro
                        this.endereco.bairro = oDadosCep.bairro

                        this.gravarConsultaCep(sCep.replace(/[^\w\s]/gi, ""))

                        if(oDadosCep.logradouro){
                            for (const iTipoLogradouro in this.tiposLogradouro) {
                                if (this.tiposLogradouro[iTipoLogradouro].split(" - ")[1] == oDadosCep.logradouro.split(" ")[0]) {
                                    this.endereco.tipoLogradouro = iTipoLogradouro
                                    this.endereco.nomeLogradouro = oDadosCep.logradouro.replace(' s/n', '').split(",")[0].replace(new RegExp(`\\b${oDadosCep.logradouro.split(" ")[0]}\\b`, 'gi'), '').trim()
                                }
                            }
                        }else{
                            toastAlert('O CEP encontrado não possui todos os dados do endereço', 'warning')
                        }

                        // document.querySelector('.campoNumero > .control > input').focus()
                    }else{
                        this.endereco.nomeLogradouro = ''
                        this.endereco.bairro = ''
                    }

                    this.endereco.loading = false
                }
            },
            /**
             * Função para pegar o CEP informado e buscar na API
             */
            async buscarCepRegistroAuxiliar(sCep, iIndice){
                if(sCep.replace(/[^\w\s]/gi, "").length == 8){
                    this.loading.enderecos[iIndice].cep = true

                    /**
                     * Consulta o CEP
                     *
                     * @var {Object} oDadosCep
                     */
                    let oDadosCep = this.ceps.filter(cep => sCep.replace(/[^\w\s]/gi, "") == cep.cep)[0]

                    if(oDadosCep == null){
                        oDadosCep = await this.consultarCep(sCep.replace(/[^\w\s]/gi, ""))
                    }

                    this.gravarConsultaCep(sCep.replace(/[^\w\s]/gi, ""))

                    if(oDadosCep != null){
                        this.form.descricaoRegistroAuxiliar.enderecos[iIndice].nomeLogradouro = oDadosCep.logradouro
                        this.form.descricaoRegistroAuxiliar.enderecos[iIndice].bairro = oDadosCep.bairro

                        for (const iTipoLogradouro in this.tiposLogradouro) {
                            if (this.tiposLogradouro[iTipoLogradouro].split(" - ")[1] == oDadosCep.logradouro.split(" ")[0]) {
                                this.form.descricaoRegistroAuxiliar.enderecos[iIndice].tipoLogradouro = iTipoLogradouro
                                this.form.descricaoRegistroAuxiliar.enderecos[iIndice].nomeLogradouro = oDadosCep.logradouro.replace(' s/n', '').split(",")[0].replace(new RegExp(`\\b${oDadosCep.logradouro.split(" ")[0]}\\b`, 'gi'), '').trim()
                            }
                        }

                        document.querySelectorAll('.campoNumeroRegistroAuxiliar > .control > input')[iIndice].focus()
                    }else{
                        this.form.descricaoRegistroAuxiliar.enderecos[iIndice].nomeLogradouro = ''
                        this.form.descricaoRegistroAuxiliar.enderecos[iIndice].bairro = ''
                    }

                    this.loading.enderecos[iIndice].cep = false
                }
            },
            /**
             * Função para consultar os dados do CEP
             */
            async consultarCep(sValor, bNotificar = true){
                return await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/consultarCep/${sValor}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(response.data.cep){
                        this.ceps.push(response.data)
                        
                        return response.data
                    }   
                    
                    if(bNotificar){
                        toastAlert('CEP não encontrado')
                    }

                    return null
                })
                .catch((error) => { 
                    if(bNotificar){
                        toastAlert('CEP não encontrado')
                    }

                    return null
                })                   
            },
            /**
             * Função para buscar o CEP pelo endereço
             */
            async buscarCepPorEndereco(sValor, iIndice, sCampoAuxiliar){
                if(sValor.length < 5){
                    document.querySelectorAll(`.dropdownEndereco${sCampoAuxiliar}`)[iIndice].style.display = 'none'
                    this.resultadosEndereco[iIndice] = []
                    return
                }

                document.querySelectorAll(`.dropdownEndereco${sCampoAuxiliar}`)[iIndice].style.display = 'block'

                this.resultadosEndereco = [...this.resultadosEndereco]
                
                this.resultadosEndereco[iIndice] = [
                    {
                        _id: '0',
                        cep: '',
                        complemento: '',
                        logradouro: 'Buscando endereços...'
                    }
                ]

                this.resultadosEndereco[iIndice] = this.ceps.filter(cep => {
                    if(!cep?.logradouro){
                        return false
                    }
                    
                    let logradouroNormalizado = cep.logradouro.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    let sValorNormalizado = sValor.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    return new RegExp(sValorNormalizado, 'i').test(logradouroNormalizado)
                })
            },
            /**
             * Função para pegar o CEP informado e buscar na API
             */
            async definirCep(oCep, iIndice, sTipo = ''){
                /**
                 * Define os dados do formulario
                 * 
                 * @var object oDados
                 * @var string sCampoAuxiliar
                 */
                let oDados = {}
                let sCampoAuxiliar = ''

                if(sTipo == 'descricaoRegistroAuxiliar'){
                    oDados = this.form[sTipo].enderecos[iIndice]
                    sCampoAuxiliar = 'RegistroAuxiliar'
                }else{
                    oDados = this.endereco
                }
                
                oDados.cep = oCep.cep.replace(/(\d{5})(\d{3})/, '$1-$2')
                oDados.bairro = oCep.bairro

                this.gravarConsultaCep(oCep.cep)

                for (const iTipoLogradouro in this.tiposLogradouro) {
                    if (this.tiposLogradouro[iTipoLogradouro].split(" - ")[1] == oCep.logradouro.split(" ")[0]) {
                        oDados.tipoLogradouro = iTipoLogradouro
                        oDados.nomeLogradouro = oCep.logradouro.replace(' s/n', '').split(",")[0].replace(new RegExp(`\\b${oCep.logradouro.split(" ")[0]}\\b`, 'gi'), '').trim()
                    }
                }

                document.querySelectorAll(`.dropdownEndereco${sCampoAuxiliar}`)[iIndice].style.display = 'none'
                this.resultadosEndereco[iIndice] = []

                document.querySelectorAll(`.campoNumero${sCampoAuxiliar} > .control > input`)[iIndice].focus()
            },
            /**
             * Função para pegar o contribuinte selecionado e adicionar ao input
             */
            async definirContribuinte(sNome, iIndice){   
                this.contribuinte = sNome
                this.indiceContribuinte = iIndice

                document.querySelector(`.contribuintes > .control > input`).focus()
            },
            /**
             * Função para listar os tabelionatos
             */
            async buscarTabelionatos(sValor){
                if(sValor.length < 2){
                    document.querySelector(`.dropdownTabelionato`).style.display = 'none'
                    this.resultadosTabelionato = []
                    return
                }

                document.querySelector(`.dropdownTabelionato`).style.display = 'block'

                this.resultadosTabelionato = [...this.resultadosTabelionato]
                
                this.resultadosTabelionato = [
                    {
                        _id: '0',
                        cep: '',
                        complemento: '',
                        logradouro: 'Buscando endereços...'
                    }
                ]

                this.resultadosTabelionato = this.cnjs.filter(cep => new RegExp(sValor, 'i').test(`${cep.cns} | ${cep.nome} - ${cep.estado} - ${cep.cidade}`))
            },
            /**
             * Função para pegar o tabelionato informado
             */
            async definirTabelionato(oCnj){
                this.form.descricaoRegistroAuxiliar.tabelionato = oCnj.nome

                document.querySelector(`.dropdownTabelionato`).style.display = 'none'

                this.resultadosTabelionato = []

                document.querySelector(`.livro > .control > input`).focus()
            },
            /**
             * Função para consultar os dados do CEP
             */
            async consultarCepPorEndereco(){     
                /**
                 * Define a variável dos dados de retorno e a página
                 * 
                 * @var boolean bVerificaDados
                 * @var int iPaginaAtal
                 */
                let bVerificaDados = true
                let iPaginaAtal = 1

                const db = await dbPromise
                
                const transactionContador = db.transaction('ceps', "readonly");
                const storeContador = transactionContador.objectStore('ceps');
                const iContador = await storeContador.count();

                if(iContador == 0){
                    localStorage.setItem('hashCliente', '')
                }

                while (bVerificaDados) {
                    try {
                        const response = await axios.get(`${process.env.VUE_APP_ENDPOINT_API}/v1/buscarCep?cidade=${process.env.VUE_APP_CIDADE ?? 'São Paulo'}&uf=${process.env.VUE_APP_UF ?? 'SP'}&hash=${localStorage.getItem('hashCliente') ?? ''}&limite=10000&paginaAtual=${iPaginaAtal}`, {
                            headers: {
                                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                            }
                        })

                        if (response.data.hash) {
                            if (response.data.data.length === 0) {
                                localStorage.setItem('hashCliente', response.data.hash)
                            }

                            const tx = db.transaction('ceps', 'readwrite')
                            const store = tx.objectStore('ceps')

                            await Promise.all(response.data.data.map(async (oCep) => {
                                const bVerificaRegistro = await store.get(oCep._id);

                                if (!bVerificaRegistro) {
                                    await store.add(oCep)
                                }
                            }))
                        }

                        bVerificaDados = response.data.data ? response.data.data.length !== 0 : false
                        iPaginaAtal++
                    } catch (error) {
                        console.error(error)
                        break // Encerra o loop em caso de erro
                    }
                }

                this.ceps = await db.transaction('ceps', 'readonly').objectStore('ceps').getAll()

                return null
            },
            /**
             * Função para salvar os dados do CNJ no indexedDB
             */
            async salvarCnjIndexedDB(){     
                const db = await dbPromise

                const response = await axios.get(`/cnj.json`)

                if (response.data.cnjs) {
                    const tx = db.transaction('cnjs', 'readwrite')
                    const store = tx.objectStore('cnjs')

                    await Promise.all(response.data.cnjs.map(async (oCnj) => {
                        const bVerificaRegistro = await store.get(oCnj.cns);

                        if (!bVerificaRegistro) {
                            await store.add(oCnj)
                        }
                    }))
                }

                this.cnjs = await db.transaction('cnjs', 'readonly').objectStore('cnjs').getAll()

                return null
            },
            /**
             * Função para abrir ou fechar o accordion
             */
            toggleAccordion(iIndice) {
                const content = document.getElementById(`content-${iIndice}`) ?? document.getElementById(`content-ato-${iIndice}`)
                const icon = document.getElementById(`icon-${iIndice}`) ?? document.getElementById(`icon-ato-${iIndice}`)

                if (content.classList.contains('show')) {
                    content.classList.remove('show')
                    content.classList.add('hide')
                    icon.textContent = '+'
                } else {
                    content.classList.remove('hide')
                    content.classList.add('show')
                    icon.textContent = '-'
                }
            },
            /**
             * Função para fechar o accordion
             */
            hideAccordion(iIndice) {
                if(iIndice < 0){
                    const classContent = document.querySelectorAll(`.content-class`)
                    const classIcon = document.querySelectorAll(`.icon-class`)
                    
                    for (let i = 0; i < classContent.length; i++) {
                        if (classIcon[i] && classContent[i].classList.contains('show')) {
                            classContent[i].classList.remove('show')
                            classContent[i].classList.add('hide')
                            classIcon[i].textContent = '+'
                        }
                    }

                    return
                }

                const content = document.getElementById(`content-${iIndice}`)
                const icon = document.getElementById(`icon-${iIndice}`)

                content.classList.remove('show')
                content.classList.add('hide')
                icon.textContent = '+'
            },
            /**
             * Função para selecionar a parte informada anteriormente
             */
            selecionarPartes(iIndiceAto) {
                this.indiceAto = iIndiceAto
                this.modalSelecionarPartes = true
            },
            /**
             * Função para selecionar a parte informada anteriormente
             */
            partesSelecionadas() {
                if(this.partesAdicionadas.filter(parte => parte.selecionado).length == 0){
                    toastAlert('Selecione pelo menos uma parte')
                    return
                }

                this.partesAdicionadas.forEach((parte, idx) => {
                    if(parte.selecionado){
                        if(!this.form.ato[this.ato.indice]?.pessoal){
                            this.form.ato[this.ato.indice] = {
                                pessoal: []
                            }
                        }
 
                        this.form.ato[this.ato.indice].pessoal.push({
                            classificacao: this.form.ato[parte.ato].pessoal[parte.indice]?.classificacao ?? '', 
                            nacionalidade: this.form.ato[parte.ato].pessoal[parte.indice]?.nacionalidade ?? '',
                            fracao: this.form.ato[parte.ato].pessoal[parte.indice]?.fracao ?? '',
                            valorFracional: this.form.ato[parte.ato].pessoal[parte.indice]?.valorFracional ?? '',
                            uniaoEstavel: this.form.ato[parte.ato].pessoal[parte.indice]?.uniaoEstavel ?? '', 
                            inconsistencia: this.form.ato[parte.ato].pessoal[parte.indice]?.inconsistencia ?? '',
                            regimeBens: this.form.ato[parte.ato].pessoal[parte.indice]?.regimeBens ?? '', 
                            estadoCivil: this.form.ato[parte.ato].pessoal[parte.indice]?.estadoCivil ?? '', 
                            tipoDocumento: this.form.ato[parte.ato].pessoal[parte.indice]?.tipoDocumento ?? '', 
                            documento: this.form.ato[parte.ato].pessoal[parte.indice]?.documento ?? '', 
                            nome: this.form.ato[parte.ato].pessoal[parte.indice]?.nome ?? '', 
                            nomes: this.form.ato[parte.ato].pessoal[parte.indice]?.nomes ?? [], 
                            tipoDocumentoConjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.tipoDocumentoConjuge ?? '', 
                            documentoConjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.documentoConjuge ?? '', 
                            nomeConjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.nomeConjuge ?? '',
                            conjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.conjuge ?? false
                        })

                        this.$forceUpdate()                        

                        /**
                         * Reestrutura as averbações adicionadas
                         *
                         * @var {object} aDadosPartesAto
                         */
                        const aDadosPartesAto = this.form.ato[this.ato.indice].pessoal.map((pessoal, indiceParte) => {
                            return `${indiceParte+1} - ${pessoal.classificacao} ${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                        })

                        /**
                         * Define o select pela referencia
                         *
                         * @var {object} oSelectParteAto
                         */
                        const oSelectParteAto = this.$refs.selectParteAto
                        
                        oSelectParteAto.clearSelectize()
                        oSelectParteAto.updateOptions(aDadosPartesAto)
                        oSelectParteAto.clearSelectize()

                        this.ato.pessoal.indice = parseInt(this.ato.pessoal.indice) + parseInt(1)

                        document.getElementById(`checkbox-${idx}`).click()
                    }
                })

                this.modalSelecionarPartes = false

                toastAlert('Partes adiconadas com sucesso', 'success')
            },
            /**
             * Função para copiar o ato
             */
            copiarAto(iIndiceAto, bAbrirModal = true){
                if(bAbrirModal){
                    this.indiceAto = iIndiceAto
                    this.modalCopiarAto = true
                    return
                }

                if(this.numeroAtoInicial == ''){
                    toastAlert('Informe o número do ato inicial')
                    return
                }

                if(this.numeroAtoFinal == ''){
                    toastAlert('Informe o número do ato final')
                    return
                }

                if(parseInt(this.numeroAtoInicial) > parseInt(this.numeroAtoFinal)){
                    toastAlert('O número do ato inicial não pode ser maior que o número do ato final')
                    return
                }

                if(parseInt(this.numeroAtoFinal) - parseInt(this.numeroAtoInicial) > 1000){
                    toastAlert('É permitido apenas a cópia de 1000 atos por vez')
                    return
                }

                if(this.dataRegistro == ''){
                    toastAlert('Informe a data de registro')
                    return
                }
                    
                this.carregando = true
                this.modalCopiarAto = false    

                const worker = new Worker(this.edicao ? '../copiarAto.js' : 'copiarAto.js')
                const that = this

                worker.addEventListener('message', function (e) {
                    const { form, listaAtos, ato } = e.data

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectAto
                     */
                    const oSelectAto = that.$refs.selectAto

                    /**
                     * Reestrutura as averbações adicionadas
                     *
                     * @var {object} aDadosAto
                     */
                    const aDadosAto = listaAtos.map((atoUnico, idx) => {
                        const option = document.querySelectorAll("div.column.is-half.listaAtos > div > div > div.selectize-dropdown.single > div > div")
               
                        if(atoUnico.onus == 1 && option[idx]){
                            option[idx].classList.add('onusAtivo')
                        }

                        return `${ atoUnico.numeroAto } ${ atoUnico.descricao != '' ? '-' : '' } ${ atoUnico.descricao } ${ that.tiposAto[atoUnico.tipo] ? ` - ${that.tiposAto[atoUnico.tipo].split(' - ')[1]}` : '' } ${ atoUnico.descricaoAdicional ? ` - ${atoUnico.descricaoAdicional}` : '' } ${ atoUnico.onus == 1 ? ` - Ônus ativo` : '' }`
                    })
                            
                    oSelectAto.clearSelectize()
                    oSelectAto.updateOptions(aDadosAto)
                    oSelectAto.clearSelectize()

                    if(!that.listaAtos[that.indiceAto]){
                        ato.indice = form.ato.length
                    }

                    that.$set(that, 'form', form)
                    that.$set(that, 'listaAtos', listaAtos)
                    that.$set(that, 'ato', ato)
                    that.$set(that, 'numeroAtoInicial', '')
                    that.$set(that, 'numeroAtoFinal', '')
                    that.$set(that, 'dataRegistro', '')
                    that.$set(that, 'transferirPartes', true)
                    that.$set(that, 'carregando', false)

                    that.cancelarEdicaoIndisponibilidade()
                    that.cancelarEdicaoAto()
                })            
                
                worker.postMessage({ sTipo: 'ato', oForm: this.form, ato: this.ato, listaAtos: this.listaAtos, indiceAto: this.indiceAto, numeroAtoInicial: this.numeroAtoInicial, numeroAtoFinal: this.numeroAtoFinal, dataRegistro: this.dataRegistro, transferirPartes: this.transferirPartes }) 
            },
            /**
             * Função para transferir o ato
             */
            async transferirAto(iIndiceAto, iNumeroAto, bAbrirModal = true){
                if(bAbrirModal){
                    this.indiceAto = iIndiceAto
                    this.numeroAto = iNumeroAto
                    this.modalTransferirAto = true
                    return
                }

                if(iNumeroAto == ''){
                    toastAlert('Informe o número do ato')
                    return
                }

                if(this.numeroMatriculaInicial == ''){
                    toastAlert('Informe o número da matrícula inicial')
                    return
                }

                if(this.numeroMatriculaFinal == ''){
                    toastAlert('Informe o número da matrícula final')
                    return
                }

                if(parseInt(this.numeroMatriculaInicial) > parseInt(this.numeroMatriculaFinal)){
                    toastAlert('O número da matrícula inicial não pode ser maior que o número da matrícula final')
                    return
                }

                if(this.dataRegistro == ''){
                    toastAlert('Informe a data de registro')
                    return
                }
                
                this.modalTransferirAto = false
                this.carregando = true

                setTimeout(async () => {
                    /**
                     * Define o objeto dos dados
                     * 
                     * @var object oDados
                     */
                    let oDados = {}
                    if(this.listaAtos[this.indiceAto]){
                        oDados = JSON.parse(JSON.stringify(this.form.ato[this.indiceAto]))
                    }else{
                        oDados = JSON.parse(JSON.stringify(this.ato))
                        oDados.pessoal = JSON.parse(JSON.stringify(this.form.ato[this.indiceAto]?.pessoal ?? []))
                    }                    

                    oDados.dataRegistro = this.dataRegistro
                    oDados.indiceAto = this.indiceAto
                    oDados.numeroMatriculaAtual = this.form.numeroRegistro
                    oDados.tipoRegistroAtual = this.form.tipoRegistro
                    oDados.numeroMatriculaInicial = this.numeroMatriculaInicial
                    oDados.numeroMatriculaFinal = this.numeroMatriculaFinal
                    oDados.numeroAto = iNumeroAto
                    oDados.usuario = this.autenticacao.usuario

                    if(!this.transferirPartes){
                        oDados.pessoal = []
                    }  

                    await axios.request({
                        method: 'post',
                        url: `${process.env.VUE_APP_ENDPOINT_API}/v1/transferirAto`,
                        headers: { 
                            'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                        },
                        data: {
                            dataBase64: Buffer.from(JSON.stringify(oDados)).toString('base64')
                        }
                    })
                    .then((response) => {
                        toastAlert('Ato transferido com sucesso', 'success')

                        this.numeroMatriculaInicial = ''
                        this.numeroMatriculaFinal = ''
                        this.dataRegistro = ''
                        this.transferirPartes = true

                        this.modalTransferirAto = false
                        this.carregando = false
                    })
                    .catch((error) => {
                        this.carregando = false
                        this.modalTransferirAto = true
                        
                        toastAlert(`Erro ao transferir o ato`)
                    });
                }, 1)
            },
            /**
             * Função para copiar o endereco
             */
            copiarEndereco(bAbrirModal = true){
                if(this.endereco.nomeLogradouro == ''){
                    toastAlert('Informe o logradouro')

                    return
                }

                if(this.endereco.tipoLogradouro == ''){
                    toastAlert('Informe o tipo do logradouro')

                    return
                }
                
                if(this.endereco.bairro == ''){
                    toastAlert('Informe o bairro do endereço')

                    return
                }

                if(this.endereco.cep != '' && (!/^[0-9]{8}$/.test(this.endereco.cep.replace('-', '')) || !this.ceps.some(endereco => this.endereco.cep.replace('-', '') == endereco.cep.replace('-', '')))){
                    toastAlert('CEP inválido')

                    return
                }

                if(bAbrirModal){
                    this.modalCopiarEndereco = true
                    return
                }

                if(this.qtdeEndereco == ''){
                    toastAlert('Informe a quantidade de endereços')
                    return
                }

                this.carregando = true
                this.modalCopiarEndereco = false    
                this.endereco.indice = this.listaAtos.length == 0 ? null : this.endereco.indice

                for(let i = 0; i < this.qtdeEndereco; i++){
                    const aLotesQuadras = []

                    if(this.numerico){
                        for(let iLote = this.loteInicial; iLote <= this.loteFinal; iLote++){
                            for(let iQuadra = this.quadraInicial; iQuadra <= this.quadraFinal; iQuadra++){
                                aLotesQuadras.push({
                                    lote: iLote.toString(),
                                    quadra: iQuadra.toString()
                                })
                            }
                        }
                    }

                    if(this.alfabetica){
                        for (let sLote = this.loteInicial; sLote <= this.loteFinal; sLote = String.fromCharCode(sLote.charCodeAt(0) + 1)) {
                            for (let sQuadra = this.quadraInicial; sQuadra <= this.quadraFinal; sQuadra = String.fromCharCode(sQuadra.charCodeAt(0) + 1)) {
                                aLotesQuadras.push({
                                    lote: sLote.toUpperCase(),
                                    quadra: sQuadra.toUpperCase()
                                })
                            }
                        }
                    }  

                    if(this.alfanumerico){
                        for (let sLote = this.loteInicial.replace(/[^a-zA-Z]/g, ''); sLote <= this.loteFinal.replace(/[^a-zA-Z]/g, ''); sLote = String.fromCharCode(sLote.charCodeAt(0) + 1)) {
                            for (let sQuadra = this.quadraInicial.replace(/[^a-zA-Z]/g, ''); sQuadra <= this.quadraFinal.replace(/[^a-zA-Z]/g, ''); sQuadra = String.fromCharCode(sQuadra.charCodeAt(0) + 1)) {
                                aLotesQuadras.push({
                                    lote: `${sLote.toUpperCase()}${this.loteInicial.replace(/[^0-9]/g, '')}`,
                                    quadra: `${sQuadra.toUpperCase()}${this.quadraInicial.replace(/[^0-9]/g, '')}`
                                })
                            }
                        }
                    }                    
               
                    this.form.enderecos.push({
                        cep: this.endereco.cep,
                        tipoLogradouro: this.endereco.tipoLogradouro,
                        nomeLogradouro: this.endereco.nomeLogradouro,
                        bairro: this.endereco.bairro,
                        numero: this.endereco.numero,
                        complemento: this.endereco.complemento,
                        complementoQuadra: this.endereco.complementoQuadra,
                        loteQuadras: aLotesQuadras
                    })
                }                

                this.listaEnderecos = []

                /**
                 * Reestrutura os endereços adicionados
                 *
                 * @var {object} aDadosEnderecos
                 */
                const aDadosEnderecos = this.form.enderecos.map((endereco, idx) => {
                    this.listaEnderecos.push({
                        cep: endereco.cep,
                        tipoLogradouro: endereco.tipoLogradouro,
                        nomeLogradouro: endereco.nomeLogradouro,
                        numero: endereco.numero
                    })

                    return `${ endereco.cep } ${ endereco.cep != '' ? `-` : `` } ${ endereco.tipoLogradouro && this.tiposLogradouro[endereco.tipoLogradouro] ? this.tiposLogradouro[endereco.tipoLogradouro].split(' - ')[1] : '' } ${ !endereco.tipoLogradouro && endereco.nomeLogradouro  ? '-' : '' } ${ endereco.nomeLogradouro }${ endereco.numero ? `, ${endereco.numero}` : '' }`
                })

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectEndereco
                 */
                const oSelectEndereco = this.$refs.selectEndereco
                
                oSelectEndereco.clearSelectize()
                oSelectEndereco.updateOptions(aDadosEnderecos)
                oSelectEndereco.clearSelectize()

                toastAlert('Endereço copiado com sucesso', 'success')
                
                this.carregando = false
                this.qtdeEndereco = ''
                this.numerico = false
                this.alfabetica = false
                this.alfanumerico = false
                this.loteInicial = ''
                this.loteFinal = ''
                this.quadraInicial = ''
                this.quadraFinal = ''
            },
            /**
             * Função para cancular a expiracao
             */
            calcularExpiracao() {
                const dataAtualUnix = Math.floor(Date.now() / 1000)
                const diferencaSegundos = this.autenticacao.exp - dataAtualUnix

                if(diferencaSegundos <= 10){
                    this.contagemRegressivaClass = 'danger'
                }else{
                    this.contagemRegressivaClass = 'default'
                }

                if (diferencaSegundos <= 0) {
                    clearInterval(this.expiracaoInterval)
                    this.contagemRegressiva = "Sessão expirada"
                } else {
                    const horas = Math.floor((diferencaSegundos % 86400) / 3600)
                    const minutos = Math.floor((diferencaSegundos % 3600) / 60)
                    const segundos = diferencaSegundos % 60

                    this.contagemRegressiva = `A sessão expira em ${horas < 10 ? '0'+horas : horas}:${minutos < 10 ? '0'+minutos : minutos}:${segundos < 10 ? '0'+segundos : segundos}`
                }
            },
            /**
             * Função para atualizar o token
             */
            async refreshToken(){
                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")

                /**
                * Define os opções da requisição
                * 
                * @var {object} oOpcoes
                */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow',
                    body: JSON.stringify({ token: this.autenticacao.refresh_token })
                }

                /**
                * Realiza a requisição para buscar os detalhes do usuario
                * 
                * @var {object} oLogin
                */
                const oLogin = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/refreshToken`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!oLogin){
                    toastAlert('Sua sessão expirou, redirecionando para a autenticação...')

                    setTimeout(() => {
                        /**
                        * Desloga da aplicação
                        */
                        this.sair()
                    }, 2000)

                    /**
                    * Define os dados nas variaveis
                    */
                    this.autenticacao.autenticado = false

                    return false
                }    

                if(oLogin?.AccessToken){
                    sessionStorage.setItem('access_token', oLogin.AccessToken)
                }

                /**
                * Realizar a autenticação
                */
                this.autenticar()

                /**
                * Realiza a contagem da expiração
                */
                this.calcularExpiracao()
                this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)
            },
            /**
             * Função para gravar a consulta pelo CEP
             */
            gravarConsultaCep(sCep){
                axios.get(`${process.env.VUE_APP_ENDPOINT_API}/v1/buscarCep?cep=${sCep}&usuario=${this.autenticacao.usuario}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
            },
            /**
             * Função para pegar a opção selecionada do campo adicionavel
             */
            async selecionarOpcao(sNome, iIndice, sCampo){   
                this[sCampo] = sNome
                this[`indice${sCampo}`] = iIndice

                document.querySelector(`.${sCampo} > .control > input`).focus()
            },
            /**
             * Função para adicionar uma opção no campo adicionavel
             */
            adicionarCampo(sCampo, bExibirAlerta = true){
                if(this[sCampo] == ''){
                    return
                }

                if(this[`indice${sCampo}`] == null){
                    if(!this.form[sCampo]){
                        this.form[sCampo] = []
                    }

                    this.form[sCampo].push(this[sCampo])
                }else{
                    this.form[sCampo][this[`indice${sCampo}`]] = this[sCampo]
                }

                this[sCampo] = ''
                this[`indice${sCampo}`] = null

                this.$nextTick(() => {
                    /**
                     * Define o focus no input
                     *
                     * @var {object} oInput
                     */
                    const oInput = this.$refs[`input-${sCampo}`]

                    if (oInput) {
                        setTimeout(() => {
                            if(bExibirAlerta){
                                oInput.$el.querySelector('input').focus();
                            }
                        }, 50)
                    }
                });

                if(bExibirAlerta){
                    this.abrirDropdown(sCampo)
                }
            },
            /**
             * Função para remover um campo adicionavel
             */
            removerOpcao(iIndice, sCampo){
                this.form[sCampo].splice(iIndice, 1)

                this[sCampo] = ''
                this[`indice${sCampo}`] = null

                setTimeout(() => {
                    if(document.querySelector(`.dropdown-${sCampo}`)){
                        document.querySelector(`.dropdown-${sCampo}`).style.display = 'block'
                        document.querySelector(`.${sCampo} > .control > input`).focus()
                    }
                }, 100)
            },
            /**
             * Função para abrir o dropdown
             */
            abrirDropdown(sCampo){
                if(document.querySelector(`.dropdown-${sCampo}`) && document.querySelector(`.dropdown-${sCampo}`).style.display != 'block'){
                    document.querySelector(`.dropdown-${sCampo}`).style.display = 'block'
                }
            },
            /**
             * Função para fechar o dropdown
             */
            fecharDropdown(sCampo, bTab = false){
                if(document.querySelector(`.dropdown-${sCampo}`) && document.querySelector(`.dropdown-${sCampo}`).style.display != 'none'){
                    const divs = document.querySelectorAll('.indicador')
                    divs.forEach(div => {
                        div.addEventListener('click', (event) => {
                            const clickedDiv = event.target
                            
                            if(!clickedDiv.classList.contains(`.option-${sCampo}`) && clickedDiv.getAttribute('data-campo') != sCampo && document.querySelector(`.dropdown-${sCampo}`) && document.querySelector(`.dropdown-${sCampo}`).style.display != 'none'){
                                document.querySelector(`.dropdown-${sCampo}`).style.display = 'none'
                            }
                        })
                    })
                } 

                if(bTab){
                    if(document.querySelector(`.dropdown-${sCampo}`) && document.querySelector(`.dropdown-${sCampo}`).style.display != 'none'){
                        document.querySelector(`.dropdown-${sCampo}`).style.display = 'none'
                    }
                }
            },
            /**
             * Grava os dados do indexedDb
             */
            async setItem(id, dados) {
                try {
                    const db = await dbPromise
                    const tx = db.transaction('dados', 'readwrite')
                    const store = tx.objectStore('dados')
                    await store.put({ id, valor: dados })
                    await tx.done
                } catch (error) {
                    console.error('Erro ao armazenar dados no IndexedDB', error)
                }
            },
            /**
             * Pega os dados do indexedDb
             */
            async getItem(id) {
                try {
                    const db = await dbPromise
                    const tx = db.transaction('dados', 'readonly')
                    const store = tx.objectStore('dados')
                    const data = await store.get(id)
                    await tx.done
                    return data ? JSON.parse(JSON.stringify(data.valor)) : false
                } catch (error) {
                    console.error('Erro ao recuperar dados do IndexedDB', error)
                    return false
                }
            },
            /**
             * Remove os dados do indexedDb
             */
            async removeItem(id) {
                try {
                    const db = await dbPromise
                    const tx = db.transaction('dados', 'readwrite')
                    const store = tx.objectStore('dados')
                    await store.delete(id)
                    await tx.done
                } catch (error) {
                    console.error('Erro ao remover dados do IndexedDB', error)
                }
            }
        }
    }
</script>

<style>
    html{
        overflow: auto!important;
        background-color: #eee!important;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .div-principal{
        height: 100vh;
        overflow: hidden;
    }

    .barra-superior{
        margin: 0;
        margin-top: -1px!important;
        height: 6rem!important;
        background: #fff!important;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0!important;
        font-size: 0.8rem;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .indicador{
        top: -16px!important;
        padding-top: 12px;
        padding: 0;
        background:#eee!important;
        position: relative;
        height: 100vh!important;
    }

    .indicadores{
        height: 83vh;
        margin: 10px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
        overflow-x: hidden;
        padding-bottom: 40px;
    }

    .lista-indicadores{
        display: none;
        justify-content: flex-end;
        flex-direction: column;
    }

    .formulario{
        margin: 0 auto;
        margin-top: 1rem!important;
        position: relative;
        width: 97vw!important;
        background: #fff;
        padding: 1rem 1rem;
        border-radius: 5px!important;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 0rem!important;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        border-top: 1px solid #ddd;
        width: 97vw!important;
        padding: 1rem 2rem 0rem 2rem;
        margin-left: -1rem;
    }

    .selectize-input{
        padding: 10px 8px!important;
    }

    .selectize-control.multi > .selectize-input > *{
        width: 32%;
    }

    .full-multiselect > .control > .selectize-control.multi > .selectize-input > *{
        width: 75%;
    }

    .selectize-input > *{
        text-overflow: ellipsis;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;    
        margin: -4px 0px;
    }

    .formulario .columns{
        flex-wrap: wrap;
        padding: 0.5rem;
        margin-top: -0.5rem!important;
        align-items: flex-end;
        padding-bottom: 15px;
    }

    .formulario .column{
        padding: 0 0.5rem!important;
        margin-top: 0rem!important;
    }

    .formulario .column.is-one-tenth,.column.is-one-tenth-tablet {
        flex: none;
        width: 10%
    }

    .formulario .label {
        margin-top: 0.5rem;
        margin-bottom: 0.2rem!important;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
    }

    .tabs{
        align-items: flex-end;
        margin-bottom: 0px;
        margin-left: -1rem;
        width: 97vw!important;
    }
    
    .tabs ul{
        padding-left: 2rem;
    }

    .formulario .numero{
        position: absolute;
        right: 25px;
        top: 1rem;
        font-size: 18px;
    }

    .formulario .registroAnterior, .formulario .indisponibilidade{
        border-bottom: 1px solid #eee;
    }

    .btn-rotate > .icon{
        transform: rotate(270deg);
    }

    .modal-card{
        top: 70px;
        position: absolute;
    }

    .accordion {
        width: 100%;
    }

    .accordion-item {
        border: 1px solid #ddd;
        margin-bottom: 1rem;
    }

    .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        background-color: #f1f1f1;
        word-break: break-all;
    }

    .accordion-icon {
        font-size: 1.2rem;
    }

    .accordion-content {
        padding: 0.5rem;
        display: none;
        border-top: 1px solid #ddd;
    }

    .accordion-content.hide {
        display: none;
    }

    .accordion-content.show {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .linha-divisoria{
        border-top: 1px solid #ccc;
        padding-left: 1.5rem;
        line-height: 0rem;
        margin: 20px 0 10px;
        width: 100%;
    }

    .linha-divisoria span {
        background-color: white;
        padding: 0 10px;
    }

    .optionEndereco:hover{
        background-color: #f5fafd;
        color: #495c68;
        cursor: pointer;
    }

    .optionEndereco{
        padding: 5px 8px;
    }

    .onusAtivo{
        color: #ff5a5a!important; 
        font-weight: bold;
    }

    .checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        line-height: 1.5;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    .checkbox:hover input ~ .checkmark {
        background-color: #ccc;
    }

    .checkbox input:checked ~ .checkmark {
        background-color: #006598;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .checkbox input:checked ~ .checkmark:after {
        display: block;
    }

    .checkbox .checkmark:after {
        left: 10px;
        top: 5px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .modal-card-body{
        max-height: 70vh;
    }

    .loading-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Fundo semi-transparente */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; /* Certifique-se de que a tela de loading está acima de outros elementos */
    }

    .loading-spinner {
        border: 4px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top: 4px solid #3498db; /* Cor do spinner */
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite; /* Animação de rotação */
    }

    .hidden{
        display: none!important;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .is-2 > div > .selectize-control.multi .selectize-input > div{
        width: 96%;
    }

    .dropdownContribuintes{
        display: none;
    }

    .remover{
        color: #FF5A5A;
        padding: 5px 8px;
    }

    .tabs.is-boxed li.is-active a {
        background-color: #3b85b4;
    }

    .tabs li.is-active a {
        color: #fff;
    }
</style>